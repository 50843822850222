import {Box, BoxProps, Button, Typography} from "@mui/material"
import {FC} from "react"
import {API} from "@plumeuk/shapeshift-identity";
import {makeStyles} from "tss-react/mui";
import {IAnnouncement, IAnnouncementEntity} from "../../../../types/announcement";
import VideoPreview from "../../../../pages/TrainingFeedPage/VideoPreview";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";
import {Link} from "react-router-dom";
import {palette} from "../../../../constants";

interface IProps extends BoxProps {
	announcement: IAnnouncement
}

const useStyles = makeStyles()((theme) => ({
	announcementCard: {
		position: "relative",
		backgroundColor: palette.orange,
		margin: theme.spacing(1.75, 0),
		borderRadius: "15px",
		color: palette.black,
		transform: "translateY(0)",
		opacity: 1,
		transition: "0.35s",
		"&:hover": {
			transform: "translateY(-4px)",
			opacity: 0.85,
			transition: "0.35s"
		}
	},
	announcementLink: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		display: "block"
	},
	announcementInner: {
		padding: theme.spacing(2.5, 2, 5)
	},
	announcementDetails: {

	},
	announcementTitle: {
		color: palette.black,
		margin: theme.spacing(2, 0),
		fontWeight: 400
	},
	announcementSubtitle: {
		color: palette.black
	},
	announcementContent: {
		color: palette.black
	},
	announcementDeliveryDate: {
		color: palette.black,
		fontSize: "12px",
		textTransform: "uppercase",
		letterSpacing: "0.4px",
		fontWeight: 400
	},
	announcementThumbnail: {

	},
	cardBottom: {

	},
	btn: {
		position: "absolute",
		bottom: theme.spacing(2.5),
		left: theme.spacing(2.5),
		width: `calc(100% - ${theme.spacing(5)})`,
		background: palette.black,
		borderRadius: "100px",
		fontSize: "15px",
		color: palette.orange,
		height: "45px",
		"&:hover": {
			background: palette.black,
			color: palette.orange
		}
	}
}));

export const AnnouncementFeedModule:FC<IProps> = ({className, announcement}) => {

	const {classes, cx} = useStyles();

	const getVideoUrl = (announcement: IAnnouncement | null): string | undefined => {
		if (announcement) {
			return announcement.videoUrl;
		}
		return undefined;
	};

	const getVideoThumbnail = (announcement: IAnnouncement | null): string | undefined => {
		if(announcement) {

			if(announcement.videoThumbnailUrl) {
				return announcement.videoThumbnailUrl;
			}

			return "/holding_image.png"

		}

		return undefined;
	};

	const videoUrl = getVideoUrl(announcement);
	const videoThumbnail = getVideoThumbnail(announcement);

	return (
		<Box className={cx(classes.announcementCard, className)}>

			<Link className={classes.announcementLink} to={"/feed/announcement/" + announcement.slug} />

			<Box className={classes.announcementInner}>

				{announcement.scheduledDeliveryDate?.toDateString() &&
					<Typography className={classes.announcementDeliveryDate}>
						{announcement.scheduledDeliveryDate?.toDateString()}
					</Typography>
				}

				<Typography className={classes.announcementTitle} variant="h4">
					{announcement.title}
				</Typography>

				{announcement?.subtitle &&
					<Typography className={classes.announcementSubtitle} variant="subtitle1">
						{announcement.subtitle}
					</Typography>
				}

				<Box className={classes.announcementThumbnail}>
					{(videoUrl && videoThumbnail) &&
						<VideoPreview videoUrl={videoThumbnail} />
					}
				</Box>

				<Box className={classes.cardBottom}>
					<Button className={classes.btn}>Learn more</Button>
				</Box>

			</Box>

		</Box>
	)
}