import Box from "@mui/material/Box";
import {FC, useCallback, useContext, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Modal} from "@plumeuk/shapeshift-common/v2";
import {Button, Typography} from "@mui/material";
import {INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {cohortBaseUrl} from "../../../constants";

interface IProps {
	open: boolean,
	onClose: (reload?: boolean) => void,
	courseId: number,
	selected: number[]
}

const useStyles = makeStyles()((theme) => ({
	adminBulkUnEnrollmentModal: {
		padding: theme.spacing(3),
		display: "flex",
		gap: theme.spacing(2),
		flexDirection: "column"
	}
}));

export const AdminBulkCourseUnenrollModal: FC<IProps> = ({open, selected, courseId, onClose}) => {
	const {classes} = useStyles();
	const {notify} = useContext(NotificationContext);
	const [completionResponse, submitUnenrollApi] = useApi<any>() //> any to do

	const handleSubmitUnenrollEnroll = useCallback(() => {
		if(!open || completionResponse.isLoading)
			return;

		submitUnenrollApi({
			url: `${cohortBaseUrl}/course/${courseId}/users/unenroll`,
			method: "PUT",
			data: {
				users: selected
			}
		})
	}, [courseId, open, completionResponse.isLoading])

	useEffect(() => {
		if(completionResponse.statusCode === 200){
			notify("", "Successfully updated users completions", INotificationSeverity.success, 5000)
			onClose(true)
		}
		else if(completionResponse.statusCode){
			notify("", "Failed to set users completion", INotificationSeverity.error, 5000)
			onClose()
		}
	}, [completionResponse])

	return (
		<Modal
			open={open}
			footerButtons={[
				<Button key={1} onClick={() => onClose()}>CANCEL</Button>,
				<Button key={2} onClick={() => handleSubmitUnenrollEnroll()}>CONFIRM</Button>
			]}
			onClose={() => onClose()}
			aria-labelledby="modal-modal-title"
			title="Confirm Bulk Unenrollment"
			aria-describedby="modal-modal-description"
		>
			<Box className={classes.adminBulkUnEnrollmentModal}>
				<Typography variant="h5">Confirm Bulk Unenrollment</Typography>
				<Typography>
					<li>[{selected.length}] users selected</li>
				</Typography>
			</Box>
		</Modal>
	);
}