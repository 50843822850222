import {Box, BoxProps, Typography} from "@mui/material"
import {useApi} from "@plumeuk/shapeshift-identity";
import {FC} from "react"
import {makeStyles} from "tss-react/mui";
import {palette} from "../../constants";
import {IconFire} from "../../icons/IconFire";

const useStyles = makeStyles()((theme) => ({
	dailyStreak: {
		height: "fit-content",
		display: "flex",
		gap: "5px",
		background: palette.grey10,
		borderRadius: "1000px",
		padding: "10px 20px",
		"svg": {
			height: "24px",
			width: "24px"
		},
		"& svg path": {
			fill: theme.palette.primary.main
		},
		"p": {
			fontSize: "17.4px",
			fontWeight: 600
		},
		[theme.breakpoints.down("sm")]: {
			padding: "8px 16px",
			marginTop: "5px",
			"p": {
				fontSize: "13px"
			},
			"svg": {
				height: "18px",
				width: "18px"
			}
		}
	}
}));


export const DailyStreak:FC<BoxProps> = ({className}) => {
	const {classes, cx} = useStyles();

	const [dailyStreak] = useApi<number>("/api/user/streak");

	if(!dailyStreak.data)
		return <></>

	return (
		<Box className={cx(classes.dailyStreak, className)}>
			<IconFire />
			<Typography>{dailyStreak.data} DAYS STREAK</Typography>
		</Box>
	)
}