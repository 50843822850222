import {makeStyles} from "tss-react/mui";
import {Box, BoxProps, ThemeProvider} from "@mui/material";
import {adminTheme} from "../../templates/adminTheme";
import {palette} from "../../constants";

interface ICustomProps {
	size?: "wide" | "constrained"
}

const useStyles = makeStyles()((_theme) => ({
	adminContainer: {
		background: palette.grey90,
		minHeight: "100%",
		height: "auto",
		flexGrow: 1,
		"&:before": {
			content: "''",
			height: "100%",
			width: "100%",
			position: "absolute",
			zIndex: 0,
			bottom: 0,
			left: 0,
			opacity: "0.025",
			backgroundPosition: "-1px -1px",
			backgroundSize: "85px 100px",
			backgroundImage: `linear-gradient(to right, ${adminTheme.palette.common.white} 1px, transparent 1px),
    linear-gradient(to bottom, ${adminTheme.palette.common.white} 1px, transparent 1px)`
		},
		"&:after": {
			content: "''",
			height: "400px",
			width: "100%",
			position: "absolute",
			zIndex: 1,
			bottom: 0,
			left: 0,
			background: `linear-gradient(0deg, ${adminTheme.palette.primary.light} 0%, transparent 100%)`
		}
	},
	adminContainerInner: {
		width: "100%",
		height: "100%",
		marginLeft: "auto",
		marginRight: "auto",
		position: "relative",
		zIndex: 9
	},
	wide: {
		maxWidth: "1580px"
	},
	constrained: {
		maxWidth: "1120px"
	}
}));

export type IProps = BoxProps & ICustomProps;

export const AdminContainer: React.FC<IProps> = ({className, size, children, ...baseProps}) => {
	const {classes, cx} = useStyles();

	return (
		<Box className={cx(classes.adminContainer, className)} {...baseProps}>
			<Box className={cx(classes.adminContainerInner, size && classes[size])}>
				{children}
			</Box>
		</Box>
	);
}