import {FC, useContext} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Breadcrumbs, Button, Tab, Tabs, Typography} from "@mui/material";
import {Link, Navigate, Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import {useAdminEntity} from "@plumeuk/shapeshift-common/hooks";
import {EnrollmentTab} from "./Tabs/EnrollmentTab";
import {QuizzesTab} from "./Tabs/QuizzesTab";
import {OverviewTab} from "./Tabs/OverviewTab";
import {LessonsTab} from "./Tabs/LessonsTab";
import {bannerHeight} from "../../../../../constants";
import {ICohortCourseEntity} from "../../../../../types/course";
import {PageBaseContext} from "../../../../../contexts/pageBaseContext";

interface IStyleProps {
	hasBackgroundImage?: boolean
}

const useStyles = makeStyles<IStyleProps>()((theme, {hasBackgroundImage}) => ({
	adminCoursesLandingPage: {
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		}
	},
	adminBreadcrumbs: {
		marginBottom: "25px"
	},
	adminBanner: {
		position: "relative",
		display: "flex",
		width: "100%",
		height: hasBackgroundImage ? bannerHeight : "auto",
		borderRadius: "5px",
		overflow: "hidden",
		marginBottom: "12px"
	},
	adminBannerTitle: {
		position: "relative",
		fontSize: "34px",
		lineHeight: "41px",
		fontWeight: "600",
		letterSpacing: "0.25px",
		alignSelf: "end",
		margin: "32px 20px 20px",
		zIndex: 2
	},
	adminBackgroundOverlay: {
		"&:after": {
			content: "''",
			position: "absolute",
			bottom: 0,
			left: 0,
			width: "100%",
			height: "100%",
			background: "linear-gradient(to top, #121212 0%, transparent 100%);",
			zIndex: 1
		}
	},
	adminTitleRow:{
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		alignSelf: "flex-end",
		width: "100%",
		paddingRight: theme.spacing(3),
		zIndex: 2
	},
	adminBackgroundImage: {
		position: "absolute",
		left: 0,
		top: 0,
		width: "100%",
		height: "100%",
		objectFit: "cover"
	},
	adminTabs: {
		borderBottom: 0,
		"& [class*=indicator]": {
			bottom: "4px"
		}
	},
	adminTab: {
		fontSize: "14px",
		fontWeight: "600",
		minHeight: "40px",
		minWidth: "85px",
		padding: 0,
		paddingLeft: "15px",
		paddingRight: "15px",
		letterSpacing: "0.4px",
		textTransform: "uppercase"
	}
}));

export const AdminCourseLandingPage: FC = () => {

	const {id: courseId} = useParams();
	const entityId = courseId ? parseInt(courseId) : -1;
	const {entity: course} = useAdminEntity<ICohortCourseEntity, "course">("course", entityId, undefined, "en")
	const backgroundImageUrl = course?.featuredImage?.formats?.large?.url ?? course?.featuredImage?.url;
	const hasBackgroundImage = !!backgroundImageUrl;
	const {classes} = useStyles({hasBackgroundImage});
	const location = useLocation();
	const navigate = useNavigate();
	const [{ownedCohort}] = useContext(PageBaseContext)

	const tabRoutes = [
		`/admin/courses/${entityId}/overview`,
		`/admin/courses/${entityId}/lessons`,
		`/admin/courses/${entityId}/quizzes`,
		`/admin/courses/${entityId}/enrollment`
	];

	const currentTab = tabRoutes.findIndex((route) =>
		location.pathname === (route)
	);

	const handleTabChange = (_: React.SyntheticEvent, newTab: number): void => {
		navigate(tabRoutes[newTab]);
	};

	const courseIsOwned = typeof ownedCohort === "object" && (ownedCohort.id === course?.authorCohort?.id);

	return (
		<Box className={classes.adminCoursesLandingPage}>
			<Breadcrumbs className={classes.adminBreadcrumbs} separator="/" aria-label="breadcrumb">
				<Link to={"/admin/courses"}><Typography variant="body1">Courses</Typography></Link>
				<Link to={`/admin/courses/${courseId}`}><Typography variant="body1">{course?.title}</Typography></Link>
			</Breadcrumbs>
			<Box className={classes.adminBanner}>
				{backgroundImageUrl &&
					<Box className={classes.adminBackgroundOverlay}>
						<img className={classes.adminBackgroundImage} src={backgroundImageUrl} />
					</Box>
				}
				<Box className={classes.adminTitleRow}>
					<Typography variant="h3" className={classes.adminBannerTitle}>
						{course?.title}
					</Typography>
					{courseIsOwned
						? <Link to="edit"><Button variant="contained">EDIT COURSE CONTENT</Button></Link>
						: <Box padding={3}><Typography>Shared course from {course?.authorCohort?.title ?? ""}</Typography></Box>
					}
				</Box>
			</Box>
			{(currentTab !== -1) &&
				<Tabs className={classes.adminTabs} value={currentTab} onChange={handleTabChange} aria-label="report tabs">
					<Tab className={classes.adminTab} label="Overview" />
					<Tab className={classes.adminTab} label="Lessons" />
					<Tab className={classes.adminTab} label="Quizzes" />
					<Tab className={classes.adminTab} label="Enrollment" />
				</Tabs>
			}
			<Routes>
				<Route path="/" element={<Navigate to="overview" replace />} />
				<Route path="overview" element={<OverviewTab courseId={entityId} course={course} />} />
				<Route path="lessons" element={<LessonsTab courseId={entityId} course={course} />} />
				<Route path="quizzes" element={<QuizzesTab allowLessonEdit={courseIsOwned} courseId={entityId} course={course} />} />
				<Route path="enrollment" element={<EnrollmentTab courseId={entityId} />} />
			</Routes>
		</Box>
	);
}