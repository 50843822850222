import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {en} from "./i18n/en";
import {es419} from "./i18n/es419";
import deepMerge from "deepmerge";
import {enCommon, es419Common} from "@plumeuk/shapeshift-common/v2";

i18n
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		resources: {
			en: {translation: deepMerge(enCommon, en)},
			"es-419": {translation: deepMerge(es419Common, es419)}
		},
		lng: "en",
		fallbackLng: "en",
		debug: true,
		interpolation: {
			escapeValue: false // not needed for react as it escapes by default
		}
	});

export default i18n;