import {useApi} from "@plumeuk/shapeshift-identity";
import {createContext, ReactElement, Dispatch, FC, useState, useEffect} from "react";
import {ISettings} from "../types/settings";
import i18n from "../i18n";

const initialState: [LocaleContextState, Dispatch<React.SetStateAction<LocaleContextState>>] = [
	{locale: undefined},
	() => {}
];
const LocaleContext = createContext(initialState);

type LocaleContextState = {
	locale?: string
}

interface IProps {
	children: ReactElement
}

const LocaleProvider: FC<IProps> = ({children}) => {
	const local = localStorage.getItem("locale");
	const localParsed = local ? JSON.parse(local) : false;
	const [me] = useApi<ISettings>("/api/user/me")
	const [state, setState] = useState<LocaleContextState>(localParsed ? {locale: localParsed} : {...initialState[0]});

	useEffect(() => {
		if(me.data && me.statusCode === 200){
			setState(prev => ({...prev, locale: me.data?.preferredLanguage}))
			localStorage.setItem("locale", JSON.stringify(me.data?.preferredLanguage))
		}
		else if(me.statusCode === 200)
			localStorage.removeItem("locale");
	}, [me])

	useEffect(() => {
		// default to english if no locale is set
		i18n.changeLanguage(state.locale || "en")
	}, [state])

	return (
		<LocaleContext.Provider value={[state, setState]}>
			{children}
		</LocaleContext.Provider>
	);
};

export {
	LocaleContext,
	LocaleProvider
};