import {APIState, useApi} from "@plumeuk/shapeshift-identity";
import {useEffect} from "react";
import {APIRequestConfig} from "@plumeuk/shapeshift-common/types/ICommon";
import {IAnnouncement} from "../types/announcement";

type S = IAnnouncement[];
type R = string;
type G = (localeOverride?: string, configOverride?: APIRequestConfig<R>) => void;

export type IUseAnnoucementsReturnProp = {
	announcements?: S,
	apiResult: APIState<S>,
	getAnnoucements: G,
	setAnnoucements: (modules: S) => void
}

const announcementsPath = "/api/announcement/learner";

export const useAnnouncements = (locale?: string, data?: S): IUseAnnoucementsReturnProp => {
	const timezone = encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)

	const [apiResult, getData, setAnnoucements] = useApi<S>(data ? null : {url: announcementsPath, data: {timezone, locale}}, data);
	const getAnnoucements: G = (localeOverride, configOverride) => {
		const _locale = localeOverride ?? locale;

		getData({...(configOverride ?? {}), url: announcementsPath, data: {
			timezone,
			locale: _locale
		}});
	}

	useEffect(() => data && setAnnoucements(data), [data])
	return {announcements: apiResult.data, apiResult, getAnnoucements, setAnnoucements};
}