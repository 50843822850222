import {makeStyles} from "tss-react/mui";
import {palette} from "../../constants";
import {Box, BoxProps, CircularProgress, Typography} from "@mui/material";
import {Badge} from "../../components/badge/Badge";
import {IconAlarm} from "../../icons/IconAlarm";
import {IconClock} from "../../icons/IconClock";
import {Add} from "@mui/icons-material";
import {IModuleScheduled} from "@plumeuk/shapeshift-types";
import {formatTimeSecondsApprox} from "@plumeuk/shapeshift-common/common";

interface IProps extends BoxProps {
	isLoading?: boolean,
	day: Date,
	showMonthLabel?: boolean,
	slim?: boolean,
	modules?: IModuleScheduled[],
}

interface IStyleProps {
	isBeforeToday: boolean,
	isToday: boolean,
	isTomorrow: boolean,
	slim?: boolean
}

const useStyles = makeStyles<IStyleProps>()((theme, {isBeforeToday, slim, isToday, isTomorrow}) => ({
	dayContainer: {
		textAlign: "center",
		height: "fit-content",
		display: "flex",
		[theme.breakpoints.down("sm")]: {
			gap: "10px"
		}

	},
	dayIconContainer: {
		width: "56px",
		position: "relative"
	},
	monthLabel: {
		width: "40px",
		textAlign: "center",
		fontSize: "16px",
		marginBottom: "6px",
		fontWeight: "600"
	},
	dayIcon: {
		background: theme.palette.background.default,
		zIndex: 5,
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		width: "36px",
		minWidth: "36px",
		textAlign: "center",
		height: "36px",
		borderRadius: "100px",
		border: "1px solid " + palette.orange,
		"& > p": {
			fontWeight: "600",
			fontSize: "17px",
			lineHeight: "1rem"
		},
		"& svg": {
			marginTop: "4px"
		}
	},
	trainingFeedDayContainerTileContainer: {
		display: "flex",
		gap: "20px",
		width: "100%",
		flexWrap: "wrap",
		margin: "15px 0"
	},
	contentContainer: {
		width: "100%",
		maxWidth: "calc(100vw - 100px)"
	},
	topContainer: {
		display: "flex",
		gap: "30px"
	},
	badgeContainer: {
		display: "flex",
		marginTop: "32px",
		marginBottom: slim ? "20px" : "30px",
		gap: "10px",
		"& > div:nth-of-type(1)": {
			background: isBeforeToday ? palette.error : isToday ? theme.palette.success.main : palette.grey10
		}
	},
	clockIcon: {
		"& svg": {
			transform: "translateY(2px)"
		}
	},
	line: {
		position: "absolute",
		top: "50px",
		zIndex: 0,
		bottom: -50,
		left: "18px",
		width: "2px",
		background: palette.grey15
	}
}));

const getIsTomorrow = (day: Date): boolean => {
	const today = new Date();
	const tomorrow = new Date(today);
	tomorrow.setDate(today.getDate() + 1);

	day.setHours(0, 0, 0, 0);
	tomorrow.setHours(0, 0, 0, 0);

	return day.getTime() === tomorrow.getTime();
};

const getIsNext = (day: Date): boolean => {
	const today = new Date();
	const tomorrow = new Date(today);
	tomorrow.setDate(today.getDate() + 2);

	day.setHours(0, 0, 0, 0);
	tomorrow.setHours(0, 0, 0, 0);

	return day.getTime() === tomorrow.getTime();
};

export const DayContainer: React.FC<IProps> = ({day, modules, className, children, slim, showMonthLabel, isLoading, ...props}) => {
	const month = day.toLocaleString("default", {month: "short"});
	const date = day.getDate();
	const isBeforeToday = day.setHours(0,0,0,0) < new Date().setHours(0,0,0,0);
	const isToday = day.setHours(0,0,0,0) == new Date().setHours(0,0,0,0);
	const isTomorrow = getIsTomorrow(day)
	const isNext = getIsNext(day)
	const {classes, cx} = useStyles({isBeforeToday, isToday, isTomorrow, slim});
	const printBadge = (isBeforeToday || isToday || isTomorrow);
	const duration = printBadge ? modules?.reduce((sum, e) => sum + (e.duration ?? 0), 0) : null;
	const getBadgeContent = (): string => {
		if(isBeforeToday){
			return "OVERDUE"
		}
		if(isToday)
			return "DUE TODAY"
		// if(isTomorrow)
		// 	return "DUE TOMORROW"
		else
			return "UPCOMING"
			// return `DUE ${monthNo < 10 ? `0${monthNo}` : monthNo}/${date < 10 ? `0${date}` : date}`
	}

	return (
		<Box className={cx(classes.dayContainer, className)} {...props}>
			<Box className={classes.dayIconContainer}>
				<Typography className={classes.monthLabel}>{showMonthLabel ? month : "\u00A0"}</Typography>
				<Box className={classes.topContainer}>
					{(printBadge) && <Box className={classes.dayIcon}>
						{isLoading
							? <CircularProgress />
							: <Typography>
								{(isNext) ? <Add sx={{marginTop: "4px"}} /> : (date < 10 ? `0${date}` : date)}
							</Typography>}
					</Box>}
				</Box>
				<Box className={classes.line} />
			</Box>

			<Box className={classes.contentContainer}>
				{(printBadge) && <Box className={classes.badgeContainer}>
					<Badge icon={<IconAlarm />}>
						{getBadgeContent()}
					</Badge>
					{((isBeforeToday || isToday) && !!duration) && <Badge className={classes.clockIcon} icon={<IconClock />}>{formatTimeSecondsApprox(duration)}</Badge>}
				</Box>}
				<Box className={classes.trainingFeedDayContainerTileContainer}>
					{children}
				</Box>

			</Box>
		</Box>
	);
}