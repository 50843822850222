import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {AdminSideMenuContent} from "./AdminSideMenuContent";
import {Route, Routes} from "react-router-dom";
import {AdminUsersPage} from "./Pages/Users/AdminUsersPage";
import {Box, ThemeProvider} from "@mui/material";
import {AdminCoursesPage} from "./Pages/Courses/AdminCoursesPage";
import {AdminReportsPage} from "./Pages/AdminReportsPage";
import {AdminSupportPage} from "./Pages/AdminSupportPage";
import {AdminSettingsPage} from "./Pages/Settings/AdminSettingsPage";
import {AdminLessonsPage} from "./Pages/Lessons/AdminLessonsPage";
import {AdminQuizzesPage} from "./Pages/Quizzes/AdminQuizzesPage";
import {adminTheme} from "../../templates/adminTheme";
import {AdminAnnouncementsPage} from "./Pages/Annoucements/AdminAnnouncementsPage";
import {AdminContainer} from "./AdminContainer";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
		height: "calc(100% + 24px)",
		backgroundColor: adminTheme.palette.grey[900],
		backgroundPosition: "-1px -1px",
		backgroundSize: "74px 74px",
		backgroundImage: "linear-gradient(to right, rgba(255, 255, 255, 0.03) 1px, transparent 1px), linear-gradient(to bottom, rgba(255, 255, 255, 0.03) 1px, transparent 1px)"
	},
	tableContainer: {
		boxSizing: "border-box",
		padding: "40px 28px 32px",
		marginBottom: "52px",
		position: "relative",
		zIndex: "1"
	}
}));

export const ContentManager: React.FC = () => {
	const {classes} = useStyles();

	return (
		<PageBase className={classes.pageBase} sideMenuWidthOverride={"283px"} contentWidth={"100%"} sideBarContent={<AdminSideMenuContent />}>
			<ThemeProvider theme={adminTheme}>
				<AdminContainer size="wide">
					<Box className={classes.tableContainer}>
						<Routes>
							<Route index element={<AdminUsersPage />}/>
							<Route path="users/*" element={<AdminUsersPage />} />
							<Route path="courses/*" element={<AdminCoursesPage />} />
							<Route path="lessons/*" element={<AdminLessonsPage />} />
							<Route path="announcements/*" element={<AdminAnnouncementsPage />} />
							<Route path="quizzes/*" element={<AdminQuizzesPage />} />
							<Route path="reports" element={<AdminReportsPage />} />
							<Route path="support" element={<AdminSupportPage />} />
							<Route path="settings" element={<AdminSettingsPage />} />
						</Routes>
					</Box>
				</AdminContainer>
			</ThemeProvider>
		</PageBase>
	);
}