import {FC, useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Button} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {ModuleNotAvailable} from "@plumeuk/shapeshift-common/module";
import {CurriculumContext} from "../../../contexts/curriculumContext";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";
import {getModule} from "@plumeuk/shapeshift-common/common";
import {ICourseCurriculumModule} from "@plumeuk/shapeshift-types";
import {LessonDataProvider} from "@plumeuk/shapeshift-common/providers";
import "bitmovin-player/bitmovinplayer-ui.css";
import {LocaleContext} from "../../../contexts/localeContext";
import {PageBaseContext} from "../../../contexts/pageBaseContext";
import {LessonPageContent} from "./LessonPageContent";

const useStyles = makeStyles()((theme) => ({
	lessonPage: {
	},
	goToNextLesson: {
		marginTop: "25px"
	},
	notAvailableContainer: {
		width: "100%",
		textAlign: "center"
	}
}));

export const LessonPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const {moduleSlug: lessonSlug} = useParams();
	const {curriculumState: curriculum} = useContext(CurriculumContext);
	const [{contentContainerRef}] = useContext(PageBaseContext);
	const navigate = useNavigate();
	const [firstAvailableModule, setFirstAvailableModule] = useState<ICourseCurriculumModule | false>();
	const videoRef = useRef<VideoPlayerRef | null>(null);
	const [{locale}] = useContext(LocaleContext);

	useEffect(() => {
		videoRef.current = null;
		if (contentContainerRef?.current) {
			contentContainerRef.current.scrollTo({top: 0, behavior: "smooth"});
		}
	}, [lessonSlug])

	useEffect(() => {
		if(courseSlug && lessonSlug && curriculum)
			setFirstAvailableModule(getModule(curriculum) ?? false)
	}, [courseSlug, lessonSlug, curriculum])

	return (
		<Box className={classes.lessonPage}>
			<LessonDataProvider locale={locale} courseSlug={courseSlug} lessonSlug={lessonSlug} curriculum={curriculum} populate={["files", "quiz", "enrollment.videoTime"]}>
				{({lesson, apiResult}) => {
					return <>
						{(apiResult.statusCode === 200 && lesson) && <LessonPageContent lesson={lesson}/>}
						{apiResult.statusCode === 403 && <Box className={classes.notAvailableContainer}>
							<ModuleNotAvailable>
								Lessons must be completed in sequence.
							</ModuleNotAvailable>
							{firstAvailableModule && <Button
								className={classes.goToNextLesson}
								onClick={() => navigate(`/course/${courseSlug}/${firstAvailableModule?.type}/${firstAvailableModule?.slug}`)}
							>
							Go to next available lesson
							</Button>}
							{firstAvailableModule === false && <Button
								className={classes.goToNextLesson}
								onClick={() => navigate("/training-feed")}
							>
							Go back to My Learning
							</Button>}
						</Box>}
					</>}
				}
			</LessonDataProvider>
		</Box>
	);
}