import {FC, useContext} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, LinearProgress} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {AdminAnnouncementList} from "./AdminAnnouncementList";
import {AdminAnnouncementPage} from "./AdminAnnouncementPage";
import {AdminAnnouncementLandingPage} from "./AdminAnnouncementLandingPage";
import {PageBaseContext} from "../../../../contexts/pageBaseContext";

const useStyles = makeStyles()((theme) => ({
	adminLessonsPage: {

	}
}));

export const AdminAnnouncementsPage: FC = () => {
	const {classes} = useStyles();
	const [{bwAdmin}] = useContext(PageBaseContext);

	if(!bwAdmin) return <LinearProgress/>;

	return (
		<Box className={classes.adminLessonsPage}>
			<Routes>
				<Route index element={<AdminAnnouncementList />}/>
				<Route path="create" element={<AdminAnnouncementPage />} />
				<Route path=":announcementId" element={<AdminAnnouncementLandingPage />} />
				<Route path=":announcementId/edit" element={<AdminAnnouncementPage />} />
			</Routes>
		</Box>
	);
}