import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {CourseLibraryPage} from "./pages/CourseLibrary/CourseLibraryPage";
import {ForgottenPasswordPage} from "./pages/ForgottenPassword/ForgottenPassword";
import {ResetPasswordPage} from "./pages/ResetPassword/ResetPassword";
import {CourseLibraryProductPage} from "./pages/CourseLibraryProduct/CourseLibraryProductPage";
import {CoursePage} from "./pages/CoursePage/CoursePage";
import {LoginPage} from "./pages/LoginPage/LoginPage";
import {MyProgressPage} from "./pages/MyProgressPage/MyProgressPage";
import {NotFoundPage} from "./pages/NotFoundPage/NotFoundPage";
import {SandboxPage} from "./pages/Sandbox/sandboxPage";
import {RequireAuth} from "./routes/RequireAuth";
import {ContentManager} from "./pages/ManagerView/ContentManager";
import {TrainingFeedPage} from "./pages/TrainingFeedPage/TrainingFeedPage";
import {SettingsPage} from "./pages/Settings/SettingsPage";
import AppUrlListener from "./routes/AppUrlListener";
import {MyNotesPage} from "./pages/MyNotesPage/MyNotesPage";
import {AnnouncementLandingPage} from "./pages/ModulePage/AnnouncementPage/AnnouncementLandingPage";
import {FeedLandingPage} from "./pages/FeedLandingPage";

const Main: React.FC = () => {
	return (
		<main style={{height: "100%"}}>
			<AppUrlListener></AppUrlListener>
			<div className="App">
				<Routes>
					<Route path="/" element={<LoginPage />} />
					<Route path="/callback" element={<LoginPage />} />
					<Route path="/forgotten-password" element={<ForgottenPasswordPage />} />
					<Route path="/reset-password" element={<ResetPasswordPage />} />
					<Route path="/training-feed" element={<RequireAuth><TrainingFeedPage /></RequireAuth>} />
					<Route path="/my-progress" element={<RequireAuth><MyProgressPage /></RequireAuth>} />
					<Route path="/notes" element={<RequireAuth><MyNotesPage /></RequireAuth>} />
					<Route path="/admin/" element={<Navigate to="/admin/users" replace />} />
					<Route path="/admin/*" element={<RequireAuth><ContentManager /></RequireAuth>} />
					<Route path="/course-library" element={<RequireAuth><CourseLibraryPage /></RequireAuth>} />
					<Route path="/course-library/:courseSlug/*" element={<RequireAuth><CourseLibraryProductPage /></RequireAuth>} />
					<Route path="/course/:courseSlug/*" element={<RequireAuth><CoursePage /></RequireAuth>} />
					<Route path="/feed/announcement/:slug/*" element={<RequireAuth><AnnouncementLandingPage /></RequireAuth>} />
					<Route path="/feed/:courseSlug/*" element={<RequireAuth><CoursePage /></RequireAuth>} />
					<Route path="/feed" element={<RequireAuth><FeedLandingPage /></RequireAuth>} />
					<Route path="/settings" element={<RequireAuth><SettingsPage /></RequireAuth>} />
					<Route path="/sandbox" element={<SandboxPage />} />
					<Route path="*" element={<NotFoundPage />} />
				</Routes>
			</div>
		</main>
	);
};

export default Main;