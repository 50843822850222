import {II18nMap} from "./en";

export const es419: II18nMap = {
	"page_progress_title": "Mi Progreso",
	"page_progress_finished_courses": "Cursos Completados",
	"page_progress_finished_courses_subtitle": "Mira qué cursos has completado hasta ahora",
	"page_progress_active_courses": "Cursos Activos",
	"page_progress_active_courses_subtitle": "Regresa a los cursos en los que participas",
	"page_progress_recent_activity": "Actividad Reciente",
	"page_progress_recent_activity_subtitle": "Mira lo que has completado hasta ahora",
	"page_progress_no_courses": "No hay cursos completados",

	"page_settings_title": "Preferencias de Entrenamiento",
	"page_settings_language": "Idioma Preferido",
	"page_settings_english": "Inglés",
	"page_settings_spanish": "Español",
	"page_settings_manage_account": "Administrar detalles de la cuenta",
	"page_settings_save_button": "Guardar preferencias",
	"page_settings_cancel_button": "Cancelar cambios",

	"page_feed_title": "Mi Feed",

	"page_streak": "Completa todas las tareas pendientes de hoy para aumentar tu racha diaria",

	"nav_feed": "Mi Feed",
	"nav_library": "Biblioteca de Cursos",
	"nav_progress": "Mi Progreso",
	"nav_notes": "Mis Notas",
	"nav_manager": "Gestor de Contenido",

	"nav_mobile_feed": "Noticias",
	"nav_mobile_library": "Biblioteca",
	"nav_mobile_progress": "Progreso",
	"nav_mobile_notes": "Notas",

	"menu_profile_settings": "Preferencias de Entrenamiento",
	"menu_profile_account": "Mi Cuenta",
	"menu_profile_support": "Soporte",
	"menu_profile_logout": "Cerrar sesión",

	"common_notes_delete": "Eliminar",
	"common_notes_cancel": "Cancelar",
	"common_notes_edit": "Editar",

	"notifications_settings_updated": "Tus datos han sido actualizados",
	"notifications_wrong_title": "Algo salió mal",
	"notifications_wrong_body": "Por favor, inténtalo de nuevo",
	"page_notes_all": "Todas",
	"page_notes_title": "Biblioteca de Notas",
	"common_notes_in": "en",
	"common_notes_search": "Buscar"
};


