import {AuthContext, useApi} from "@plumeuk/shapeshift-identity";
import {API} from "@plumeuk/shapeshift-identity";
import {createContext, ReactElement, Dispatch, FC, useState, useEffect, useContext} from "react";
import {jwtDecode} from "jwt-decode";
import {ICohortEntity} from "@plumeuk/shapeshift-types";

const initialState: [PageBaseContextState, Dispatch<React.SetStateAction<PageBaseContextState>>] = [
	{sidebarEnabled: true, sidebarOpen: true, notificationDrawerOpen: false, notificationCount: 0, contentContainerRef: undefined},
	() => {}
];
const PageBaseContext = createContext(initialState);

type PageBaseContextState = {
	ownedCohort?: {title: string, id: number} | false,
	memberedCohort?: {title: string, id: number, comments?: boolean},
	bwAdmin?: boolean,
	sidebarEnabled: boolean,
	sidebarOpen: boolean,
	notificationDrawerOpen: boolean,
	notificationCount: number,
	contentContainerRef: any
}

interface IProps {
	children: ReactElement
}

const PageBaseProvider: FC<IProps> = ({children}) => {
	const local = localStorage.getItem("ownedCohort");
	const localParsedCohortOwned = local ? JSON.parse(local) : false;
	const localParsedCohortMember = local ? JSON.parse(local) : false;
	const [state, setState] = useState<PageBaseContextState>({...initialState[0], ownedCohort: localParsedCohortOwned, memberedCohort: localParsedCohortMember});
	const [{data: ownedCohort, statusCode: ownedCohortStatusCode}] = useApi<false | {title: string, id: number}>(API.cohortIsOwner, localParsedCohortOwned);
	const [{data: memberedCohorts, statusCode: memberedCohortStatusCode}] = useApi<ICohortEntity[]>(API.memberedCohorts, localParsedCohortOwned);
	const {jwt} = useContext(AuthContext);

	useEffect(() => {
		if(ownedCohort && ownedCohortStatusCode === 200){
			setState(prev => ({...prev, ownedCohort}))
			localStorage.setItem("ownedCohort", JSON.stringify(ownedCohort))
		}
		else if(ownedCohortStatusCode === 200)
			localStorage.removeItem("ownedCohort");
	}, [ownedCohort, ownedCohortStatusCode])

	useEffect(() => {
		if(memberedCohorts?.[0] && memberedCohortStatusCode === 200){
			setState(prev => ({...prev, memberedCohort: memberedCohorts?.[0]}))
			localStorage.setItem("memberedCohort", JSON.stringify(memberedCohorts?.[0]))
		}
		else if(memberedCohortStatusCode === 200)
			localStorage.removeItem("memberedCohort");
	}, [memberedCohorts, memberedCohortStatusCode])

	useEffect(() => {
		if(jwt){

			const decoded: any = jwtDecode(jwt);
			const key = Object.keys(decoded.org_id_to_org_member_info)[0];
			const org = decoded.org_id_to_org_member_info[key];

			const allRoles = new Set([
				...(org?.inherited_user_roles_plus_current_role ?? []),
				...(org?.additional_roles ?? []),
				org.user_role
			]);

			setState(prev => ({...prev, bwAdmin: allRoles.has("BuildWitt:Admin")}))
		}
	}, [])

	return (
		<PageBaseContext.Provider value={[state, setState]}>
			{children}
		</PageBaseContext.Provider>
	);
};

export {
	PageBaseContext,
	PageBaseProvider
};