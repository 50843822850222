import {FC} from "react";
import {IAdminUsersResponseEntry, ILessonEntity, ILessonEntityPayload, ILocalizationEntity} from "@plumeuk/shapeshift-types";
import Editor from "../../../../components/admin/WYSIWYG/Editor";
import {AdminEntityPageContainer, AdminFieldContainer, AdminInputDate, AdminInputText, AdminTable, AdminTableProps} from "@plumeuk/shapeshift-common/admin";
import {cohortLessonValidationDoc} from "../../../../validationDocs/user/cohortLessonValidationDoc";
import {AdminEntityPageContainerProps} from "@plumeuk/shapeshift-common/admin"
import AdminVideoUpload from "../Lessons/AdminBitmovinUpload/adminVideoUpload";
import {EntityIdentifier} from "@plumeuk/shapeshift-types/entityService";
import {IAnnouncementEntity, IAnnouncementEntityPayload} from "../../../../types/announcement";
import {Button} from "@mui/material";
import dayjs from "dayjs";
const p: EntityIdentifier = "announcement" as EntityIdentifier;

interface IProps extends Partial<AdminEntityPageContainerProps<ILessonEntity, typeof p>> {
	announcementId?: number
}

const template: ILessonEntityPayload = {
	title: "", slug: "", content: ""
}


const headCells: AdminTableProps<"users", IAdminUsersResponseEntry>["columns"] = ([
	{
		field: "firstname",
		flex: 1,
		headerName: "First Name",
		width: 200,
		type: "string"
	},
	{
		field: "lastname",
		flex: 1,
		headerName: "Last Name",
		width: 200,
		type: "string"
	},
	{
		field: "jobTitle",
		flex: 1,
		headerName: "Job Title",
		width: 200,
		type: "string"
	},
	{
		field: "jobCategory",
		flex: 1,
		headerName: "Job Category",
		hide: true,
		type: "string"
	},
	{
		field: "email",
		flex: 1,
		hide: true,
		headerName: "Email",
		type: "string"
	}
]);

export const AdminAnnouncementPageContent: FC<IProps> = ({announcementId, ...props}) => {

	const ILessonEntityToILessonEntityPayload = (e: IAnnouncementEntity): IAnnouncementEntityPayload => {
		const {title, slug, content, id, createdAt, updatedAt, subtitle, scheduledDeliveryDate, audience} = e
		const videoData = (e as any).videoData;
		const payload = {
			title, slug, content, id, createdAt, updatedAt, subtitle, videoData, scheduledDeliveryDate, audience: audience?.map(e => e.id)
		}
		return payload;
	}

	return (
		<AdminEntityPageContainer<IAnnouncementEntity, typeof p>
			entityId={announcementId}
			baseApiUrl="/api"
			entityToPayload={ILessonEntityToILessonEntityPayload}
			template={template}
			validationDoc={cohortLessonValidationDoc}
			type={p}
			{...props}
		>
			{({formData: _formData, setFormData, entity}) => {
				const formData = _formData as IAnnouncementEntityPayload
				return <>
					{/* <AdminInputText onChange={e => setFormData(prev => ({...prev, slug: e.target.value}))} value={formData.slug} label={"Slug"}/> */}
					<AdminInputText required onChange={e => setFormData(prev => ({...prev, title: e.target.value}))} value={formData.title} label={"Title"}/>
					<AdminInputText onChange={e => setFormData(prev => ({...prev, subtitle: e.target.value}))} value={formData.subtitle} label={"Subtitle"}/>
					<AdminFieldContainer label="Content"><Editor onChange={e => setFormData(prev => ({...prev, content: e.target.value}))} value={formData.content ?? ""} /></AdminFieldContainer>
					<AdminFieldContainer label="Video"><AdminVideoUpload videoThumbnailUrl={entity?.videoThumbnailUrl} videoUrl={entity?.videoUrl} value={(formData as any)?.videoData} entity={entity} initialValue={(entity as any)?.videoData} onChange={e => setFormData(prev => ({...prev, "videoData": e}))}/></AdminFieldContainer>
					<AdminFieldContainer label="Audience">
						<AdminTable<"users", IAdminUsersResponseEntry>
							url="/api/user"
							columns={headCells}
							enableDelete={false}
							type="users"
							onSelectionModelChange={e => {
								setFormData(prev => ({...prev, audience: e as number[]}))
							}}
							selectionModel={formData.audience ?? []}
							keepNonExistentRowsSelected
						/>
					</AdminFieldContainer>
					<AdminInputDate
						required
						onChange={e => setFormData(prev => ({...prev, scheduledDeliveryDate: e}))}
						value={formData.scheduledDeliveryDate ? dayjs(formData.scheduledDeliveryDate) : null}
						label={"Scheduled Delivery Date"}
					/>
					<Button size="large" onClick={() => setFormData(prev => ({...prev, scheduledDeliveryDate: new Date()}))}>Schedule now</Button>
				</>}}
		</AdminEntityPageContainer>
	);
}