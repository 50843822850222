import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {GridToolbarColumnsButton, GridToolbarContainer, GridToolbarContainerProps, GridToolbarDensitySelector, GridToolbarExport,GridToolbarFilterButton} from "@mui/x-data-grid-pro";

const useStyles = makeStyles()((theme) => ({
	customToolbar: {
		display: "flex",
		gap: theme.spacing(1),
		"& svg": {
			fontSize: "18px"
		},
		"& .MuiBox-root": {
			color: theme.palette.primary.main,
			cursor: "pointer",
			padding: theme.spacing(1.2, 1),
			fontSize: "0.8125rem",
			fontWeight: 600,
			"& svg": {
				"& path" :{
					fill: theme.palette.primary.main
				},
				color: theme.palette.primary.main,
				top: "5px",
				position: "relative"
			},
			top: "-2px",
			position: "relative",
			transition: "opacity .3s",
			"&:hover": {
				opacity: .8
			}
		}
	}
}));

interface ICustomProps {
	exportButton?: boolean;
	columnsButton?: boolean;
	filterButton?: boolean;
	densityButton?: boolean;
}

export type IProps = ICustomProps & GridToolbarContainerProps;

export const CustomToolbar: FC<IProps> = ({children, exportButton = true, columnsButton = true, densityButton, filterButton, className, ...baseProps}) => {
	const {cx, classes} = useStyles();

	// To do: known issue https://github.com/mui/mui-x/issues/7288
	const buttonBaseProps = {} as any

	return (
		<GridToolbarContainer className={cx(classes.customToolbar, className)} {...baseProps}>
			{columnsButton 	&& <GridToolbarColumnsButton 	{...buttonBaseProps} />}
			{filterButton 	&& <GridToolbarFilterButton 	{...buttonBaseProps} />}
			{densityButton 	&& <GridToolbarDensitySelector 	{...buttonBaseProps} />}
			{exportButton 	&& <GridToolbarExport /> }
			{children}
		</GridToolbarContainer>
	)
};