import {FC, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {Link, Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {ILibraryCourse} from "@plumeuk/shapeshift-types";
import {bannerHeight, palette} from "../../constants";
import {LessonsTab} from "./Tabs/LessonsTab";
import {OverviewTab} from "./Tabs/OverviewTab";
import {useSetCourseEnrollment} from "@plumeuk/shapeshift-common/hooks";
import {LinearProgress, Box, Breadcrumbs, Typography, CircularProgress, Tabs, Tab, Button} from "@mui/material";

interface IStyleProps {
	hasBackgroundImage?: boolean
}

const useStyles = makeStyles<IStyleProps>()((theme, {hasBackgroundImage}) => ({
	courseLibraryLandingPage: {
		marginBottom: "40px",
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		},
		"& [class*='lessonsAdminTable']": {
			border: 0
		}
	},
	breadcrumbs: {
		marginBottom: "25px"
	},
	banner: {
		position: "relative",
		display: "flex",
		width: "100%",
		height: hasBackgroundImage ? bannerHeight : "auto",
		borderRadius: "5px",
		overflow: "hidden",
		marginBottom: "12px"
	},
	bannerTitle: {
		position: "relative",
		fontSize: "34px",
		lineHeight: "41px",
		fontWeight: "600",
		letterSpacing: "0.25px",
		alignSelf: "end",
		margin: "32px 20px 20px",
		zIndex: 2
	},
	backgroundOverlay: {
		"&:after": {
			content: "''",
			position: "absolute",
			bottom: 0,
			left: 0,
			width: "100%",
			height: "100%",
			background: "linear-gradient(to top, #121212 0%, transparent 100%);",
			zIndex: 1
		}
	},
	titleRow:{
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		alignSelf: "flex-end",
		width: "100%",
		paddingRight: theme.spacing(3),
		zIndex: 2
	},
	backgroundImage: {
		position: "absolute",
		left: 0,
		top: 0,
		width: "100%",
		height: "100%",
		objectFit: "cover"
	},
	tabs: {
		borderBottom: 0,
		"& [class*=indicator]": {
			bottom: "4px",
			height: "2px"
		}
	},
	tab: {
		fontSize: "14px",
		fontWeight: "600",
		minHeight: "40px",
		minWidth: "85px",
		padding: 0,
		paddingLeft: "15px",
		paddingRight: "15px",
		marginLeft: 0,
		letterSpacing: "0.4px",
		textTransform: "uppercase",
		"&.Mui-selected": {
			color: palette.orange,
			background: "transparent",
			"& svg path": {
				fill: palette.orange
			}
		}
	}
}));

interface ICustomProps {
	course?: ILibraryCourse;
	courseSlug?: string;
}

export type IProps = ICustomProps

export const CourseLibraryLandingPage: FC<IProps> = ({course, courseSlug}) => {
	const backgroundImageUrl = course?.featuredImage?.formats?.large?.url ?? course?.featuredImage?.url;
	const hasBackgroundImage = !!backgroundImageUrl;
	const {classes} = useStyles({hasBackgroundImage});
	const location = useLocation();
	const navigate = useNavigate();
	const {setEnroll, apiResult} = useSetCourseEnrollment();

	useEffect(() => {
		if(apiResult.statusCode === 200 || apiResult.statusCode === 204){
			navigate(`/course/${courseSlug}`)
		}
	}, [apiResult])


	const tabRoutes = [
		`/course-library/${courseSlug}/overview`,
		`/course-library/${courseSlug}/lessons`
	];

	const currentTab = tabRoutes.findIndex((route) =>
		location.pathname === (route)
	);

	const handleTabChange = (_: React.SyntheticEvent, newTab: number): void => {
		navigate(tabRoutes[newTab]);
	};

	if(!course || !courseSlug)
		return <LinearProgress />

	return (
		<Box className={classes.courseLibraryLandingPage}>
			<Breadcrumbs className={classes.breadcrumbs} separator="/" aria-label="breadcrumb">
				<Link to={"/course-library"}><Typography variant="body1">Courses</Typography></Link>
				<Link to={`/course-library/${courseSlug}`}><Typography variant="body1">{course?.title}</Typography></Link>
			</Breadcrumbs>
			<Box className={classes.banner}>
				{backgroundImageUrl &&
					<Box className={classes.backgroundOverlay}>
						<img className={classes.backgroundImage} src={backgroundImageUrl} />
					</Box>
				}
				<Box className={classes.titleRow}>
					<Typography variant="h3" className={classes.bannerTitle}>
						{course?.title}
					</Typography>
					<Button onClick={() => setEnroll(courseSlug)} variant="outlined">{apiResult.isLoading ? <CircularProgress /> : "ENROLL"}</Button>
				</Box>
			</Box>
			{(currentTab !== -1) &&
				<Tabs className={classes.tabs} value={currentTab} onChange={handleTabChange} aria-label="report tabs">
					<Tab className={classes.tab} label="Overview" />
					<Tab className={classes.tab} label="Lessons" />
				</Tabs>
			}
			<Routes>
				<Route path="/" element={<Navigate to="overview" replace />} />
				<Route path="overview" element={<OverviewTab course={course} />} />
				<Route path="lessons" element={<LessonsTab course={course} />} />
			</Routes>
		</Box>
	);
}