import {makeStyles} from "tss-react/mui";
import {IModuleScheduled} from "@plumeuk/shapeshift-types";
import {Box, BoxProps, CircularProgress, Typography} from "@mui/material";
import {TrainingFeedModule} from "../../../components/trainingFeed/TrainingFeedModule";
import {TrainingFeedModuleSlim} from "../../../components/trainingFeed/trainingFeedModuleSlim";
import {useAnnouncements} from "../../../hooks/useAnnouncements";
import {palette} from "../../../constants";
import {Badge} from "../../../components/badge/Badge";
import {TrainingFeedAnnouncement} from "./TrainingFeedAnnouncement";
import {IAnnouncement} from "../../../types/announcement";
import {TrainingFeedAnnouncementSlim} from "./TrainingFeedAnnouncementSlim";
import {useContext} from "react";
import {AnnouncementContext} from "../../../contexts/announcementContext";
import {IconAnnouncement} from "../../../icons/IconAnnouncement";

interface IProps extends BoxProps {
	data?: IAnnouncement[],
	slim?: boolean
}

interface IStyleProps {
	slim?: boolean
}

const useStyles = makeStyles<IStyleProps>()((theme, {slim}) => ({

	announcements: {
		textAlign: "center",
		height: "fit-content",
		display: "flex",
		[theme.breakpoints.down("sm")]: {
			gap: "10px"
		}
	},
	announcementsContainer: {
		width: "100%",
		display: "flex",
		gap: "20px",
		flexWrap: "wrap",
		marginBottom: slim ? 0 : "25px"
	},
	dayIconContainer: {
		width: "56px",
		position: "relative"
	},
	monthLabel: {
		width: "40px",
		textAlign: "center",
		fontSize: "16px",
		marginBottom: "6px",
		fontWeight: "600"
	},
	dayIcon: {
		background: theme.palette.background.default,
		zIndex: 5,
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		width: "36px",
		minWidth: "36px",
		textAlign: "center",
		height: "36px",
		borderRadius: "100px",
		border: "1px solid " + palette.orange,
		"& > p": {
			fontWeight: "600",
			fontSize: "17px",
			lineHeight: "1rem"
		},
		"& svg": {
			marginTop: "4px"
		}
	},
	trainingFeedDayContainerTileContainer: {
		display: "flex",
		gap: "20px",
		width: "100%",
		flexWrap: "wrap",
		margin: "15px 0"
	},
	contentContainer: {
		width: "100%",
		maxWidth: "calc(100vw - 100px)"
	},
	topContainer: {
		display: "flex",
		gap: "30px"
	},
	badgeContainer: {
		display: "flex",
		marginTop: "32px",
		marginBottom: slim ? "20px" : "30px",
		gap: "10px",
		"p": {
			color: theme.palette.common.black
		},
		"& > div:nth-of-type(1)": {
			background: theme.palette.primary.main
		}
	},
	clockIcon: {
		"& svg": {
			transform: "translateY(2px)"
		}
	},
	line: {
		position: "absolute",
		top: "50px",
		zIndex: 0,
		bottom: -50,
		left: "18px",
		width: "2px",
		background: palette.grey15
	}
}));

export const Announcements: React.FC<IProps> = ({data, className, slim, ...props}) => {
	const {classes, cx} = useStyles({slim});
	const month = new Date().toLocaleString("default", {month: "short"});
	const {announcementState} = useContext(AnnouncementContext)

	if((announcementState?.length ?? 0) === 0)
		return <></>

	return (
		<Box className={cx(classes.announcements, className)} {...props}>
			<Box className={classes.dayIconContainer}>
				<Typography className={classes.monthLabel}>{month}</Typography>
				<Box className={classes.topContainer}>
					<Box className={classes.dayIcon}>
						{announcementState === undefined
							? <CircularProgress />
							: <Typography>
								<IconAnnouncement/>
							</Typography>}
					</Box>
				</Box>
				<Box className={classes.line} />
			</Box>

			<Box className={classes.contentContainer}>
				<Box className={classes.badgeContainer}>
					<Badge>
						COMPANY ANNOUNCEMENT
					</Badge>
				</Box>
				<Box className={classes.trainingFeedDayContainerTileContainer}>
					<Box className={cx(classes.announcementsContainer, className)} {...props}>
						{announcementState?.map(module => slim
							? <TrainingFeedAnnouncementSlim
								key={module.id + "_" + module.type + "_" + module.id}
								module={module as any}
							/>
							: <TrainingFeedAnnouncement
								key={module.id + "_" + module.type + "_" + module.id}
								module={module as any}
							/>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	)
}