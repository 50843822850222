/* eslint-disable no-console */
import {FC, useEffect, useState} from "react";
import {NotificationProvider as SSNotificationProvider} from "@plumeuk/shapeshift-identity";
import {Capacitor} from "@capacitor/core";
import {ActionPerformed, PushNotificationSchema, PushNotifications, Token} from "@capacitor/push-notifications";
import {useSetNotificationToken} from "@plumeuk/shapeshift-common/hooks";

interface IProps {
	children: JSX.Element
}

const NotificationProvider: FC<IProps> = ({children}) => {
	const [platform] = useState(Capacitor.getPlatform());
	const [token, setToken] = useState<string>();
	const {setNotificationToken, apiResult} = useSetNotificationToken();

	useEffect(() => {
		//alert("send notification token---+"+JSON.stringify(apiResult))
		//console.log("---+", apiResult)
	}, [apiResult])

	useEffect(() => {
		if(token){
			// console.log("---+", token);
			// alert("---+: "+ token);
			setNotificationToken(token)
		}
	}, [token])

	const registerPush = (): void => {
		// console.log("---+registerPush");
		// alert("---+:registerPush ");

		PushNotifications.requestPermissions().then((permission) => {
			if (permission.receive === "granted") {
				// Register with Apple / Google to receive push via APNS/FCM
				PushNotifications.register();
				// console.log("---+registerPush granted");
				// alert("---+:registerPush granted");
			} else {
				// No permission for push granted
				// console.log("---+registerPush not granted");
				//alert("---+:registerPush not granted");
			}
		});

		PushNotifications.addListener("registration", (FCMToken: Token) => {
			// console.log("---+My token: " + JSON.stringify(FCMToken));
			//alert("---+My token: " + JSON.stringify(FCMToken));
			//This is a token
			setToken(FCMToken.value)
		});

		PushNotifications.addListener("registrationError", (error: any) => {
			// console.error("---+Registration error: ", error);
			//alert("---+ ERROR" + JSON.stringify(error))
		});

		PushNotifications.addListener("pushNotificationReceived", (notification: PushNotificationSchema) => {
			// console.log("---+Push notification received: ", notification);
			//alert("---+Push notification received: " +JSON.stringify(notification))
		});

		PushNotifications.addListener("pushNotificationActionPerformed", (notification: ActionPerformed) => {
			const data = notification.notification.data;
			// console.log("---+Action performed: ", notification.notification);
			// alert("---+Action performed: " + JSON.stringify(notification.notification));
			if (data.detailsId) {
				// this.router.navigateByUrl(`/home/${data.detailsId}`);
			}
		});
	}

	useEffect(() => {
		if (platform !== "web") {
			registerPush();
		}
	}, [])


	return (
		<SSNotificationProvider><>
			{/* <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
		TOKEN RESPONSE{JSON.stringify(apiResult)}
		<br/>
		TOKEN: {token} */}
			{children}
		</>

		</SSNotificationProvider>
	)
}

export default NotificationProvider;