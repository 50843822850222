import {FC, useContext, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Switch} from "@mui/material";
import {INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {PageBaseContext} from "../../../../../contexts/pageBaseContext";

const useStyles = makeStyles()((_theme) => ({
	adminCoursesList: {
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		}
	}
}));

interface IProps {
	courseId?: number,
	initialState: boolean
}


export const CourseBlacklistToggle: FC<IProps> = ({courseId, initialState}) => {
	const [{ownedCohort}] = useContext(PageBaseContext)
	const [toggleBlacklistResponse, toggleBlacklist] = useApi<boolean>()
	const [value, setValue] = useState(initialState);
	const {notify} = useContext(NotificationContext);

	useEffect(() => {
		if(toggleBlacklistResponse.statusCode === 200){
			setValue(!!toggleBlacklistResponse.data)
			if(toggleBlacklistResponse.data)
				notify("Please note: Currently enrolled users will still have access but it will no longer show up in any users course library, nor will it automatically be assigned.", "Course hidden from team", INotificationSeverity.success, 10000)
			else
				notify("The course will now appear in users course library and is available for automatic enrollment.", "Course now available", INotificationSeverity.success, 10000)
		}
		if(toggleBlacklistResponse.isError && toggleBlacklistResponse.statusCode !== undefined && toggleBlacklistResponse.statusCode !== 200){
			notify("Please try again", "Failed to toggle course", INotificationSeverity.error, 5000)
		}
	}, [toggleBlacklistResponse.statusCode])

	const cohortId = (typeof ownedCohort === "object" ? ownedCohort : undefined)?.id;

	if(!courseId || !cohortId)
		return <></>

	return (
		<Switch onClick={(x) => {
			x.stopPropagation();
			toggleBlacklist({
				url: `/api/strapi-plugin-shapeshift-lms/cohort/${cohortId}/course/${courseId}/blacklist`,
				data: {state: !value},
				method: "PUT"
			})
		}}
		checked={value}/>
	);
}