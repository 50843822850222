import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Button, ButtonProps, CircularProgress, Typography} from "@mui/material";

const useStyles = makeStyles()((theme) => ({
	adminHeader: {
		display: "flex",
		justifyContent: "space-between",
		marginBottom: "32px"
	},
	adminTitle: {
		fontWeight: 600
	},
	adminSubtitle: {
		fontWeight: 400,
		fontSize: "16px",
		letterSpacing: "0.15px",
		opacity: "0.8"
	},
	buttons: {
		display: "flex",
		gap: "8px",
		"& > button": {
			height: "36px",
			padding: "0px 16px",
			fontSize: "14px",
			textTransform: "uppercase",
			borderRadius: "6px",
			display: "flex",
			gap: "8px",
			fontWeight: 600,
			background: theme.palette.secondary.main,
			color: theme.palette.background.default,
			"& > svg": {
				width: "17px"
			}
		}
	}
}));

interface ICourseheaderContentProps {
	title: string,
	subtitle?: string,
	actionButtons?: (null | ({label: string, loading?: boolean} & Omit<ButtonProps, "label" | "children">))[]
}

export const AdminHeader: FC<ICourseheaderContentProps> = ({title, subtitle, actionButtons}) => {
	const {classes} = useStyles();
	return (
		<Box className={classes.adminHeader}>
			<Box>
				<Typography variant="h3" className={classes.adminTitle}>{title}</Typography>
				{subtitle &&
					<Typography className={classes.adminSubtitle}>{subtitle}</Typography>
				}
			</Box>
			<Box className={classes.buttons}>
				{(actionButtons ?? []).filter(e => !!e).map((e, i) => <Button key={"action-btn-" + i} {...e}>{e?.loading ? <CircularProgress size={20}/> : e?.label}</Button>)}
			</Box>
		</Box>
	);
}