import {II18nMap as CommonII18nMap} from "@plumeuk/shapeshift-common/v2";

const enAdditional = {

	"page_progress_title": "My Progress",
	"page_progress_finished_courses": "Finished Courses",
	"page_progress_finished_courses_subtitle": "See what courses you’ve completed so far",
	"page_progress_active_courses": "Active Courses",
	"page_progress_active_courses_subtitle": "Jump back into courses you participate in",
	"page_progress_recent_activity": "Recent Activity",
	"page_progress_recent_activity_subtitle": "See what you’ve completed so far",
	"page_progress_no_courses": "No courses completed",

	"page_settings_title": "Training Preferences",
	"page_settings_language": "Preferred language",
	"page_settings_english": "English",
	"page_settings_spanish": "Spanish",
	"page_settings_manage_account": "Manage account details",
	"page_settings_save_button": "Save preferences",
	"page_settings_cancel_button": "Cancel changes",

	"page_feed_title": "My Feed",

	"page_notes_title": "Notes library",
	"page_notes_all": "All",

	"page_streak": "Complete all tasks due today to increase your daily streak",

	"nav_feed": "My Feed",
	"nav_library": "Course Library",
	"nav_progress": "My Progress",
	"nav_notes": "My Notes",
	"nav_manager": "Content Manager",
	"nav_mobile_feed": "My Feed",
	"nav_mobile_library": "Course Library",
	"nav_mobile_progress": "My Progress",
	"nav_mobile_notes": "My Notes",

	"menu_profile_settings": "Training Preferences",
	"menu_profile_account": "My Account",
	"menu_profile_support": "Support",
	"menu_profile_logout": "Log Out",

	"notifications_settings_updated": "Your details have been updated",
	"notifications_wrong_title": "Something went wrong",
	"notifications_wrong_body": "Please try again"
}

export const enCommonOverride: Partial<CommonII18nMap> = {
	"common_notes_delete": "Delete",
	"common_notes_edit": "Edit",
	"common_notes_cancel": "Cancel"
};

export const en = {...enAdditional, ...enCommonOverride}

export type II18nMap = {[K in keyof typeof en]: string};

