import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {DayContainer} from "./DayContainer";
import {OverdueModules} from "./OverdueModules";
import {ScheduledDayModules} from "./ScheduledDayModules";
import {useCallback, useContext, useEffect, useState} from "react";
import {TrainingfeedContext} from "../../contexts/trainingFeedContext";
import {Box, Button} from "@mui/material";
import {PageTitle} from "../../components/pageBase/pageTitle";
import {Announcements} from "./Announcements/Announcements";
import {AnnouncementContext} from "../../contexts/announcementContext";
import {useSSTranslation} from "../../i18n/useSSTranslation";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
		"& [class*='menuIcon']": {
			display: "none"
		}
	},
	tapToLoadBtn: {},
	container:{
		padding: "15px"

	}
}));


export const TrainingFeedPage: React.FC = () => {
	const {classes} = useStyles();
	const {trainingfeedState, trainingfeedDispatch} = useContext(TrainingfeedContext)
	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1)
	yesterday.setHours(0, 0, 0, 0)
	const {announcementState} = useContext(AnnouncementContext)
	const {t} = useSSTranslation();

	const next = new Date();
	next.setDate(next.getDate() + 2);

	const [nextDayToLoad, setNextDayToLoad] = useState<Date>(() => {
		const e = new Date();
		e.setDate(e.getDate() + 3);
		return e;
	});

	const handleRequestDay = useCallback(() => {
		trainingfeedDispatch({type: "getDay", day: nextDayToLoad})
		const newDate = new Date(nextDayToLoad)
		newDate.setDate(newDate.getDate() + 1);
		setNextDayToLoad(newDate)
	}, [nextDayToLoad])

	useEffect(() => {
		if(!trainingfeedState?.[next.getTime()]){
			trainingfeedDispatch({type: "getDay", day: next})
		}
	})

	const overDueModules = trainingfeedState?.["overdue"];
	const hasOverdue = typeof overDueModules !== "string" && (overDueModules?.length ?? 0) > 0 ;
	const hasAnnouncements = (announcementState?.length ?? 0) > 0;

	return (
		<PageBase className={classes.pageBase} disableSideBar contentWidth="1420px">
			<PageTitle sx={{margin: "0 0 0 100px"}} enableDayStreak title={t("page_feed_title", "My Feed")} />
			<Box className={classes.container}>
				{hasAnnouncements && <Announcements data={announcementState}/>}
				{hasOverdue &&
					<DayContainer modules={(overDueModules) ?? []} showMonthLabel={!hasAnnouncements} day={yesterday}>
						<OverdueModules modules={(overDueModules) ?? []}/>
					</DayContainer>
				}
				{Object.keys(trainingfeedState ?? {}).sort((a,b) => {
					if (a === "overdue") return -1;
					if (b === "overdue") return 1;
					return Number(a) - Number(b);
				}).filter(e => e !== "overdue")
					.map((day, i) => {
						const dayDate = new Date(parseInt(day))
						dayDate.setHours(0, 0, 0, 0);

						const modules = trainingfeedState?.[day];
						return <DayContainer modules={(typeof modules === "string" ? [] : modules) ?? []} showMonthLabel={!hasAnnouncements && !hasOverdue && i === 0} key={day + "-" + i} day={dayDate}>
							<ScheduledDayModules
								isLoading={modules === "loading"}
								data={(typeof modules === "string" ? [] : modules) ?? []}
								day={next}
							/>
						</DayContainer>
					})}
				<DayContainer day={nextDayToLoad}>
					<Button onClick={handleRequestDay} className={classes.tapToLoadBtn}>Tap to load</Button>
				</DayContainer>
			</Box>
		</PageBase>
	);
}