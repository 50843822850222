import {Box, ThemeProvider} from "@mui/material";
import {LibraryCourseDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC, useContext} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {PageBase} from "../../components/pageBase/pageBase";
import {LocaleContext} from "../../contexts/localeContext";
import {CourseLibraryLandingPage} from "./CourseLibraryLandingPage";
import {AdminContainer} from "../ManagerView/AdminContainer";

const useStyles = makeStyles()((theme) => ({
	courseLibraryProductPage: {
		marginTop: "20px",
		paddingTop: "80px",
		padding: "20px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		}
	}
}));


export const CourseLibraryProductPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const [{locale}] = useContext(LocaleContext);

	return (
		<PageBase contentWidth={"100%"} disableSideBar>
			<AdminContainer size="constrained">
				<LibraryCourseDataProvider locale={locale} slug={courseSlug} >
					{({course}) => <Box className={classes.courseLibraryProductPage}>
						<CourseLibraryLandingPage courseSlug={courseSlug} course={course} /></Box>
					}
				</LibraryCourseDataProvider>
			</AdminContainer>
		</PageBase>
	);
}