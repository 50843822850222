import {FC, useContext, useEffect, useState} from "react";
import {Box, BoxProps, LinearProgress, Stack, Switch} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {useApi} from "@plumeuk/shapeshift-identity";
import {DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF, GridColumns} from "@mui/x-data-grid-pro";
import {IPaginatedResponse, IUserCourseEnrollmentStatus} from "@plumeuk/shapeshift-types";
import {Add, Check, Undo} from "@mui/icons-material";
import {AdminBulkEnrollmentModal} from "../../../../../../components/admin/bulkEnrollment/AdminBulkEnrollmentModal";
import {AdminBulkCourseCompletionModal} from "../../../../../../components/admin/bulkEnrollment/AdminBulkCourseCompletionModal";
import {AdminBulkCourseUnenrollModal} from "../../../../../../components/admin/bulkEnrollment/AdminBulkCourseUnenrollmentModal";
import {CustomToolbar} from "../../../../../../components/admin/bulkEnrollment/CustomToolbar";
import {PageBaseContext} from "../../../../../../contexts/pageBaseContext";

const useStyles = makeStyles()((theme) => ({
	contentBox: {
		display: "flex",
		flexDirection: "column",
		marginTop: theme.spacing(3),
		padding: theme.spacing(2),
		backgroundColor: theme.palette.background.paper,
		borderWidth: theme.borderWidth.small,
		borderColor: theme.palette.grey[100],
		borderStyle: "solid",
		borderRadius: theme.borderRadius.small
	},
	enrollmentAdminTable: {
		padding: 0,
		border: 0
	},
	progressBar: {
		width:"100%"
	},
	progressBarRoot:{
		width:"100%",
		alignItems:"center",
		gap: theme.spacing(2)
	},
	enrollmentDataGrid: {
		padding: 0,
		border: 0
	}
}));

interface ICustomProps {
	courseId: number
}

export type IProps = Omit<BoxProps, "children"> & ICustomProps;

export const EnrollmentTab:FC<IProps> = ({courseId, ...baseProps}) => {
	const {classes} = useStyles();
	const [rows, getRows] = useApi<IPaginatedResponse<IUserCourseEnrollmentStatus[]>>()
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [bulkEnrollModalOpen, setBulkEnrollModalOpen] = useState<boolean>(false);
	const [bulkUnEnrollModalOpen, setBulkUnEnrollModalOpen] = useState<boolean>(false);
	const [bulkCompletionModalOpen, setBulkCompletionModalOpen] = useState<boolean>(false);
	const [selected, setSelected] = useState<number[]>([]);
	const [selectedSingular, setSelectedSingular] = useState<number>();
	const [{ownedCohort}] = useContext(PageBaseContext)

	const headCells: GridColumns<IPaginatedResponse<IUserCourseEnrollmentStatus[]>["data"][0]> = ([
		{
			...GRID_CHECKBOX_SELECTION_COL_DEF,
			type: "string",
			hideable: false
		},
		{
			field: "firstname",
			flex: 1,
			headerName: "First Name",
			type: "string"
		},
		{
			field: "lastname",
			flex: 1,
			headerName: "Last Name",
			type: "string"
		},
		{
			field: "email",
			flex: 1,
			headerName: "Email",
			type: "string"
		},
		{
			field: "jobCategory",
			flex: 1,
			headerName: "Job Category",
			type: "string"
		},
		{
			field: "enrolled",
			flex: 1,
			headerName: "Enroll",
			type: "string",
			renderCell: e => {
				return <Switch onClick={(x) => {
					x.stopPropagation();
					if(e.value){
						setBulkUnEnrollModalOpen(true)
					}
					else {
						setBulkEnrollModalOpen(true)
					}
					setSelectedSingular(e.id as number)
				}}
				checked={e.value}/>
			}
		},
		{
			field: "dueDate",
			flex: 1,
			headerName: "Due Date",
			type: "date"
		},
		{
			field: "type",
			flex: 1,
			headerName: "Enrollment Type",
			type: "string",
			valueGetter: (e) => {
				if(e.value === "learner")
					return "Self Assigned"
				if(e.value === "strapi-admin")
					return "Admin Assigned"
				if(e.value === "due-date-schedule")
					return "Due Date Assigned"
				if(e.value === "prioritize-schedule")
					return "Prioritize Assigned"
				if(e.value === "auto-assign-schedule"){
					return "Auto Assigned"
				}
			}
		},
		{
			field: "progress",
			flex: 1,
			headerName: "Course Completion",
			type: "string",
			renderCell: e => {
				const {classes} = useStyles();
				return <Stack className={classes.progressBarRoot} direction="row"><LinearProgress className={classes.progressBar} variant="determinate" value={e.value} /><span>{`${e.value}%`}</span></Stack>
			}
		},
		{
			field: "division",
			flex: 1,
			type: "string",
			headerName: "Division"
		},
		{
			field: "supervisorName",
			flex: 1,
			type: "string",
			headerName: "Supervisor"
		}
	])

	const Toolbar= (): JSX.Element => (<CustomToolbar filterButton>
		<Box onClick={() => setBulkEnrollModalOpen(true)}><Add /> ENROLL</Box>
		<Box onClick={() => setBulkUnEnrollModalOpen(true)}><Undo /> UNENROLL</Box>
		<Box onClick={() => setBulkCompletionModalOpen(true)}><Check /> COMPLETE</Box>
	</CustomToolbar>)

	useEffect(() => {
		if(ownedCohort)
			getRows(`/api/strapi-plugin-shapeshift-lms/cohort/${ownedCohort.id}/course/${courseId}/users/enrollments?pageSize=${pageSize}&page=${page}`)
	}, [courseId, page, pageSize])

	return <Box {...baseProps}>
		<Box className={classes.contentBox}>
			<DataGridPro<IPaginatedResponse<IUserCourseEnrollmentStatus[]>["data"][0]>
				initialState={{
					sorting: {
						sortModel: [{field: "assignedOn", sort: "desc"}]
					},
					columns: {
						columnVisibilityModel: {
							email: false,
							supervisorName: false
						}
					}
				}}
				className={classes.enrollmentDataGrid}
				checkboxSelection
				autoHeight
				aria-label="admin table"
				loading={rows.isLoading}
				pagination
				selectionModel={selected}
				onSelectionModelChange={e => setSelected(e as number[])}
				paginationMode="server"
				rowCount={rows?.data?.totalCount ?? 0}
				rows={rows?.data?.data ?? []}
				page={(page - 1)}
				pageSize={pageSize}
				onPageSizeChange={e => setPageSize(e)}
				onPageChange={e => setPage(e + 1)}
				columns={headCells}
				rowsPerPageOptions={[5, 10, 20, 100]}
				components={{Toolbar}}
			/>
			<AdminBulkEnrollmentModal courseId={courseId} onClose={(r) =>{ setSelected([]); setSelectedSingular(undefined); setBulkEnrollModalOpen(false); if(r) getRows()}} open={bulkEnrollModalOpen} selected={selectedSingular ? [selectedSingular]: selected} />
			<AdminBulkCourseUnenrollModal courseId={courseId} onClose={(r) =>{ setSelected([]); setSelectedSingular(undefined); setBulkUnEnrollModalOpen(false); if(r) getRows()}} open={bulkUnEnrollModalOpen} selected={selectedSingular ? [selectedSingular]: selected} />
			<AdminBulkCourseCompletionModal courseId={courseId} onClose={(r) =>{setSelected([]); setSelectedSingular(undefined); setBulkCompletionModalOpen(false); if(r) getRows()}} open={bulkCompletionModalOpen} selected={selectedSingular ? [selectedSingular]: selected} />
		</Box>
	</Box>
}