export function convertSecondsToHHMM(seconds: number): string {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);

	// Pad with zeros if necessary
	const paddedHours = String(hours).padStart(2, "0");
	const paddedMinutes = String(minutes).padStart(2, "0");

	return `${paddedHours}:${paddedMinutes}`;
}

export function convertSecondsToHHMMSS(totalSeconds: number): string {
	const hours = Math.floor(totalSeconds / 3600);
	const minutes = Math.floor((totalSeconds % 3600) / 60);
	const seconds = totalSeconds % 60;

	// Pad with zeros if necessary
	const paddedHours = String(hours).padStart(2, "0");
	const paddedMinutes = String(minutes).padStart(2, "0");
	const paddedSeconds = String(seconds).padStart(2, "0");

	if (hours === 0) {
		return `${paddedMinutes}:${paddedSeconds}`;
	}

	return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

export function convertSecondsToReadable(totalSeconds?: number | null): string|null {
	if (!totalSeconds) {
		return null
	}

	const hours = Math.floor(totalSeconds / 3600);
	const minutes = Math.floor(totalSeconds / 60);
	const seconds = totalSeconds % 60;

	if (hours === 0) {
		return minutes > 0 ? `${minutes} min ${seconds} sec` : `${seconds} sec`;
	}

	return `${hours} h ${minutes} m`
}