import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {App, URLOpenListenerEvent} from "@capacitor/app";

const AppUrlListener: React.FC<any> = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const handleAppUrlOpen = (event: URLOpenListenerEvent): void => {

			if (event.url.startsWith("bwtrain://")) {
				// Parse the URL and handle the deep link
				const url = new URL(event.url);
				const code = url.searchParams.get("code");
				const state = url.searchParams.get("state");
				console.log("Code:", code, "State:", state);

				// Navigate to the appropriate screen in your app
				// e.g., React Router or any navigation logic
			}


			// // Example url: https://dev.buildwitt.plume.co.uk/tabs/tab2
			// // slug = /tabs/tab2
			// const slug = event.url.split(".com").pop();
			// if (slug) {
			// 	navigate(slug);
			// }
			// // If no match, do nothing - let regular routing
			// // logic take over
		};

		App.addListener("appUrlOpen", handleAppUrlOpen);

		// Cleanup listener on component unmount
		return () => {
			App.removeAllListeners();
		};
	}, [navigate]);

	return null;
};

export default AppUrlListener;
