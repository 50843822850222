import {FC, useContext, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Switch, Typography} from "@mui/material";
import {AdminHeader} from "../../../AdminHeader";
import {useNavigate} from "react-router-dom";
import {useApi} from "@plumeuk/shapeshift-identity";
import {IAdminUsersCoursesResponseEntry, ILocalizationEntity} from "@plumeuk/shapeshift-types";
import {AdminTable, AdminTableProps, ConfirmDeleteEntityDialog} from "@plumeuk/shapeshift-common/admin";
import {GRID_CHECKBOX_SELECTION_COL_DEF} from "@mui/x-data-grid-pro";
import {PageBaseContext} from "../../../../../contexts/pageBaseContext";
import {CourseBlacklistToggle} from "./CourseBlacklistToggle";

const useStyles = makeStyles()((_theme) => ({
	adminCoursesList: {
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		}
	}
}));



export const AdminCoursesList: FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	const [locales] = useApi<ILocalizationEntity[]>("/api/settings/i18n");
	const [{ownedCohort}] = useContext(PageBaseContext)

	const headCells = (locales?: ILocalizationEntity[], ownedCohortTitle?: string, cohortId?: number): AdminTableProps<"course", IAdminUsersCoursesResponseEntry>["columns"] => ([
		{
			...GRID_CHECKBOX_SELECTION_COL_DEF,
			type: "string",
			hideable: false
		},
		{
			field: "title",
			type: "string",
			flex: 1,
			headerName: "Course",
			minWidth: 240
		},
		{
			field: "tags",
			type: "string",
			filterable: false,
			flex: 1,
			headerName: "Tags",
			valueGetter: ({value}: {value: IAdminUsersCoursesResponseEntry["tags"]}) => value?.join(", ")
		},
		{
			field: "moduleCount",
			type: "string",
			filterable: false,
			flex: 1,
			headerName: "Lessons",
			maxWidth: 110
		},
		{
			field: "enrollmentCount",
			type: "string",
			filterable: false,
			flex: 1,
			headerName: "Enrolled Employees"
		},
		{
			field: "completionCount",
			type: "string",
			filterable: false,
			flex: 1,
			headerName: "Course Completions"
		},
		{
			field: "contentType",
			type: "string",
			flex: 1,
			headerName: "Content Type"
		},
		{
			field: "updatedAt",
			type: "date",
			flex: 1,
			headerName: "Last Modified",
			valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
		},
		{
			field: "publishedAt",
			type: "date",
			flex: 1,
			headerName: "Published",
			valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
		},
		{
			field: "languages",
			type: "string",
			flex: 1,
			filterable: false,
			headerName: "Supported Languages",
			valueGetter: ({value}: {value: IAdminUsersCoursesResponseEntry["languages"]}) => value?.map(e => locales?.find(x => x.code === e)?.name)?.join(", ")
		},
		{
			field: "authorCohort",
			type: "string",
			flex: 1,
			filterable: false,
			headerName: "Shared By",
			valueGetter: ({value}: {value: IAdminUsersCoursesResponseEntry["authorCohort"]}) => (value?.title ?? "") === ownedCohortTitle ? "" : value?.title
		},
		{
			field: "isBlacklisted",
			type: "boolean",
			flex: 1,
			sortable: false,
			headerName: "Hide From Team",
			renderCell: e => <CourseBlacklistToggle courseId={e.id as number} initialState={e.value} />
		}
	]);

	const cohort = typeof ownedCohort === "object" ? ownedCohort : undefined;

	return (
		<Box className={classes.adminCoursesList}>
			<AdminHeader
				title="Courses"
				subtitle="Manage courses, enroll them into courses and check their capacity"
				actionButtons={[{label: "Create New", onClick: () => navigate("create")}]}
			/>
			<AdminTable<"course", IAdminUsersCoursesResponseEntry>
				url="/api/strapi-plugin-shapeshift-lms/cohort/course"
				columns={headCells(locales.data, cohort?.title, cohort?.id)}
				searchDTOverride={{extendedAccess: true} as any}
				onEditAction={e => navigate(e.id.toString())}
				enableDelete={e => !!(e.authorCohort && ownedCohort && e.authorCohort.id === ownedCohort.id)}
				type="course"
				componentOverrides={{
					deleteDialog: deleteProps => <ConfirmDeleteEntityDialog
						{...deleteProps}
						componentOverrides={{
							content: (deleteProps?.entity?.enrollmentCount && deleteProps.entity?.enrollmentCount >= 1) ? <Typography variant="body1">You will be making an assigned course unavailable.</Typography> : undefined
						}}
					/>
				}}
				initialState={{
					columns: {
						columnVisibilityModel: {
							tags: false,
							updatedAt: false
						}}}}
			/>
		</Box>
	);
}