import {BreakpointsOptions, createTheme, Shadows, Theme} from "@mui/material";
import {CustomMuiTheme} from "@plumeuk/shapeshift-common/theming";
import {palette, toolbarHeight} from "../constants";
import {TypographyStyleOptions} from "@mui/material/styles/createTypography";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";

declare module "@mui/material/styles" {
	interface Theme extends CustomMuiTheme {}
	// allow configuration using `createTheme`
	interface ThemeOptions extends CustomMuiTheme{}
}

const breakpoints: BreakpointsOptions = {
	//Mobile  |		 x < sm
	//Tablet  |	sm < x < md
	//Desktop |		 x > md
	values: {
		// extra-small
		xs: 500,
		// small
		sm: 700,
		// medium
		md: 1000,
		// large
		lg: 1200,
		// extra-large
		xl: 1536
	}
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getFontStyles = (font: TypographyStyleOptions, removeProps: string[] = []) => {
	const styles: { [key: string]: any } = {
		fontWeight: !removeProps.includes("fontWeight") ? font.fontWeight : undefined,
		fontSize: !removeProps.includes("fontSize") ? font.fontSize : undefined,
		lineHeight: !removeProps.includes("lineHeight") ? font.lineHeight : undefined,
		color: !removeProps.includes("color") ? font.color : undefined,
		fontFamily: !removeProps.includes("fontFamily") ? font.fontFamily : undefined,
		textTransform: !removeProps.includes("textTransform") ? font.textTransform : undefined
	};

	if (breakpoints?.values?.sm && font[`@media (max-width: ${breakpoints.values.sm}px)`]) {
		styles[`@media (max-width: ${breakpoints.values.sm}px)`] = font[`@media (max-width: ${breakpoints.values.sm}px)`];
	}

	return styles;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getAllFontStyles = (theme: Theme, removeProps: string[] = []) => ({
	"& > p, li": getFontStyles(theme.typography.body1, removeProps),
	"& > h1": getFontStyles(theme.typography.h1, removeProps),
	"& > h2": getFontStyles(theme.typography.h2, removeProps),
	"& > h3": getFontStyles(theme.typography.h3, removeProps), //> training feed card title
	"& > h4": getFontStyles(theme.typography.h4, removeProps),
	"& > h5": getFontStyles(theme.typography.h5, removeProps),
	"& > h6": getFontStyles(theme.typography.h6, removeProps)
})

export const adminTheme = createTheme({
	toolbarHeight,
	breakpoints,
	shadows: Array(25).fill("none") as Shadows,
	palette: {
		grey: {
			50: "rgba(255, 255, 255, .05)",
			100: "rgba(255, 255, 255, .1)",
			200: palette.grey15,
			400: "rgba(255, 255, 255, .4)",
			600: "rgba(255, 255, 255, .6)",
			700: "rgba(255, 255, 255, .7)",
			800: "rgba(255, 255, 255, .8)",
			900: palette.grey90
		},
		background: {
			default: palette.grey90,
			paper: palette.greyDark
		},
		primary: {
			main: palette.orange,
			light: "#39280e"
		},
		secondary: {
			main: palette.orange
		},
		text: {
			primary: palette.white,
			secondary: palette.grey15,
			disabled: palette.midGrey
		},
		success: {
			main: palette.successLight
		},
		error: {
			main: palette.errorLight
		},
		warning: {
			main: palette.warning
		},
	},
	typography: {
		fontFamily: "Titillium Web, sans-serif",
		body1: {
			color: palette.white,
			fontSize: "16px",
			fontWeight: 400
		},
		h1: {
			color: palette.white,
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "50px",
			lineHeight: "120%"
		},
		h2: {
			color: palette.white,
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "46px",
			lineHeight: "120%"
		},
		h3: {
			color: palette.white,
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "26px",
			lineHeight: "30px"
		},
		h4: {
			color: palette.white,
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "21px",
			lineHeight: "25.2px"
		},
		h5: {
			color: palette.white,
			fontStyle: "normal",
			fontWeight: 400,
			fontSize: "21px",
			lineHeight: "27px"
		},
		body2: { //> training feed description
			color: palette.white,
			fontWeight: 400,
			fontSize: "15px"
		},
		h6: {
			color: palette.white,
			fontWeight: 400,
			fontSize: "20px"
		},
		button: { //> training feed watch lesson
			fontWeight: 500,
			color: palette.white,
			fontFamily: "Titillium Web, sans-serif",
			fontSize: "16px",
			textTransform: "none"
		}
	},
	navBar: {
		statusprofile: {
			backgroundColor: palette.background
		}
	},
	components: {
		MuiToolbar: {
			styleOverrides: {
			}
		},
		MuiTablePagination: {
			styleOverrides: {
				root: {
					height: "53px",
					"& p, & label": {
						margin: "0",
						padding: "0"
					}
				},
				toolbar: {
					height: "53px"
				}
			}
		},
		MuiDataGrid: {
			styleOverrides: {
				root: {
					border: "1px solid rgb(255, 255, 255, 0.12)",
					borderRadius: "8px",
					background: palette.greyDark,
					padding: "15px 15px 0",
					"& [class*=columnHeader]": {
						minHeight: "52px !important",
						height: "52px !important",
						maxHeight: "52px !important",
						lineHeight: "52px"
					},
					"& [class*=cellContent]": {
						fontWeight: 400
					},
					"& [class*=MuiIconButton]": {
						padding: 0,
						marginLeft: "6px",
						fontSize: "1.125rem"
					},
					"& [class*=menuIcon]": {
						"& svg": {
							fontSize: "18px",
							"& path": {
								fill: palette.grey60
							}
						}
					},
					"& [class*=MuiCheckbox]": {
						padding: "0px 7px",
						"& svg": {
							color: palette.grey70,
							"& path": {
								fill: palette.grey70
							}
						},
						"&[class*=Mui-checked]": {
							"& svg": {
								"& path": {
									fill: palette.orange
								}
							}
						}
					}
				},
				filterForm: {
					padding: "24px 16px"
				},
				virtualScroller: {
					minHeight: "100%",
					height: "100%"
				},
				panelHeader: {
					padding: "8px 16px"
				},
				panelFooter: {
					padding: "0px 16px 16px",
					gap: "8px",
					justifyContent: "end",
					"& [class*=startIcon]": {
						marginRight: 0
					}
				},
				row: {
					cursor: "pointer",
					"&[class*=selected]": {
						background: "rgba(255, 158, 0, 0.08)"
					}
				},
				columnHeaderCheckbox: {
					minWidth: "38px !important",
					width: "38px !important"
				},
				cellCheckbox: {
					minWidth: "38px !important",
					width: "38px !important"
				},
				footerContainer: {
					minHeight: "62px",
					fontWeight: "600",
					borderTop: "1px solid rgba(255, 255, 255, 0.12)"
				},
				columnHeaderTitleContainerContent: {
					minWidth: "100%",
					borderTop: "1px solid rgba(255, 255, 255, 0.12)"
				},
				columnHeaders: {
					borderBottom: "0px solid rgba(255, 255, 255, 0.12)"
				},
				columnHeader: {
					height: "52px",
					"& [class*=columnSeparator]": {
						minWidth: "10px",
						transform: "translateX(-14px)",
						svg:{
							display: "none"
						}
					}
				},
				columnHeaderTitle: {
					fontSize: "0.875rem",
					fontWeight: "600"
				},
				paper: {
					borderRadius: "6px"
				},
				cell: {
					fontSize: "0.875rem",
					outline: "none",
					borderTop: "1px solid rgba(255, 255, 255, 0.12)",
					borderBottom: "0",
					fontWeight: "600",
					"&:focus, &:active, &:focus-within": {
						outline: 0
					}
				},
				toolbarContainer: {
					paddingTop: "0",
					height: "auto",
					paddingLeft: "8px",
					"& .MuiButton-text": {
						color: palette.orange,
						border: "0px solid",
						borderRadius: "0",
						gap: 0,
						fontWeight: "600",
						fontSize: "0.8125rem",
						textTransform: "uppercase",
						padding: "12px 5px 12px",
						letterSpacing: "0.025rem",
						"&:hover": {
							background: "transparent",
							opacity: "0.8"
						},
						"& span": {
							marginRight: 0
						},
						"& svg": {
							marginRight: "8px",
							"& path": {
								fill: palette.orange
							}
						},
						"&:disabled": {
							color: palette.orange,
							opacity: 0.5,
							"& svg": {
								"& path": {
									fill: palette.orange
								}
							},
							marginRight: "8px",
							"& path": {
								fill: palette.orange
							}
						}
					}
				}
			}
		},
		MuiInput: {
			styleOverrides: {
				root: {
					padding: "4px 4px 4px",
					borderColor: palette.white,
					"&:before": {
						borderBottom: "1px solid",
						borderColor: palette.grey40,
						margin: "0 3px"
					}
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					border: "1px solid",
					borderColor: palette.grey10
				}
			}
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					borderRadius: "0px",
					height: "4px",
					backgroundColor: palette.orange75
				},
				bar: {
					backgroundColor: palette.orange,
					borderRadius: "10px"
				}
			}
		},
		MuiMenu: {
			styleOverrides: {
				root: {
					marginTop: "0px !important"
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				outlined: {
					fieldset: {
						borderColor: palette.grey15 + "!important"
					}
				},
				standard: {
					fieldset: {
						borderColor: palette.grey15 + "!important"
					}
				},
				select: {
					fieldset: {
						borderColor: palette.grey15 + "!important"
					}
				},
				icon: {
					color: palette.white
				}
			}
		},
		MuiBreadcrumbs: {
			styleOverrides: {
				root: {
					color: palette.grey70,
					"& p": {
						color: palette.grey70
					}
				}
			}
		},
		MuiBadge: {
			styleOverrides: { // To do: move badge output to its own component
				root: {
					position: "relative",
					zIndex: 2,
					fontSize: "14px"
				},
				colorInfo: {
					transform: "none !important",
					padding: "1px 8px 2px",
					left: 0,
					top: 0,
					position: "absolute",
					width: "100%",
					height: "100%",
					borderRadius: "100px",
					zIndex: "-1",
					background: palette.grey10,
					color: palette.white
				},
				colorWarning: {
					transform: "none !important",
					padding: "1px 8px 2px",
					left: 0,
					top: 0,
					position: "absolute",
					width: "100%",
					height: "100%",
					borderRadius: "100px",
					zIndex: "-1",
					background: palette.orange,
					color: palette.white
				},
				colorSuccess: {
					transform: "none !important",
					padding: "1px 8px 2px",
					left: 0,
					top: 0,
					position: "absolute",
					width: "100%",
					height: "100%",
					borderRadius: "100px",
					zIndex: "-1",
					background: palette.success,
					color: palette.white
				},
				colorError: {
					transform: "none !important",
					padding: "1px 8px 2px",
					left: 0,
					top: 0,
					position: "absolute",
					width: "100%",
					height: "100%",
					borderRadius: "100px",
					zIndex: "-1",
					background: palette.error,
					color: palette.white
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				root: {
					minHeight: toolbarHeight,
					display: "flex",
					flexDirection: "row",
					gap: "10px",
					fontSize: "16px",
					fontWeight: "400",
					letterSpacing: "0.15px",
					"&.Mui-selected": {
						color: palette.orange,
						background: "transparent",
						"& svg path": {
							fill: palette.orange
						}
					},
					color: palette.midGrey
				}
			}
		},
		MuiSlider: {
			styleOverrides: {
				root: {

				},
				valueLabel: {
					background: palette.orange,
					color: palette.black,
					fontSize: "14px",
					fontWeight: "600"
				}
			}
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					borderBottom: "1px solid " + palette.grey10,
					lineHeight: 0,
					"[class*='flexContainer']": {
						gap: "0px"
					}
				},
				indicator: {
					height: "2px",
					backgroundColor: palette.orange
				}
			}
		},
		MuiIcon: {
			styleOverrides: {
				fontSizeSmall: "12pt",
				fontSizeLarge: "20px"
			}
		},
		MuiSwitch: {
			styleOverrides: {
				switchBase: {
					color: palette.grey80,
					".Mui-checked.Mui-checked + [class*='thumb']": {
						color: palette.orange
					}
				},
				track: {
					backgroundColor: palette.grey40
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					backgroundColor: "transparent",
					border: "1px solid " + palette.orange,
					color: "white",
					height: "36px",
					padding: "0px 16px",
					fontSize: "14px",
					textTransform: "uppercase",
					borderRadius: "6px",
					display: "flex",
					gap: "8px",
					fontWeight: 600,
					transition: "opacity .3s ease-in-out",
					"&:hover": {
						backgroundColor: palette.orange,
						opacity: 0.8
					},
					":disabled": {
						opacity: 0.6,
						color: "white"
					},
					"& a": {
						color: palette.white,
						textDecoration: "none"
					}
				},
				sizeLarge: {
					height: "48px",
					padding: "16px 16px"
				},
				contained: {
					height: "36px",
					padding: "0px 16px",
					fontSize: "14px",
					textTransform: "uppercase",
					borderRadius: "6px",
					display: "flex",
					gap: "8px",
					fontWeight: 600,
					background: palette.orange,
					color: palette.black,
					lineHeight: 1,
					"& a": {
						color: palette.black,
						textDecoration: "none"
					},
					"& svg": {
						fontSize: "14px"
					}
				}
			}
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					color: palette.white,
					"&:disabled": {
						color: palette.white
					}
				}
			}
		},
		MuiDrawer: {
			defaultProps: {
			},
			styleOverrides: {
				root: {
					background: "none"
				},
				paper: {
					backgroundColor: palette.drawer
				},
				docked: {
					backgroundColor: palette.drawer
				}
			}
		},
		MuiNativeSelect: {
			styleOverrides: {
				icon: {
					marginTop: "5px",
					color: palette.white,
					fontSize: "16px"
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					top: "-15px"
				},
				shrink: {
					top: "0px"
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					"& fieldset": {
						// borderColor: palette.grey15 + "!important"
					}
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					"& fieldset": {
						borderColor: palette.grey15
					}
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					borderRadius: 100,
					backgroundColor: palette.orange,
					color: "#fff"
				}
			}
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					margin: "10px 0",
					backgroundColor: palette.grey15
				}
			}
		}
		// MuiInput: {
		// 	styleOverrides: {
		// 		root: {
		// 			border: "1px solid red",
		// 		}
		// 	}
		// }
	}
});
