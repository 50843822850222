import {FC} from "react";
import {ILessonEntity, ILessonEntityPayload, IVideoType} from "@plumeuk/shapeshift-types";
import Editor from "../../../../components/admin/WYSIWYG/Editor";
import AdminVideoUpload from "./AdminBitmovinUpload/adminVideoUpload";
import {AdminEntityPageContainer, AdminFieldContainer, AdminInputText,AdminEntityPageContainerProps, ConfirmDeleteEntityDialog, AdminFormContainer, AdminInputSelect} from "@plumeuk/shapeshift-common/admin";
import {cohortLessonValidationDoc} from "../../../../validationDocs/user/cohortLessonValidationDoc";
import {AdminLessonInlineQuiz} from "./AdminLessonInlineQuiz";
import {useNavigate} from "react-router-dom";
import {Typography} from "@mui/material";

interface ILessonAdminPageEntry extends ILessonEntity { //> to do move to types package
	enrollmentCount?: number
}

interface IProps extends Partial<AdminEntityPageContainerProps<ILessonEntity, "lesson">> {
	lessonId?: number
}

const template: ILessonEntityPayload = {
	title: "", slug: "", content: ""
}
const videoTypes: string[] = ["Url", "Upload"]

export const AdminLessonPageContent: FC<IProps> = ({lessonId, ...props}) => {
	const navigate = useNavigate();
	const ILessonEntityToILessonEntityPayload = (e: ILessonEntity): ILessonEntityPayload => {
		const {title, slug, content, id, createdAt, updatedAt, subtitle, quiz, videoType, videoUrl} = e
		const videoData = (e as any).videoData;
		const payload = {
			title, slug, content, id, createdAt, updatedAt, subtitle, videoData, quiz: quiz?.id, videoType, videoUrl
		}
		return payload;
	}

	return (
		<AdminEntityPageContainer<ILessonAdminPageEntry, "lesson">
			entityId={lessonId}
			entityToPayload={ILessonEntityToILessonEntityPayload}
			template={template}
			validationDoc={cohortLessonValidationDoc}
			type="lesson"
			componentOverrides={{
				adminFormContainer: ({entity, ...containerProps}) =>
					<AdminFormContainer<ILessonAdminPageEntry, "lesson"> {...containerProps} entity={entity} componentOverrides={{deleteDialog: deleteProps => <ConfirmDeleteEntityDialog {...deleteProps} componentOverrides={{content:  entity?.enrollmentCount && entity?.enrollmentCount >= 1 ? <Typography variant="body1">You will be making an assigned lesson unavailable.</Typography> : <Typography variant="body1">Do you want to delete?</Typography>}}/>}}/>
			}}
			{...props}
		>
			{({formData, setFormData, entity, validation}) => <>
				{/* <AdminInputText onChange={e => setFormData(prev => ({...prev, slug: e.target.value}))} value={formData.slug} label={"Slug"}/> */}
				<AdminInputText error={validation?.[0]?.title} required onChange={e => setFormData(prev => ({...prev, title: e.target.value}))} value={formData.title} label={"Lesson title"}/>
				<AdminInputText error={validation?.[0]?.subtitle} onChange={e => setFormData(prev => ({...prev, subtitle: e.target.value}))} value={formData.subtitle} label={"Lesson subtitle"}/>
				<AdminFieldContainer error={validation?.[0]?.content} label="Content"><Editor onChange={e => setFormData(prev => ({...prev, content: e.target.value}))} value={formData.content ?? ""} /></AdminFieldContainer>
				{<AdminInputSelect<IVideoType> error={(validation?.[0])?.videoType} required label={"Video Type"} onChange={e => setFormData(prev => ({...prev, videoType: e.target.value as IVideoType, videoUrl: ""}))} options={[{label: "Url", value: "url"}, {label: "Upload", value: "bitmovin"}]} value={formData.videoType ?? "bitmovin"}/>}
				{formData.videoType === "bitmovin" && <AdminFieldContainer label="Video">
					<AdminVideoUpload
						videoThumbnailUrl={entity?.videoThumbnailUrl}
						videoUrl={entity?.videoUrl}
						value={(formData as any)?.videoData}
						entity={entity} initialValue={(entity as any)?.videoData}
						onChange={e => setFormData(prev => ({...prev, "videoData": e}))}/>
				</AdminFieldContainer>}
				{formData.videoType === "url" && <AdminInputText error={validation?.[0]?.videoUrl} onChange={e => setFormData(prev => ({...prev, videoUrl: e.target.value}))} value={formData.videoUrl} label={"Video Url"}/>}
				<AdminFieldContainer label="Inline Quiz">
					<AdminLessonInlineQuiz
						onDeleteAction={() => setFormData(prev => ({...prev, quiz: null}))}
						onSelectAction={() => navigate("/admin/quizzes/" + formData.quiz)}
						quizId={formData.quiz}
						onChange={(id) => {setFormData(prev => ({...prev, quiz: id}))}}
					/>
				</AdminFieldContainer>
			</>}
		</AdminEntityPageContainer>
	);
}