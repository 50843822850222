import {VideoPlayer} from "@plumeuk/shapeshift-common/videoPlayer";
import {useContext, useEffect, useRef, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {ILesson} from "@plumeuk/shapeshift-types";
import {ReactPlayerProps} from "react-player";
import {forwardRef} from "react";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";
import {PlayerAPI} from "bitmovin-player/modules/bitmovinplayer-core";
import {INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";
import {VideoProviders} from "@plumeuk/shapeshift-common/videoPlayer/types";
import {OnProgressProps} from "react-player/base";

const useStyles = makeStyles()((theme) => ({
	videoPlayerBackdrop: {
		position: "relative",
		width: "100%",
		paddingBottom: "56.25%",
		height: 0,
		overflow: "hidden",
		background: "black",
		boxSizing: "border-box",
		left: 0,
		top: 0,
		right: "0px",
		[theme.breakpoints.down("sm")]: {
			height: "250px"
		},
		"#bitmovin-player": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			objectFit: "cover"
		}
	},
	videoPlayer: {
		zIndex: 0,
		background: "black",
		position: "absolute",
		opacity: 0,
		left: 0,
		transition: "opacity .5s ease-in",
		right: "0px",
		height: "100% !important"

	},
	videoPlayerSpacer: {
		height: "550px",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			height: "250px"
		}
	}
}));

interface IPropsCustom {
	lesson?: ILesson,
	bitmovinPlayer?:PlayerAPI | null,
	componentOverrides?: {
		header?: JSX.Element
	}
}

export type IProps = Omit<ReactPlayerProps, "url"> & IPropsCustom;

export const LessonVideoPlayer = forwardRef<VideoPlayerRef, IProps>(function LessonVideoPlayer({componentOverrides, lesson, currentTime, onReady, bitmovinPlayer, ...props}, externalRef) {
	const {classes} = useStyles();
	const [videoReady, setVideoReady] = useState(false);
	const {notify} = useContext(NotificationContext);
	const internalRef = useRef<VideoPlayerRef>({});

	const handleReady = ():void => {
		onReady?.()
		setVideoReady(true)
	}

	useEffect(() => {
		setVideoReady(false)
	}, [lesson?.videoUrl])

	//BITMOVIN PLAYER RESTORE CONTROL
	useEffect(() => {
		if (videoReady && bitmovinPlayer && currentTime) {
			bitmovinPlayer.seek(currentTime);
		}
	}, [currentTime, videoReady, bitmovinPlayer])

	//REACT PLAYER RESTORE CONTROL
	useEffect(() => {
		if (!internalRef.current.react) return;

		const playerInstance = internalRef.current.react.react.player;
		if (videoReady && playerInstance && currentTime) {
			playerInstance.seekTo(currentTime, "seconds");
			playerInstance.player.play()
		}
	}, [currentTime, videoReady])

	const generateVideoRef = function<T>(current: T, type: VideoProviders): void | {
		[x: string]: NonNullable<T>;
	} | null {
		if(type !== "loom")
			internalRef.current[type] = current;

		return current && externalRef && (typeof externalRef === "function" ? externalRef(current) : externalRef.current = current);
	}

	//BITMOVIN PLAYER PLAYBACK CONTROL
	useEffect(() => {
		if(!bitmovinPlayer)
			return;

		const originalSeek = bitmovinPlayer?.seek;

		const newSeek = (time: number, issuer?: string): boolean => {
			if(!issuer || !lesson || !videoReady || !bitmovinPlayer || lesson.complete || currentTime === time){
				return (originalSeek.apply(bitmovinPlayer, [time, issuer]));
			}

			const diff = time - bitmovinPlayer.getCurrentTime();
			if (diff < 0) {
				return (originalSeek.apply(bitmovinPlayer, [time, issuer]));
			}

			notify("", "Skipping is not permitted for incomplete lessons", INotificationSeverity.warning, 5000, false)
			return false;

		}
		bitmovinPlayer.seek = newSeek;


	}, [bitmovinPlayer, lesson?.complete, videoReady]);

	// //REACT PLAYER PLAYBACK CONTROL
	const prevPlayedSeconds = useRef<number>(0);
	useEffect(() => {prevPlayedSeconds.current = 0}, [lesson?.videoUrl])
	const handleSeek = ({playedSeconds}: OnProgressProps): void => {
		if (!internalRef.current.react) return;

		const playerInstance = internalRef.current.react.react.player;

		if (!lesson || !videoReady ) {
			return;
		}

		const diff = playedSeconds - (prevPlayedSeconds.current ?? 0);
		if (prevPlayedSeconds.current > 0 && diff > 2) {
			notify("", "Skipping is not permitted for incomplete lessons", INotificationSeverity.warning, 5000, false)
			playerInstance.seekTo((prevPlayedSeconds.current), "seconds");
			playerInstance.player.play()
		}
		else
			prevPlayedSeconds.current = playedSeconds;

		if(((playerInstance.getDuration() - playedSeconds)) < 10){
			props.onVideoFinished?.();
		}
		props.onProgress?.({playedSeconds})
	};

	if(!lesson?.videoUrl) return <></>

	return (
		<>
			<div className={classes.videoPlayerBackdrop}>
				{componentOverrides?.header}
				<VideoPlayer
					{...props}
					moduleVideoType={lesson.videoType}
					ref={lesson.videoType === "bitmovin" ? externalRef : (current => generateVideoRef<typeof current>(current, "react"))}
					onReady={handleReady}
					className={classes.videoPlayer}
					style={{opacity: videoReady ? 1 : 0}}
					url={lesson.videoUrl}
					controls={true}
					providerDetails={bitmovinPlayer ? {bitmovin:{player: bitmovinPlayer}} : undefined}
					onProgress={lesson.videoType === "url" ? handleSeek : undefined}
				/>
			</div>
		</>
	);
});