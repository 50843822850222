import {FC, useCallback, useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Box, Button, Tab, Tabs, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {ModuleCompleteButton, ModuleNotAvailable, ModuleTab} from "@plumeuk/shapeshift-common/module";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {Files} from "@plumeuk/shapeshift-common/files";
import {Links} from "@plumeuk/shapeshift-common/links";
import {INotificationSeverity, APIState, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {CenteredContainer} from "../../../components/pageBase/centeredContainer";
import {LessonVideoPlayer} from "./AnnouncementVideoPlayer";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";
import {IModuleEnrollment} from "@plumeuk/shapeshift-common/types";
import {getAllFontStyles} from "../../../templates/defaultTheme";
import {TrainingfeedContext} from "../../../contexts/trainingFeedContext";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";
import {IconDocSearch} from "../../../icons/IconDocSearch";
import {palette, toolbarHeight} from "../../../constants";
import {Player, PlayerAPI, PlayerConfig, PlayerType, StreamType} from "bitmovin-player/modules/bitmovinplayer-core";
import EngineBitmovinModule from "bitmovin-player/modules/bitmovinplayer-engine-bitmovin";
import MseRendererModule from "bitmovin-player/modules/bitmovinplayer-mserenderer";
import HlsModule from "bitmovin-player/modules/bitmovinplayer-hls";
import DashModule from "bitmovin-player/modules/bitmovinplayer-dash";
import AbrModule from "bitmovin-player/modules/bitmovinplayer-abr";
import XmlModule from "bitmovin-player/modules/bitmovinplayer-xml";
import ContainerTSModule from "bitmovin-player/modules/bitmovinplayer-container-ts";
import ContainerMp4Module from "bitmovin-player/modules/bitmovinplayer-container-mp4";
import SubtitlesModule from "bitmovin-player/modules/bitmovinplayer-subtitles";
import SubtitlesCEA608Module from "bitmovin-player/modules/bitmovinplayer-subtitles-cea608";
import PolyfillModule from "bitmovin-player/modules/bitmovinplayer-polyfill";
import StyleModule from "bitmovin-player/modules/bitmovinplayer-style";
import "bitmovin-player/bitmovinplayer-ui.css";
import {UIFactory} from "bitmovin-player-ui";
import {SocketContext} from "../../../contexts/socketContext";
import {LocaleContext} from "../../../contexts/localeContext";
import AnnouncementDataProvider from "../../../providers/announcementDataProvider";
import {IAnnouncement} from "../../../types/announcement";
import {AnnouncementContext} from "../../../contexts/announcementContext";
import {useSetAnnouncementCompletion} from "../../../hooks/useSetAnnouncementCompletion";

const useStyles = makeStyles()((theme) => ({
	announcementPage: {
	},
	miniQuizzer: {
		marginBottom: "80px",
		minHeight: "550px"
	},
	resumeVideoContainer: {
		position: "fixed",
		top: toolbarHeight,
		margin: "20px 30px",
		right: "0px",
		zIndex: 30
	},
	title:{
		color: theme.palette.common.white,
		marginTop: "70px",
		marginBottom: "50px",
		"h2": {
			fontSize: "42px"
		},
		"h5": {
			fontSize: "20px",
			lineHeight: "1.5rem"
		},
		[theme.breakpoints.down("sm")]: {
			marginBottom: "30px"
		}
	},
	centeredContainer: {
		maxWidth: "900px",
		padding: "0 20px",
		paddingBottom: "100px"
	},
	announcementCompleteBtn: {
		float: "right"
	},
	content: {
		...getAllFontStyles(theme),
		color: theme.palette.text.primary,
		marginBottom: "40px",
		"& p": {
			lineHeight: 1.5,
			fontSize: "17px"
		},
		"& a": {
			textDecoration: "underline",
			color: theme.palette.primary.main
		}
	},
	contentImage: {
		maxWidth: "100%"
	},
	fileTitle: {
		fontWeight: 700,
		color: theme.palette.common.white,
		margin: "20px 0"
	},
	files: {
		marginBottom: "40px",
		"& > div": {
			background: palette.grey15}
	},
	tabs: {
		width: "100%",
		margin: 0,
		marginBottom: "50px",
		"[class*='indicator']": {
			display: "none"
		},
		[theme.breakpoints.up("sm")]: {
			"[class*='MuiTabs-scroller']": {
				marginLeft: "25px"
			}
		},
		[theme.breakpoints.down("sm")]: {
			"[class*='MuiTabs-scroller']": {
				marginLeft: "15px"
			},
			gap: 0
		}
	},
	activeTab: {
		color: theme.palette.common.white + "!important",
		background: "transparent"
	},
	links: {
		marginBottom: "40px"
	},
	tab: {
		display: "flex",
		fontSize: "14px",
		gap: "5px",
		justifyContent: "space-around",
		color: "#A8A8A8",
		padding: "20px 12px",
		letterSpacing: "0.5px",
		"& svg": {
			marginTop: "4px"
		},
		"& svg path": {
			fill: palette.midGrey
		},
		[theme.breakpoints.down("sm")]: {
			minHeight: "44px",
			fontSize: "13px",
			padding: "0 5px"
		}
	},
	goToNextLesson: {
		marginTop: "25px"
	},
	notAvailableContainer: {
		width: "100%",
		textAlign: "center"
	},
	comments: {
		"& > div": {
			background: palette.drawer
		},
		"[class*='MuiAvatar']": {
			background: palette.grey10
		},
		"& *": {
			color: theme.palette.common.white + "!important"
		}
	},
	notes: {
		"& *": {
			color: theme.palette.common.white + "!important"
		},
		"& [class*='inputContainer'] > [class*='MuiTextField']": {
			background: palette.drawer
		},
		"[class*='filtersContainer'] > div" : {
			background: palette.grey10
		}
	},
	notesSection: {
		"& [class*='timeStampInputAdornment']": {
			alignSelf: "start",
			marginTop: "5px"
		}
	}
}));


export const AnnouncementPage: FC = () => {
	const {cx, classes} = useStyles();
	const {slug: announcementSlug} = useParams();
	const {announcementState, announcementDispatch} = useContext(AnnouncementContext);
	const {notify} = useContext(NotificationContext);
	const navigate = useNavigate();
	const [firstAvailableModule, setFirstAvailableAnnouncement] = useState<IAnnouncement | false>();
	const videoRef = useRef<VideoPlayerRef | null>(null);
	const [player, setPlayer] = useState<PlayerAPI | null>(null);
	(global as any).playerx = player;
	const [videoFinished, setVideoFinished] = useState(false);
	const [videoStarted, setVideoStarted] = useState(false);
	const socket = useContext(SocketContext)
	const [{locale}] = useContext(LocaleContext);
	const location = useLocation();
	const {setComplete, apiResult: completeResponse} = useSetAnnouncementCompletion();

	useEffect(() => {
		if(announcementState){
			const next = announcementState.find(e => e.slug !== announcementSlug && e.complete === false);
			setFirstAvailableAnnouncement(next)
		}
	}, [])

	useEffect(() => {
		videoRef.current = null;
	}, [announcementSlug])

	const handleVideoReady = useCallback((announcement: IAnnouncement) => {
		if(!process.env.REACT_APP_BITMOVIN_PLAYER_KEY)
			throw new Error("REACT_APP_BITMOVIN_PLAYER_KEY required")

		const playerConfig: PlayerConfig = {
			key: process.env.REACT_APP_BITMOVIN_PLAYER_KEY,
			analytics: {
				key: process.env.REACT_APP_BITMOVIN_ANALYTICS_KEY
			},
			playback: {
				preferredTech: [{player: PlayerType.Html5, streaming: StreamType.Hls}]
			}
		};

		if(!videoRef.current?.bitmovin?.container)
			return;

		const playerSource = {
			hls: announcement.videoUrl,
			poster: announcement.videoThumbnailUrl
		};

		Player.addModule(EngineBitmovinModule);
		Player.addModule(MseRendererModule);
		Player.addModule(HlsModule);
		Player.addModule(XmlModule);
		Player.addModule(DashModule);
		Player.addModule(AbrModule);
		Player.addModule(ContainerTSModule);
		Player.addModule(ContainerMp4Module);
		Player.addModule(SubtitlesModule);
		Player.addModule(SubtitlesCEA608Module);
		Player.addModule(PolyfillModule);
		Player.addModule(StyleModule);

		// Delay player setup for Safari
		setTimeout(() => {
			if(!videoRef.current?.bitmovin?.container)
				return;

			const playerInstance = new Player(videoRef.current.bitmovin?.container, playerConfig);
			UIFactory.buildDefaultUI(playerInstance);
			playerInstance.load(playerSource).then(() => {
				setPlayer(playerInstance);
				console.log("Successfully loaded source");
			}, (e) => {
				console.log("Error while loading source", e);
			});
		}, 100); // Delay added
	}, [])


	const handleCompletion = useCallback((e: APIState<IModuleEnrollment>): void => {
		if(e.isError) {
			notify("Please try again", "Something went wrong", INotificationSeverity.error, 5000);
		}
		else if(!e.isLoading && !e.isError && e.statusCode === 200 && e.data?.id){
			announcementDispatch({
				type: "setAnnouncementComplete", id: e.data?.id})

			if(e?.data?.complete){
				notify(null, "Announcement completed", INotificationSeverity.success, 5000);
				navigate("/feed/")
			}
		}
	}, [announcementDispatch, announcementSlug])

	useEffect(() => {
		handleCompletion(completeResponse)
	}, [completeResponse])

	const handleCompleteCheck = (): boolean => {
		if(!player)
			return true


		const valid = (player.getDuration() - player.getCurrentTime()) < 5;
		if(!valid)
			notify("Please finish the video before progressing", "Hold up!", INotificationSeverity.warning, 5000)
		return valid;
	}

	const prevProgress = useRef<number>()
	useEffect(() => {
		if(!player){
			setVideoFinished(true)
			return;
		}

		//run in loop to save video progress
		const interval = setInterval(() => {
			const started = (player?.getCurrentTime() ?? 0) > 0;
			const finished = (player.getDuration() - player.getCurrentTime()) < 5;
			setVideoStarted(started);
			setVideoFinished(finished);
			if(started && prevProgress.current !== player.getCurrentTime()){
				prevProgress.current = player.getCurrentTime();
				socket?.emit("announcementVideoProgress", {progress: player.getCurrentTime(), announcementSlug})
			}
		}, 2000);

		return () => clearInterval(interval);
	}, [player]);

	return (
		<Box className={classes.announcementPage}>
			<AnnouncementDataProvider locale={locale} announcementSlug={announcementSlug} populate={["files", "enrollment.videoTime"]}>
				{({announcement, apiResult}) => <>
					{apiResult.statusCode === 200 && <>
						<LessonVideoPlayer onReady={() => announcement ? handleVideoReady(announcement) : null} ref={videoRef} announcement={announcement} currentTime={location?.state?.timestamp ?? announcement?.videoTime} bitmovinPlayer={player} />
						<CenteredContainer className={classes.centeredContainer}>
							<>
								<PageTitle className={classes.title} title={announcement?.title} subtitle={announcement?.subtitle} />
								<WYSIWYG className={classes.content}>
									{announcement?.content ?? ""}
								</WYSIWYG>

								{announcement?.files && <>
									<Typography className={classes.fileTitle} variant="h5">Files</Typography>
									<Files className={classes.files} files={announcement.files}/>
								</>}

								{announcement?.links && announcement.links.length > 0 && <>
									<Typography className={classes.fileTitle} variant="h5">Links</Typography>
									<Links className={classes.links} links={announcement.links}/>
								</>}
								{announcement?.slug && !announcement?.complete &&
									<ModuleCompleteButton
										style={{opacity: videoFinished ? 1 : 0.5}}
										validate={handleCompleteCheck}
										courseSlug={""}
										moduleSlug={announcement.slug}
										actionOverride={() => setComplete(announcement.slug)}
										type="announcement"
										className={classes.announcementCompleteBtn}
										onApiUpdate={handleCompletion}
									/>
								}
								{announcement?.slug && announcement?.complete && firstAvailableModule &&
									<Button
										onClick={() => navigate("/training-feed")}
										className={classes.announcementCompleteBtn}
									>
										Next {firstAvailableModule ? firstAvailableModule?.type : ""}
									</Button>
								}
							</>
						</CenteredContainer>
					</>}
					{apiResult.statusCode === 403 && <Box className={classes.notAvailableContainer}>
						<ModuleNotAvailable>
							Announcement is not yet available
						</ModuleNotAvailable>
						{firstAvailableModule === false && <Button
							className={classes.goToNextLesson}
							onClick={() => navigate("/training-feed")}
						>
							Go back to My Learning
						</Button>}
					</Box>}
				</>}
			</AnnouncementDataProvider>
		</Box>
	);
}