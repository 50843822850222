import {Box, BoxProps, Typography} from "@mui/material";
import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {DailyStreak} from "../dailyStreak/dailyStreak";
import {palette} from "../../constants";
import {useSSTranslation} from "../../i18n/useSSTranslation";

type IProps = {enableDayStreak?: boolean} & BoxProps;

const useStyles = makeStyles()((theme) => ({
	pageTitle: {
		justifyContent: "space-between",
		display: "flex",
		flexDirection: "column",
		marginTop: "40px",
		borderBottom: "1px solid " + palette.grey10,
		padding: "20px 0",
		marginBottom: "30px",
		[theme.breakpoints.down("sm")]: {
			borderBottom: "none",
			margin: "10px 10px 0 10px",
			padding: "10px 0 0 0"
		},
		[theme.breakpoints.down("lg")]: {
			padding: "10px"
		}
	},
	title: {
		fontWeight: 700,
		fontSize: "62px",
		[theme.breakpoints.down("sm")]: {
			fontSize: "32px"
		}

	},
	subTitle: {
		fontSize: "20px",
		color: palette.grey80,
		marginBottom: "22px"
	}
}));

export const PageTitle: FC<IProps> = ({title, enableDayStreak, ...boxProps}) => {
	const {classes, cx} = useStyles();
	const {t} = useSSTranslation();

	return (
		<Box className={classes.pageTitle} {...boxProps}>
			<Box sx={{display: "flex", justifyContent: "space-between"}}>
				<Typography className={classes.title}>{title}</Typography>
				{enableDayStreak && <DailyStreak />}
			</Box>
			{enableDayStreak && <Typography className={classes.subTitle}>{t("page_streak", "Complete all tasks due today to increase your daily streak")}</Typography>}
		</Box>
	);
};
