import {FC, useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {PageBase} from "../components/pageBase/pageBase";
import {CourseSideMenuContent} from "./CoursePage/CourseSideMenuContent";
import {MobileTrainingFeedDrawer} from "../components/pageBase/mobileTrainingFeedDrawer";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {TrainingfeedContext} from "../contexts/trainingFeedContext";
import {AnnouncementContext} from "../contexts/announcementContext";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
		height: "100%",
		"& [class*='background']":  {
			backgroundColor: "white",
			[theme.breakpoints.down("sm")]: {
				backgroundColor: "#F2F1F4"
			}
		}
	}
}));


export const FeedLandingPage: FC = () => {
	const {classes} = useStyles();
	const {trainingfeedState} = useContext(TrainingfeedContext);
	const {announcementState} = useContext(AnnouncementContext);
	const navigate = useNavigate();

	//wait on overdue and today
	useEffect(() => {
		if(trainingfeedState === undefined || announcementState === undefined)
			return;

		if(announcementState.length > 0)
			navigate("/feed/announcement/" + announcementState[0].slug)

		if(!trainingfeedState?.["overdue"] || typeof trainingfeedState?.["overdue"]?.[0] === "string")
			return;

		if(trainingfeedState["overdue"].length > 0 && Array.isArray(trainingfeedState["overdue"])){
			const module = trainingfeedState["overdue"].find(e => e.complete === false);
			if(module){
				navigate("/feed/" + module.course.slug + "/" + module.type + "/" + module.slug)
				return;
			}
		}

		//check for next
		const days = Object.keys(trainingfeedState).filter(e => e !== "overdue").sort();

		for(const day of days){
			const modules = trainingfeedState[day];
			if(typeof modules === "string")
				return;
			if(modules.length > 0){
				const next = modules.find(e => e.complete === false)
				if(next){
					navigate("/feed/" + next.course.slug + "/" + next.type + "/" + next.slug)
					return
				}
			}
		}
	}, [trainingfeedState, announcementState])

	return (
		<PageBase sideBarContent={<CourseSideMenuContent />} contentWidth={"3000px"} disableFooter={true}>
			<Box className={classes.pageBase} >
			</Box>
			<MobileTrainingFeedDrawer />
		</PageBase>
	);
}