import {FC, useCallback, useContext, useEffect, useRef, useState} from "react";
import {useLocation, useMatch, useNavigate, useParams} from "react-router-dom";
import {Box, Button, Tab, Tabs, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {ModuleCompleteButton, ModuleTab} from "@plumeuk/shapeshift-common/module";
import {NotesSection} from "@plumeuk/shapeshift-common/v2";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {Files} from "@plumeuk/shapeshift-common/files";
import {CommentsSection} from "@plumeuk/shapeshift-common/comments";
import {Links} from "@plumeuk/shapeshift-common/links";
import {INotificationSeverity, APIState, NotificationContext} from "@plumeuk/shapeshift-identity";
import {CenteredContainer} from "../../../components/pageBase/centeredContainer";
import {CurriculumContext} from "../../../contexts/curriculumContext";
import {LessonVideoPlayer} from "./LessonVideoPlayer";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";
import {getModule} from "@plumeuk/shapeshift-common/common";
import {ICourseCurriculumModule, ILesson} from "@plumeuk/shapeshift-types";
import {IModuleEnrollment} from "@plumeuk/shapeshift-common/types";
import {getAllFontStyles} from "../../../templates/defaultTheme";
import {TrainingfeedContext} from "../../../contexts/trainingFeedContext";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";
import {IconDocSearch} from "../../../icons/IconDocSearch";
import {IconComments} from "../../../icons/IconComments";
import {palette, toolbarHeight} from "../../../constants";
import {Player, PlayerAPI, PlayerConfig, PlayerType, StreamType} from "bitmovin-player/modules/bitmovinplayer-core";
import EngineBitmovinModule from "bitmovin-player/modules/bitmovinplayer-engine-bitmovin";
import MseRendererModule from "bitmovin-player/modules/bitmovinplayer-mserenderer";
import HlsModule from "bitmovin-player/modules/bitmovinplayer-hls";
import DashModule from "bitmovin-player/modules/bitmovinplayer-dash";
import AbrModule from "bitmovin-player/modules/bitmovinplayer-abr";
import XmlModule from "bitmovin-player/modules/bitmovinplayer-xml";
import ContainerTSModule from "bitmovin-player/modules/bitmovinplayer-container-ts";
import ContainerMp4Module from "bitmovin-player/modules/bitmovinplayer-container-mp4";
import SubtitlesModule from "bitmovin-player/modules/bitmovinplayer-subtitles";
import SubtitlesCEA608Module from "bitmovin-player/modules/bitmovinplayer-subtitles-cea608";
import PolyfillModule from "bitmovin-player/modules/bitmovinplayer-polyfill";
import StyleModule from "bitmovin-player/modules/bitmovinplayer-style";
import "bitmovin-player/bitmovinplayer-ui.css";
import {UIFactory} from "bitmovin-player-ui";
import {SocketContext} from "../../../contexts/socketContext";
import {MiniQuizzer} from "./MiniQuizzer";
import {IconNotes} from "../../../icons/IconNotes";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import {PageBaseContext} from "../../../contexts/pageBaseContext";

const useStyles = makeStyles<{ scrollToContinueBannerEnabled: boolean }>()((theme, {scrollToContinueBannerEnabled}) => ({
	miniQuizzer: {
		"[class*='submitBtn']": {
			[theme.breakpoints.down("sm")]: {
				width: "100%"
			}
		}

	},
	resumeVideoContainer: {
		position: "fixed",
		top: toolbarHeight,
		margin: "20px 30px",
		right: "0px",
		zIndex: 30
	},
	title: {
		color: theme.palette.common.white,
		"h2": {
			fontSize: "42px"
		},
		"h5": {
			fontSize: "20px",
			lineHeight: "1.5rem"
		},
		[theme.breakpoints.down("sm")]: {
			marginBottom: "30px"
		}
	},
	centeredContainer: {
		maxWidth: "900px",
		padding: "0 20px",
		paddingBottom: "100px"
	},
	lessonCompleteBtn: {
		float: "right"
	},
	content: {
		...getAllFontStyles(theme),
		color: theme.palette.text.primary,
		marginBottom: "40px",
		"& p": {
			lineHeight: 1.5,
			fontSize: "17px"
		},
		"& a": {
			textDecoration: "underline",
			color: theme.palette.primary.main
		}
	},
	contentImage: {
		maxWidth: "100%"
	},
	fileTitle: {
		fontWeight: 700,
		color: theme.palette.common.white,
		margin: "20px 0"
	},
	files: {
		marginBottom: "40px",
		"& > div": {
			background: palette.grey15
		}
	},
	tabs: {
		width: "100%",
		margin: 0,
		marginBottom: "50px",
		"[class*='indicator']": {
			display: "none"
		},
		[theme.breakpoints.up("sm")]: {
			"[class*='MuiTabs-scroller']": {
				marginLeft: "25px"
			}
		},
		[theme.breakpoints.down("sm")]: {
			"[class*='MuiTabs-scroller']": {
				marginLeft: "15px"
			},
			gap: 0
		}
	},
	activeTab: {
		color: theme.palette.common.white + "!important",
		background: "transparent"
	},
	links: {
		marginBottom: "40px"
	},
	tab: {
		display: "flex",
		fontSize: "14px",
		gap: "5px",
		justifyContent: "space-around",
		color: "#A8A8A8",
		padding: "20px 12px",
		letterSpacing: "0.5px",
		"& svg": {
			marginTop: "4px"
		},
		"& svg path": {
			fill: palette.midGrey
		},
		[theme.breakpoints.down("sm")]: {
			minHeight: "44px",
			fontSize: "13px",
			padding: "0 5px"
		}
	},
	comments: {
		"& > div": {
			background: palette.drawer
		},
		"[class*='MuiAvatar']": {
			background: palette.grey10
		},
		"& *": {
			color: theme.palette.common.white + "!important"
		}
	},
	notes: {
		"& *": {
			color: theme.palette.common.white + "!important"
		},
		"& [class*='inputContainer'] > [class*='MuiTextField']": {
			background: palette.drawer
		},
		"[class*='filtersContainer'] > div": {
			background: palette.grey10
		}
	},
	notesSection: {
		"& [class*='timeStampInputAdornment']": {
			alignSelf: "start",
			marginTop: "5px"
		}
	},
	scrollToContinueBanner: {
		opacity: scrollToContinueBannerEnabled ? 1 : 0,
		transition: "opacity 0.2s ease-in-out",
		position: "absolute",
		color: theme.palette.primary.main,
		width: "100%",
		textAlign: "center",
		paddingTop: "35%",
		pointerEvents: "none",
		height: "100%",
		justifyContent: "center",
		margin: "auto",
		zIndex: 10,
		"& svg": {
			fontSize: 50,
			padding: 0,
			margin: 0,
			[theme.breakpoints.down("sm")]: {
				fontSize: 30
			}
		},
		"& > *": {
			pointerEvents: "auto",
			cursor: scrollToContinueBannerEnabled ? "pointer" : "default"
		},
		background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2))"

	}
}));

const MODULE_TABS: { label: JSX.Element, value: ModuleTab }[] = [
	{
		label: <><IconDocSearch /> OVERVIEW</>,
		value: "overview"
	},
	{
		label: <><IconComments /> COMMENTS</>,
		value: "comments"
	},
	{
		label: <><IconNotes /> NOTES</>,
		value: "notes"
	}
]

interface IProps {
	lesson: ILesson
}

export const LessonPageContent: FC<IProps> = ({lesson}) => {
	const {trainingfeedState} = useContext(TrainingfeedContext);
	const [videoPlaying, setVideoPlaying] = useState(false);
	const [miniQuizzerDisabled, setMiniQuizzerDisabled] = useState(true);
	const [scrollToContinueBannerEnabled, setScrollToContinueBannerEnabled] = useState(false);
	const {cx, classes} = useStyles({scrollToContinueBannerEnabled});
	const pageIsFeed = useMatch("/feed/:courseSlug/*");
	const {courseSlug} = useParams();
	const {moduleSlug: lessonSlug} = useParams();
	const {curriculumState: curriculum, curriculumDispatch} = useContext(CurriculumContext);
	const [{contentContainerRef, memberedCohort}] = useContext(PageBaseContext);
	const {trainingfeedDispatch} = useContext(TrainingfeedContext);
	const {notify} = useContext(NotificationContext);
	const navigate = useNavigate();
	const [tabSelected, setTab] = useState<ModuleTab>("overview");
	const [firstAvailableModule, setFirstAvailableModule] = useState<ICourseCurriculumModule | false>();
	const videoRef = useRef<VideoPlayerRef | null>(null);
	const [player, setPlayer] = useState<PlayerAPI | null>(null);
	(global as any).playerx = player;
	const [videoFinished, setVideoFinished] = useState(false);
	const socket = useContext(SocketContext)
	const location = useLocation();
	const [quizComplete, setQuizComplete] = useState(false);
	const videoContainerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setVideoFinished(!lesson.videoUrl);
		setQuizComplete(!(lesson as any).quiz);
		setScrollToContinueBannerEnabled(false)
	}, [lesson])

	useEffect(() => {
		const updateScrollBanner = (): void => {
			if (!contentContainerRef?.current) return;

			const scrollableDistance = contentContainerRef.current.scrollHeight - contentContainerRef.current.clientHeight;
			const hasScrollDistance = (scrollableDistance > 50);

			setScrollToContinueBannerEnabled(!videoPlaying && !miniQuizzerDisabled && hasScrollDistance);
		};

		updateScrollBanner();
		window.addEventListener("resize", updateScrollBanner);

		return () => {
			window.removeEventListener("resize", updateScrollBanner);
		};
	}, [videoPlaying, miniQuizzerDisabled]);

	useEffect(() => {
		videoRef.current = null;
		if (contentContainerRef?.current) {
			contentContainerRef.current.scrollTo({top: 0, behavior: "smooth"});
		}
	}, [lessonSlug])

	const handleVideoReady = useCallback((lesson: ILesson) => {
		if (!process.env.REACT_APP_BITMOVIN_PLAYER_KEY)
			throw new Error("REACT_APP_BITMOVIN_PLAYER_KEY required")

		const playerConfig: PlayerConfig = {
			key: process.env.REACT_APP_BITMOVIN_PLAYER_KEY,
			analytics: {
				key: process.env.REACT_APP_BITMOVIN_ANALYTICS_KEY
			},
			playback: {
				preferredTech: [{player: PlayerType.Html5, streaming: StreamType.Hls}]
			}
		};

		if (!videoRef.current?.bitmovin?.container)
			return;

		const playerSource = {
			hls: lesson.videoUrl,
			poster: lesson.videoThumbnailUrl
		};

		Player.addModule(EngineBitmovinModule);
		Player.addModule(MseRendererModule);
		Player.addModule(HlsModule);
		Player.addModule(XmlModule);
		Player.addModule(DashModule);
		Player.addModule(AbrModule);
		Player.addModule(ContainerTSModule);
		Player.addModule(ContainerMp4Module);
		Player.addModule(SubtitlesModule);
		Player.addModule(SubtitlesCEA608Module);
		Player.addModule(PolyfillModule);
		Player.addModule(StyleModule);

		// Delay player setup for Safari
		setTimeout(() => {
			if (!videoRef.current?.bitmovin?.container)
				return;

			const playerInstance = new Player(videoRef.current.bitmovin?.container, playerConfig);
			UIFactory.buildDefaultUI(playerInstance);
			playerInstance.load(playerSource).then(() => {
				setPlayer(playerInstance);
				console.log("Successfully loaded source");
			}, (e) => {
				console.log("Error while loading source", e);
			});
		}, 100); // Delay added
	}, [])

	useEffect(() => {
		if (courseSlug && lessonSlug && curriculum){
			if(!pageIsFeed)
				setFirstAvailableModule(getModule(curriculum) ?? false)
			else if(trainingfeedState){
				for(const day of ["overdue", ...Object.keys(trainingfeedState)]){
					if(trainingfeedState[day] === "loading"){
						//day still loading, wait and re-run on dep change
						return;
					}
					for(const module of trainingfeedState[day]){
						if(typeof module !== "string" && !module.complete){
							setFirstAvailableModule(module);
							return;
						}
					}
				}
			}
		}
	}, [courseSlug, lessonSlug, curriculum, trainingfeedState])

	const handleCompletion = useCallback((e: APIState<IModuleEnrollment>): void => {
		if (!courseSlug)
			return

		if (e.isError) {
			notify("Please try again", "Something went wrong", INotificationSeverity.error, 5000);
		}
		else if (!e.isLoading && !e.isError && e.statusCode === 200) {
			const status = !!e?.data?.complete;
			curriculumDispatch({
				type: "setModuleComplete", module: {type: "lesson", slug: lessonSlug as string}, status
			})

			if (pageIsFeed) {
				trainingfeedDispatch({
					type: "setModuleComplete", module: {courseSlug, type: "lesson", slug: lessonSlug as string}, status
				})
			}

			if (e?.data?.complete) {
				notify(null, "Lesson completed", INotificationSeverity.success, 5000);
				if (pageIsFeed)
					navigate("/feed/")
				else
					navigate("/course/" + courseSlug)
			}
			else {
				notify("Set to incomplete", "Lesson Updated", INotificationSeverity.success, 5000);
			}
		}
	}, [curriculumDispatch, lessonSlug])

	const handleCompleteCheck = (lesson: ILesson, sendNotification: boolean): boolean => {
		if (lesson.videoUrl && !videoFinished) {
			sendNotification && notify("Please finish the video before progressing", "Hold up!", INotificationSeverity.warning, 5000)
			return false;
		}
		if ((lesson as any).quiz && !quizComplete) {
			sendNotification && notify("Please attempt the quiz before progressing", "Hold up!", INotificationSeverity.warning, 5000)
			return false;
		}

		return true;
	}

	const prevProgress = useRef<number>()
	//BITMOVIN PLAYER PROGRESS
	useEffect(() => {
		if (!player) {
			return;
		}

		//run in loop to save video progress
		const interval = setInterval(() => {
			const started = (player?.getCurrentTime() ?? 0) > 0;
			const finished = (player.getDuration() - player.getCurrentTime()) < 5;
			if(finished) setVideoFinished(true);

			if (started && prevProgress.current !== player.getCurrentTime()) {
				prevProgress.current = player.getCurrentTime();
				socket?.emit("lessonVideoProgress", {progress: player.getCurrentTime(), courseSlug, lessonSlug})
			}

			setVideoPlaying(player.isPlaying())
		}, 500);

		return () => clearInterval(interval);
	}, [player]);

	const handleReactVideoProgress = (e: {playedSeconds: number}): void => {
		if(videoPlaying){
			socket?.emit("lessonVideoProgress", {progress: e.playedSeconds, courseSlug, lessonSlug})
		}
	}

	useEffect(() => setMiniQuizzerDisabled(!videoFinished), [videoFinished])
	useEffect(() => (lesson.complete) ? setMiniQuizzerDisabled(false) : undefined, [lesson?.complete])

	return (<>
		<Box ref={videoContainerRef}>
			<LessonVideoPlayer
				componentOverrides={{
					header: <Box
						onClick={() => (contentContainerRef.current && scrollToContinueBannerEnabled) && contentContainerRef.current.scrollBy({top: videoContainerRef.current?.offsetHeight, behavior: "smooth"})}
						className={classes.scrollToContinueBanner}
					>
						<KeyboardDoubleArrowDownIcon /><Typography variant="h5">Please scroll to continue</Typography>
					</Box>
				}}
				onReady={() => lesson ? handleVideoReady(lesson) : null}
				ref={videoRef}
				lesson={lesson}
				currentTime={location?.state?.timestamp ?? lesson?.videoTime}
				bitmovinPlayer={player}
				onVideoFinished={() => setVideoFinished(true)}
				onPlay={() => setVideoPlaying(true)}
				onPause={() => setVideoPlaying(false)}
				onEnded={() => {setVideoPlaying(false); setVideoFinished(true)}}
				onProgress={handleReactVideoProgress}

			/>
		</Box>
		<Box className={classes.tabs}>
			<Tabs
				variant="standard"
				value={tabSelected}
				onChange={(_e, tab) => setTab(tab)}
			>
				{MODULE_TABS.filter(e => e.value !== "comments" || memberedCohort?.comments).map(({label, value}) => (
					<Tab
						data-test-id={`module-tabs-${value?.toLocaleLowerCase()?.replace(" ","")}`}
						key={value}
						value={value}
						label={label}
						className={cx(classes.tab, tabSelected === value ? classes.activeTab : "")}
					/>
				))}
			</Tabs>
		</Box>
		<CenteredContainer className={classes.centeredContainer}>
			{tabSelected === "overview" && <>
				<PageTitle className={classes.title} title={lesson?.title} subtitle={lesson?.subtitle} />
				<WYSIWYG className={classes.content}>
					{lesson?.content ?? ""}
				</WYSIWYG>

				{(lesson as any)?.quiz &&
					<MiniQuizzer
						disabledMessage="Please complete the video before taking the quiz"
						disabled={miniQuizzerDisabled}
						autoStart
						className={classes.miniQuizzer}
						onComplete={() => setQuizComplete(true)}
						quiz={(lesson as any).quiz}
						onQuizResult={e => { setQuizComplete(!!e) }}
					/>
				}

				{lesson?.files && <>
					<Typography className={classes.fileTitle} variant="h5">Files</Typography>
					<Files className={classes.files} files={lesson.files} />
				</>}

				{lesson?.links && lesson.links.length > 0 && <>
					<Typography className={classes.fileTitle} variant="h5">Links</Typography>
					<Links className={classes.links} links={lesson.links} />
				</>}
				{courseSlug && lesson?.slug && !lesson?.complete &&
					<ModuleCompleteButton
						style={{opacity: handleCompleteCheck(lesson, false) ? 1 : 0.5}}
						validate={() => handleCompleteCheck(lesson, true)}
						courseSlug={courseSlug}
						moduleSlug={lesson.slug}
						type="lesson"
						className={classes.lessonCompleteBtn}
						onApiUpdate={handleCompletion}
					/>
				}
				{courseSlug && lesson?.slug && lesson?.complete && firstAvailableModule &&
					<Button
						onClick={() => navigate(pageIsFeed
							? "/feed/"
							: `/course/${courseSlug}/${firstAvailableModule.type}/${firstAvailableModule?.slug}`
						)}
						className={classes.lessonCompleteBtn}
					>
						Next {firstAvailableModule ? firstAvailableModule?.type : ""}
					</Button>
				}
			</>}
			{tabSelected === "comments" && <CommentsSection className={classes.comments} courseSlug={courseSlug} moduleType={"lesson"} moduleSlug={lessonSlug} />}
			{tabSelected === "notes" && <NotesSection className={classes.notesSection} disableTimeStamp={!lesson?.videoUrl} courseSlug={courseSlug} moduleType="lesson" moduleSlug={lessonSlug} videoRef={videoRef} onGoToModuleAction={(courseId, moduleId, moduleType, timestamp) => { navigate(`/course/${courseId}/${moduleType}/${moduleId}`, {state: {timestamp}}) }} />}
		</CenteredContainer>
	</>
	);
}