import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {AdminLessonPage} from "./AdminLessonPage";
import {AdminLessonsList} from "./AdminLessonsList";
import {AdminLessonLandingPage} from "./AdminLessonLandingPage";

const useStyles = makeStyles()((theme) => ({
	adminLessonsPage: {

	}

}));

export const AdminLessonsPage: FC = () => {
	const {classes} = useStyles();

	return (
		<Box className={classes.adminLessonsPage}>
			<Routes>
				<Route index element={<AdminLessonsList />}/>
				<Route path="create" element={<AdminLessonPage />} />
				<Route path=":lessonId" element={<AdminLessonLandingPage />} />
				<Route path=":lessonId/edit" element={<AdminLessonPage />} />
			</Routes>
		</Box>
	);
}