import {createSvgIcon} from "@plumeuk/shapeshift-common/icon";

export const IconNotification = createSvgIcon(
	<svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g opacity="0.4" clipPath="url(#clip0_935_6040)">
			<path fillRule="evenodd" clipRule="evenodd" d="M4.00653 7.65351C5.06588 6.59416 6.50267 5.99902 8.00081 5.99902C9.49896 5.99902 10.9358 6.59416 11.9951 7.65351C13.0544 8.71286 13.6496 10.1496 13.6496 11.6478V16.6913C13.6496 17.0447 13.79 17.3837 14.0399 17.6336C14.3235 17.9172 14.5463 18.0239 14.858 18.0239C15.1735 18.0239 15.4294 18.2797 15.4294 18.5953C15.4294 18.9109 15.1735 19.1668 14.858 19.1668H1.14369C0.828103 19.1668 0.572266 18.9109 0.572266 18.5953C0.572266 18.2797 0.828103 18.0239 1.14369 18.0239C1.45535 18.0239 1.67816 17.9172 1.96174 17.6336C2.21165 17.3837 2.35204 17.0447 2.35204 16.6913V11.6478C2.35204 10.1496 2.94718 8.71286 4.00653 7.65351ZM6.00084 21.143C6.00084 20.6696 6.3846 20.2859 6.85798 20.2859H9.14369C9.61708 20.2859 10.0008 20.6696 10.0008 21.143C10.0008 21.6164 9.61708 22.0001 9.14369 22.0001H6.85798C6.3846 22.0001 6.00084 21.6164 6.00084 21.143Z" fill="white"/>
		</g>
	</svg>,
	"Notification",
	"0 0 16 20"
);