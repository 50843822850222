import {FC} from "react";
import {Box, BoxProps, Typography} from "@mui/material";
import {AdminHeader} from "../../../../AdminHeader";
import {makeStyles} from "tss-react/mui";
import {ICohortCourseEntity} from "../../../../../../types/course";

const useStyles = makeStyles()((theme) => ({
	contentBox: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(2.5),
		backgroundColor: theme.palette.background.paper,
		borderWidth: theme.borderWidth.small,
		borderColor: theme.palette.grey[100],
		borderStyle: "solid",
		borderRadius: theme.borderRadius.small
	},
	hr: {
		borderTop: 0,
		borderColor: theme.palette.grey[100]
	},
	contentContainer: {
		display: "flex",
		gap: theme.spacing(3),
		flexDirection: "column",
		"& span": {
			fontWeight: 700
		}
	}
}));

interface ICustomProps {
	courseId: number,
	course?: ICohortCourseEntity
}

export type IProps = Omit<BoxProps, "children"> & ICustomProps;


export const OverviewTab:FC<IProps> = ({courseId, course, ...baseProps}) => {
	const {classes} = useStyles();

	return <Box {...baseProps}>
		<Box className={classes.contentBox}>
			<AdminHeader title="Course Overview" />
			<Box className={classes.contentContainer}>
				{course ? <>
					<Typography variant="body1">{course.description}</Typography>
					<hr className={classes.hr}/>
					<Typography variant="body1"><span>Number of Lessons: </span>{course.moduleCount}</Typography>
					<Typography variant="body1"><span>Content Type:  </span>{course.contentType }</Typography>
					<Typography variant="body1"><span>Employees Currently Enrolled:  </span>{course.enrollmentCount}</Typography>
					{/* <Typography variant="body1">Course Duration: {course.timeEstimateHours}</Typography> */}
					<Typography variant="body1"><span>Course Completions:  </span>{course.completionCount}</Typography>
				</>: null}
			</Box>
		</Box>
	</Box>
}
