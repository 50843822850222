import {FC, useCallback, useRef, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Badge, Box, Breadcrumbs, Button, Divider, Menu, MenuItem, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";
import {Player, PlayerAPI, PlayerConfig, PlayerType, StreamType} from "bitmovin-player/modules/bitmovinplayer-core";
import EngineBitmovinModule from "bitmovin-player/modules/bitmovinplayer-engine-bitmovin";
import MseRendererModule from "bitmovin-player/modules/bitmovinplayer-mserenderer";
import HlsModule from "bitmovin-player/modules/bitmovinplayer-hls";
import DashModule from "bitmovin-player/modules/bitmovinplayer-dash";
import AbrModule from "bitmovin-player/modules/bitmovinplayer-abr";
import XmlModule from "bitmovin-player/modules/bitmovinplayer-xml";
import ContainerTSModule from "bitmovin-player/modules/bitmovinplayer-container-ts";
import ContainerMp4Module from "bitmovin-player/modules/bitmovinplayer-container-mp4";
import SubtitlesModule from "bitmovin-player/modules/bitmovinplayer-subtitles";
import SubtitlesCEA608Module from "bitmovin-player/modules/bitmovinplayer-subtitles-cea608";
import PolyfillModule from "bitmovin-player/modules/bitmovinplayer-polyfill";
import StyleModule from "bitmovin-player/modules/bitmovinplayer-style";
import "bitmovin-player/bitmovinplayer-ui.css";
import {UIFactory} from "bitmovin-player-ui";
import {IAnnouncementEntity} from "../../../../types/announcement";
import {LessonVideoPlayer} from "../../../ModulePage/LessonPage/LessonVideoPlayer";
import {EntityIdentifier} from "@plumeuk/shapeshift-types/entityService";
import {useApi} from "@plumeuk/shapeshift-identity";
import {AnnouncementFeedModule} from "./AnnouncementFeedModule";

interface IStyleProps {
	hasVideo?: boolean
}

const useStyles = makeStyles<IStyleProps>()((theme, {hasVideo}) => ({
	adminAnnouncementLandingPage: {
		maxWidth: theme.containers.constrained,
		marginLeft: "auto",
		marginRight: "auto"
	},
	breadcrumbs: {
		marginBottom: "25px"
	},
	adminBanner: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "auto",
		borderRadius: "5px",
		overflow: "hidden",
		marginBottom: "12px"
	},
	adminBannerTitle: {
		position: "relative",
		fontSize: "34px",
		lineHeight: "1.1",
		fontWeight: "600",
		letterSpacing: "0.25px",
		alignSelf: "end",
		margin: "12px 0 0",
		zIndex: 2
	},
	adminAnnouncementButton: {
		marginTop: "-2px"
	},
	adminVideo: {
		width: "100%",
		height: "100%"
	},
	adminBackgroundImage: {
		position: "absolute",
		left: 0,
		top: 0,
		width: "100%",
		height: "100%",
		objectFit: "cover"
	},
	adminAnnouncementLabel: {
	},
	adminBadge: {
		padding: theme.spacing(0.25, 1.25, 0.35),
		marginBottom: theme.spacing(0.5)
	},
	contentBox: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(3.5),
		backgroundColor: theme.palette.background.paper,
		border: 0,
		borderRadius: theme.borderRadius.small
	},
	titleRow :{
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	announcementBox: {
	},
	adminTrainingFeedModule: {
		minWidth: "340px",
		maxWidth: "340px"
	},
	announcementList: {
		marginTop: theme.spacing(1),
		flexGrow: 1
	},
	announcementListItem: {
		fontSize: "20px"
	},
	announcementListLabel: {
		fontSize: "14px",
		marginTop: "8px",
		color: theme.palette.grey[600]
	},
	hr: {
		borderTop: 0,
		borderColor: theme.palette.grey[50]
	}
}));
const p: EntityIdentifier = "api::announcement.announcement" as EntityIdentifier;

export const AdminAnnouncementLandingPage: FC = () => {
	const {announcementId, id: _courseId} = useParams();
	const [announcementEntity] = useApi<IAnnouncementEntity>("/api/announcement/" + announcementId)
	const announcement = announcementEntity.data;
	const videoUrl = announcement?.videoUrl;
	const hasVideo = !!videoUrl;
	const {classes} = useStyles({hasVideo});
	const videoRef = useRef<VideoPlayerRef | null>(null);
	const [player, setPlayer] = useState<PlayerAPI | null>(null);
	const [menuAnchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const menuOpen = Boolean(menuAnchorEl);

	const handleMenuItemClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget);
	};
	const handleMenuClose = (): void => {
		setAnchorEl(null);
	};

	const handleVideoReady = useCallback((announcement: IAnnouncementEntity) => {
		if(!process.env.REACT_APP_BITMOVIN_PLAYER_KEY)
			throw new Error("REACT_APP_BITMOVIN_PLAYER_KEY required")

		const playerConfig: PlayerConfig = {
			key: process.env.REACT_APP_BITMOVIN_PLAYER_KEY,
			analytics: {
				key: process.env.REACT_APP_BITMOVIN_ANALYTICS_KEY
			},
			playback: {
				preferredTech: [{player: PlayerType.Html5, streaming: StreamType.Hls}]
			}
		};

		if(!videoRef.current?.bitmovin?.container)
			return;

		const playerSource = {
			hls: announcement.videoUrl,
			poster: announcement.videoThumbnailUrl
		};

		Player.addModule(EngineBitmovinModule);
		Player.addModule(MseRendererModule);
		Player.addModule(HlsModule);
		Player.addModule(XmlModule);
		Player.addModule(DashModule);
		Player.addModule(AbrModule);
		Player.addModule(ContainerTSModule);
		Player.addModule(ContainerMp4Module);
		Player.addModule(SubtitlesModule);
		Player.addModule(SubtitlesCEA608Module);
		Player.addModule(PolyfillModule);
		Player.addModule(StyleModule);

		// Delay player setup for Safari
		setTimeout(() => {
			if(!videoRef.current?.bitmovin?.container)
				return;

			const playerInstance = new Player(videoRef.current.bitmovin?.container, playerConfig);
			UIFactory.buildDefaultUI(playerInstance);
			playerInstance.load(playerSource).then(() => {
				setPlayer(playerInstance);
			}, (e) => {
				// reporting needed?
			});
		}, 100);
	}, [])

	return (
		<Box className={classes.adminAnnouncementLandingPage}>
			<Breadcrumbs className={classes.breadcrumbs} separator="/" aria-label="breadcrumb">
				<Link to={"/admin/announcements"}><Typography variant="body1">Announcements</Typography></Link>
				<Link to={`/admin/announcements/${announcementId}`}><Typography variant="body1">{announcement?.title}</Typography></Link>
			</Breadcrumbs>
			<Typography variant="h3" className={classes.adminBannerTitle}>
				{announcement?.title}
			</Typography>
			<Box className={classes.adminBanner}>
				{hasVideo &&
					<Box className={classes.adminVideo}>
						<LessonVideoPlayer onReady={() => announcement ? handleVideoReady(announcement) : null} ref={videoRef} announcement={announcement} bitmovinPlayer={player} />
					</Box>
				}
			</Box>
			<Box className={classes.contentBox}>
				<Box className={classes.titleRow}>
					<Typography variant="h4" className={classes.adminAnnouncementLabel}>
						Announcement Details
					</Typography>
					<Button
						className={classes.adminAnnouncementButton}
						variant="contained"
						id="announcement-action-menu-button"
						aria-controls={menuOpen ? "announcement-action-menu-button" : undefined}
						aria-haspopup="true"
						aria-expanded={menuOpen ? "true" : undefined}
						onClick={handleMenuItemClick}
					>
						Actions
					</Button>
					<Menu
						id="action-menu"
						anchorEl={menuAnchorEl}
						open={menuOpen}
						onClose={handleMenuClose}
						MenuListProps={{
							"aria-labelledby": "basic-button"
						}}
					>
						<Link to="edit">
							<MenuItem onClick={handleMenuClose}>Edit</MenuItem>
						</Link>
						<MenuItem onClick={handleMenuClose}>Set Live</MenuItem>
						<Divider/>
						<MenuItem onClick={handleMenuClose} sx={t => ({color: t.palette.error.main})}>Deactivate</MenuItem>
					</Menu>
					{/*  */}
				</Box>
				{announcement &&
					<Box className={classes.announcementBox} sx={{display: "flex"}} gap={5}>
						<AnnouncementFeedModule
							className={classes.adminTrainingFeedModule}
							announcement={{
								content: announcement.content,
								scheduledDeliveryDate: announcement.scheduledDeliveryDate ?? new Date(),
								title: announcement.title,
								subtitle: announcement.subtitle ?? "",
								complete: false,
								slug: "123",
								type: "lesson",
								__type: "",
								id: announcement.id
							}}/>
						<Box className={classes.announcementList} display={"flex"} flexDirection={"column"} gap={1}>
							<Divider />
							<Box>
								{announcement.scheduledDeliveryDate ?
									<Badge className={classes.adminBadge} color="success">Scheduled</Badge> :
									<Badge className={classes.adminBadge} color="error">Not Scheduled</Badge>
								}
								<Typography className={classes.announcementListLabel}>Status</Typography>
							</Box>
							<Divider />
							<Box>
								<Typography className={classes.announcementListItem} variant="h5">
									{announcement.scheduledDeliveryDate?.toDateString() ?? "none"}
								</Typography>
								<Typography className={classes.announcementListLabel}>Delivery Date</Typography>
							</Box>
							<Divider />
							<Box>
								<Typography className={classes.announcementListItem} variant="h5">
									[to do] {announcement.createdBy?.firstname} {announcement.createdBy?.lastname}
								</Typography>{/* need to be populated*/}
								<Typography className={classes.announcementListLabel}>Author</Typography>
							</Box>
							<Divider />
							<Box>
								<Typography className={classes.announcementListItem} variant="h5">
									{announcement.audience?.length} Employees
								</Typography>
								<Typography className={classes.announcementListLabel}>Audience</Typography>
							</Box>
							<Divider />
						</Box>
					</Box>
				}
			</Box>
		</Box>
	);
}