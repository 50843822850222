export const sideMenuWidth = "420px";
export const toolbarHeight = "70px";
export const footerHeight = "50px";
export const footerToolbarCombinedHeight = "120px";
export const mobileNavBarHeight = "80px";

export const palette = {
	background: "#222222",
	darkGrey: "#191919", //> navbar
	midGrey: "rgba(255, 255, 255, 0.6)",
	drawer: "#2c2c2c",
	grey05: "rgba(255, 255, 255, .05)",
	grey08: "rgba(255, 255, 255, .08)",
	grey10: "rgba(255, 255, 255, .1)",
	grey15: "rgba(255, 255, 255, .15)",
	grey20: "rgba(255, 255, 255, .2)",
	grey40: "rgba(255, 255, 255, .4)",
	grey60: "rgba(255, 255, 255, .6)",
	grey70: "rgba(255, 255, 255, .7)",
	grey80: "rgba(255, 255, 255, .8)",
	success: "#4CB05C",
	greySolid: "#3E3E3E",
	//primary
	orange: "#FF9E00",
	orange25: "#FF9E0025",
	orange50: "#FF9E0050",
	orange75: "#FF9E0075",
	black: "#191919",
	// grey15: "#989898",
	// midGrey :"#7b7b7b", //#fff 40%
	midGrey2: "#a7a7a7", //#fff 60%
	midGrey3: "#3b3b3b", //#fff 15%
	midGrey4: "#656565",
	// darkGrey: "#3d3d3d", //#fff 10%  - another vaiant in designs -2c2c2c, 2b2b2b
	error: "#D94949",
	white: "#fff"



	//OLD
	// orange: "#FF9E00",
	// orange50: "#FF9E0050",
	// black: "#191919",
	// grey15: "#989898",
	// grey: "#757575",
	// midGrey :"#7b7b7b", //#fff 40%
	// midGrey2: "#a7a7a7", //#fff 60%
	// midGrey3: "#3b3b3b", //#fff 15%
	// midGrey4: "#656565",
	// darkGrey: "#3d3d3d", //#fff 10%  - another vaiant in designs -2c2c2c, 2b2b2b
	// background: "#282828",
	// drawer: "#3E3E3E",
	// error: "#FE4A49",
	// success: "#228B22",
	// white: "#fff"
}

