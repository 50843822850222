import {useNavigate} from "react-router-dom";
import {DynamicContainer, NotesLibrary} from "@plumeuk/shapeshift-common/v2";
import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {Box, Typography} from "@mui/material";
import {useCourseContentType} from "./useCourseContentType";
import {NotesFilter} from "./NotesFilter";
import {PageTitle} from "../../components/pageBase/pageTitle";
import {useSSTranslation} from "../../i18n/useSSTranslation";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
		[theme.breakpoints.up("lg")]:{
			"& [class*='menuIcon']": {
				display: "none"
			}
		}
	},
	container:{
		paddingBottom: "70px",
		maxWidth: "1220px",
		width: "calc(100% - 20px)",
		margin: "auto"
	},
	topContainer: {
		margin: "60px 0 0"
	},
	pageTitle: {
		marginTop: "60px"
	},
	notesContainer: {
		"& [class*='notesSearchContainer']": {
			[theme.breakpoints.down("sm")]:{
				flexDirection: "column"
			}
		}
	}
}));


export const MyNotesPage: React.FC = () => {
	const navigate = useNavigate();
	const {classes} = useStyles();
	const {items: contentTypes} = useCourseContentType()
	const {t} = useSSTranslation()

	return (
		<PageBase className={classes.pageBase} contentWidth="100%" disableSideBar>
			<Box className={classes.container}>
				<PageTitle className={classes.pageTitle} title={t("page_notes_title", "Notes library")} />
				<NotesLibrary className={classes.notesContainer} onGoToModuleAction={(courseId, moduleId, moduleType, timestamp) => {navigate(`/course/${courseId}/${moduleType}/${moduleId}`, {state: {timestamp}})}} componentOverrides={{notesFilter: (props) => {
					return <NotesFilter {...props} contentTypes={contentTypes} />
				}}} />
			</Box>
		</PageBase>
	);
}