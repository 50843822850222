import {APIState, useApi} from "@plumeuk/shapeshift-identity";
import {useEffect} from "react";
import {APIRequestConfig} from "@plumeuk/shapeshift-common/types/ICommon";
import {IAnnouncement} from "../types/announcement";

type S = IAnnouncement;
type R = string | {populate: string[]};
type G = (announcementSlugOverride?: string, populateOverride?: string[], localeOverride?: string, previewOverride?: boolean, configOverride?: APIRequestConfig<R>) => void;

export type IUseAnnouncementReturnProp = {
	announcement?: S,
	apiResult: APIState<S>,
	getAnnouncement: G,
	setAnnouncement: (announcement: S) => void
}

const announcementPath = (slug: string): string => "/api/announcement/learn/" + slug;

export const useAnnouncement = (announcementSlug?: string, data?: S, populate?: string[], locale?: string, preview?: boolean): IUseAnnouncementReturnProp => {
	const [apiResult, getData, setAnnouncement] = useApi<S>(data || !announcementSlug ? null : {url: announcementPath(announcementSlug), data: {populate, locale}}, data);

	const getAnnouncement: G = (announcementSlugOverride, populateOverride, localeOverride, previewOverride, configOverride) => {
		const _announcementSlug = announcementSlugOverride ?? announcementSlug;
		const _populate = populateOverride ?? populate;
		const _locale = localeOverride ?? locale;
		const _preview = previewOverride ?? preview;
		if(!_announcementSlug) return;

		getData({...(configOverride ?? {}), url: announcementPath(_announcementSlug), data: {locale: _locale, populate: _populate, preview: _preview}});
	}

	useEffect(() => data && setAnnouncement(data), [data])

	return {announcement: apiResult.data, apiResult, getAnnouncement, setAnnouncement};
}