import {IModuleEnrollment} from "@plumeuk/shapeshift-common/types";
import {APIRequestConfig} from "@plumeuk/shapeshift-common/types/ICommon";
import {API, APIState, useApi} from "@plumeuk/shapeshift-identity";

type S = IModuleEnrollment;
type R = string;
type G = (announcementSlug: string, config?: APIRequestConfig<R>) => void;

export type IUseSetAnnouncementCompletionReturnProp = {
	response?: S,
	apiResult: APIState<S>,
	setComplete: G
}

export const useSetAnnouncementCompletion = (): IUseSetAnnouncementCompletionReturnProp => {
	const [apiResult, getData] = useApi<S>(null);

	const setComplete: G = (announcementSlug, config) =>
		getData({
			...(config ?? {}),
			url: "/api/announcement/learn/" + announcementSlug + "/complete" ,
			method: "PUT",
			cache: false
		});

	return {response: apiResult.data, apiResult, setComplete};
}