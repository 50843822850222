import {FC, useEffect, useState} from "react";
import {Box, BoxProps, LinearProgress, Stack} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {AdminHeader} from "../../../AdminHeader";
import {makeStyles} from "tss-react/mui";
import {DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF, GridSelectionModel, GridColumns} from "@mui/x-data-grid-pro";
import {IAdminUserActiveCourses} from "../../../../../types/admin";
import {useApi} from "@plumeuk/shapeshift-identity";
import {AdminBulkUserUnenrollModal} from "../../../../../components/admin/bulkEnrollment/AdminBulkUserUnenrollModal";
import {CustomToolbar} from "../../../../../components/admin/bulkEnrollment/CustomToolbar";

const useStyles = makeStyles()((theme) => ({
	progressBar: {
		width:"100%"
	},
	progressBarRoot:{
		width:"100%",
		alignItems:"center",
		gap: theme.spacing(2)
	},
	tableRoot: {
		cursor: "default"
	}
}));

interface ICustomProps {
	userId: number
}

export type IProps = Omit<BoxProps, "children"> & ICustomProps;

const headCells: GridColumns<IAdminUserActiveCourses["data"][0]> = ([
	{
		...GRID_CHECKBOX_SELECTION_COL_DEF,
		type: "string",
		hideable: false
	},
	{
		field: "title",
		flex: 1,
		headerName: "Course",
		type: "string",
		minWidth: 240
	},
	{
		field: "moduleCount",
		flex: 1,
		headerName: "Lessons",
		maxWidth: 110,
		type: "string"
	},
	{
		field: "dueOn",
		flex: 1,
		headerName: "Due on",
		type: "date"
	},
	{
		field: "assignedOn",
		flex: 1,
		headerName: "Assigned on",
		type: "date"
	},
	{
		field: "type",
		flex: 1,
		headerName: "Enrollment Type",
		type: "string",
		valueGetter: (e) => {
			if(e.value === "learner")
				return "Self Assigned"
			if(e.value === "strapi-admin")
				return "Admin Assigned"
			if(e.value === "due-date-schedule")
				return "Due Date Assigned"
			if(e.value === "prioritize-schedule")
				return "Prioritize Assigned"
			if(e.value === "auto-assign-schedule")
				return "Auto Assigned"
		}
	},
	{
		field: "contentType",
		flex: 1,
		headerName: "Content Type",
		type: "string"
	},
	{
		field: "percentCompleted",
		flex: 1,
		headerName: "Course Progress",
		type: "string",
		renderCell: e => {
			const {classes} = useStyles();
			return <Stack className={classes.progressBarRoot} direction="row"><LinearProgress className={classes.progressBar} variant="determinate" value={e.value} /><span>{`${e.value}%`}</span></Stack>
		}
	}
])

export const UserActiveCoursesTab:FC<IProps> = ({userId, ...baseProps}) => {
	const [rows, getRows] = useApi<IAdminUserActiveCourses>()
	const {classes} = useStyles();
	const [selected, setSelected] = useState<GridSelectionModel>([]);
	const [bulkCompletionModalOpen, setBulkCompletionModalOpen] = useState<boolean>(false);

	useEffect(() => {
		getRows(`/api/user/admin/${userId}/activeCourses`)
	}, [userId])

	return <Box {...baseProps}>
		<AdminHeader title="User's Active Courses" />
		<Box>
			<DataGridPro<IAdminUserActiveCourses["data"][0]>
				className={classes.tableRoot}
				initialState={{
					sorting: {
						sortModel: [{field: "assignedOn", sort: "desc"}]
					}
				}}
				aria-label="admin table"
				loading={rows.isLoading}
				pagination
				rowCount={rows?.data?.totalCount ?? 0}
				rows={rows?.data?.data ?? []}
				columns={headCells}
				rowsPerPageOptions={[5, 10, 20, 100]}
				components={{Toolbar: (): JSX.Element => (<CustomToolbar>
					<Box onClick={() => {selected.length > 0 && setBulkCompletionModalOpen(true)}}><Delete /> UNENROLL</Box>
				</CustomToolbar>)}}
				checkboxSelection
				autoHeight
				selectionModel={selected}
				onSelectionModelChange={e => setSelected(e)}
			/>
			<AdminBulkUserUnenrollModal
				onClose={(r) => {
					setSelected([]);
					setBulkCompletionModalOpen(false);
					if(r) getRows()}}
				userId={userId}
				open={bulkCompletionModalOpen}
				selected={selected as number[]}
			/>
		</Box>
	</Box>
}