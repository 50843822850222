import {FC, useEffect} from "react";
import {IAnnouncement} from "../types/announcement";
import {IUseAnnouncementReturnProp, useAnnouncement} from "../hooks/useAnnouncement";


type S = IAnnouncement;

export interface IProps {
	announcementSlug?: string,
	populate?: string[],
	children?: (e: IUseAnnouncementReturnProp) => JSX.Element,
	data?: S,
	locale?: string
}

export const AnnouncementDataProvider: FC<IProps> = ({children, announcementSlug, data, populate, locale}) => {
	const dataHook = useAnnouncement(announcementSlug, data, populate, locale)
	const {announcement, getAnnouncement} = dataHook;

	useEffect(() => {
		if(announcementSlug && !data){
			getAnnouncement(announcementSlug, populate, locale);
		}
	}, [announcementSlug, locale])

	return children?.(dataHook)
		?? <div>Please implement children to handle announcement {announcement?.title ?? "...loading"}</div>
}

export default AnnouncementDataProvider;