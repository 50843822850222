import {FC, useContext} from "react";
import {Box, BoxProps} from "@mui/material";
import {AdminHeader} from "../../../../AdminHeader";
import {makeStyles} from "tss-react/mui";
import {useApi} from "@plumeuk/shapeshift-identity";
import {ICourseEntity, IAdminUsersQuizzesResponseEntry, ILocalizationEntity} from "@plumeuk/shapeshift-types";
import {AdminTable, AdminTableProps} from "@plumeuk/shapeshift-common/admin";
import {useNavigate} from "react-router-dom";
import {convertSecondsToHHMMSS} from "../../../../../../utils";
import {GRID_CHECKBOX_SELECTION_COL_DEF} from "@mui/x-data-grid-pro";
import {PageBaseContext} from "../../../../../../contexts/pageBaseContext";

const useStyles = makeStyles()((theme) => ({
	contentBox: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(2.5),
		backgroundColor: theme.palette.background.paper,
		borderWidth: theme.borderWidth.small,
		borderColor: theme.palette.grey[100],
		borderStyle: "solid",
		borderRadius: theme.borderRadius.small
	},
	quizzesAdminTable: {
		padding: 0,
		border: 0
	}
}));

interface ICustomProps {
	courseId: number,
	course?: ICourseEntity,
	allowLessonEdit: boolean
}

export type IProps = Omit<BoxProps, "children"> & ICustomProps;

const headCells = (locales?: ILocalizationEntity[]): AdminTableProps<"quiz", IAdminUsersQuizzesResponseEntry>["columns"] => ([
	{
		...GRID_CHECKBOX_SELECTION_COL_DEF,
		type: "string",
		hideable: false
	},
	{
		field: "title",
		type: "string",
		flex: 1,
		headerName: "Title",
		minWidth: 240
	},
	{
		field: "videoDuration",
		type: "string",
		flex: 1,
		headerName: "Duration",
		valueGetter: (e) => e.value ? convertSecondsToHHMMSS(e.value) : ""
	},
	{
		field: "updatedAt",
		type: "string",
		flex: 1,
		headerName: "Last Modified",
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	},
	{
		field: "languages",
		type: "string",
		flex: 1,
		headerName: "Supported Languages",
		valueGetter: ({value}: {value: IAdminUsersQuizzesResponseEntry["languages"]}) => value?.map(e => locales?.find(x => x.code === e)?.name)?.join(", ")
	}
]);


export const QuizzesTab: FC<IProps> = ({courseId,course, allowLessonEdit, ...baseProps}) => {
	const {classes} = useStyles();
	const [locales] = useApi<ILocalizationEntity[]>("/api/settings/i18n");
	const navigate = useNavigate();
	const [{ownedCohort}] = useContext(PageBaseContext);

	return <Box {...baseProps}>
		<Box className={classes.contentBox}>
			<AdminHeader title={`${course?.title} Quizzes`} />
			<AdminTable<"quiz", IAdminUsersQuizzesResponseEntry>
				className={classes.quizzesAdminTable}
				searchDTOverride={{extendedAccess: true} as any}
				url={"/api/strapi-plugin-shapeshift-lms/cohort/course/" + courseId + "/quizzes"}
				columns={headCells(locales.data)}
				onEditAction={e => allowLessonEdit ? navigate(e.id.toString()) : null}
				type="quiz"
			/>
		</Box>
	</Box>
}