import {Box, Button, LinearProgress, Typography} from "@mui/material"
import {FC} from "react"
import {makeStyles} from "tss-react/mui";
import {ILibraryItem, isILibraryCourse} from "@plumeuk/shapeshift-types";
import {LibraryItemProgressionActionBar} from "@plumeuk/shapeshift-common/library";

interface IProps {
	item: ILibraryItem,
	active?: boolean
}

const useStyles = makeStyles()((theme) => ({
	libraryCarouselActionBar: {
		display: "flex",
		justifyContent: "end",
		flexDirection: "column",
		padding: 0,
		margin: 0,
		background: theme.palette.background.paper,
		position: "relative",
		["& [class*='libraryItemProgressionActionBar']"]: {
			padding: "0 30px"
		}
	},
	spacer: {
	},
	loadingBar: {
		width: "100%",
		marginTop: "8px",
		marginRight: "10px"
	},
	cardProgress: {
		fontSize: "15px"
	},
	content: {
		flexGrow: 2
	},
	btn: {
		boxSizing: "border-box",
		width: "calc(100% - 60px)",
		margin: "5px 30px 0",
		position: "relative",
		top: "-30px"
	}

}));

export const LibraryCarouselActionBar:FC<IProps> = ({item, active}) => {
	const {classes} = useStyles();

	return (
		<Box className={classes.libraryCarouselActionBar}>
			<Box className={classes.content}>
				<Box className={classes.spacer}></Box>
				<LibraryItemProgressionActionBar
					active={active}
					data={isILibraryCourse(item) ? item.progress : undefined}
					item={item}
					componentOverrides={item.enrolled ? {
						content: item.enrolled ? (progress) => <>
							<LinearProgress className={classes.loadingBar} variant="determinate" value={progress ?? 0} />
							<Typography className={classes.cardProgress}>{progress}%</Typography>
						</> : () => <></>
					} : {
						content: () => <></>
					}}
				/>
			</Box>

			<Button className={classes.btn}>{item.enrolled ? "Learn" : "Enroll"}</Button>
		</Box>
	)
}