import {useAnnouncements} from "../hooks/useAnnouncements";
import {DevLog} from "@plumeuk/shapeshift-common/common";
import {createContext, useReducer, ReactElement, Reducer, useEffect, Dispatch, FC, useContext} from "react";
import {INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";
import {IAnnouncement} from "../types/announcement";
import {LocaleContext} from "./localeContext";
import {useSSTranslation} from "../i18n/useSSTranslation";

const initialState: {announcementState: AnnouncementContextState; announcementDispatch: Dispatch<AnnouncementContextAction>} = {announcementState: undefined, announcementDispatch: () => {}};
const AnnouncementContext = createContext(initialState);

type AnnouncementContextState = IAnnouncement[] | undefined

type AnnouncementContextAction = {
	type: "set";
	payload?: IAnnouncement[];
} | {
	type: "setAnnouncementComplete",
	id: number,
}

interface IProps {
	children: ReactElement
}

const AnnouncementProvider: FC<IProps> = ({children}) => {
	const [{locale}] = useContext(LocaleContext);
	const {announcements, setAnnoucements, apiResult} = useAnnouncements(locale ?? "en")
	const {notify} = useContext(NotificationContext);
	const {t} = useSSTranslation();

	const [announcementState, announcementDispatch] = useReducer<Reducer<AnnouncementContextState, AnnouncementContextAction>>((state: AnnouncementContextState, action: AnnouncementContextAction) => {
		switch (action.type) {
			case "set": {
				return action.payload;
			}
			case "setAnnouncementComplete": {
				const current = (announcements ?? []).find(e => e.id === action.id);
				if(!current || !announcements) return state;

				current.complete = true;
				setAnnoucements([...announcements]);
				return state;
			}
			default:
				return state;
		}
	}, initialState.announcementState);

	//below is for debugging only
	useEffect(() => {
		if(announcementState)
			DevLog("UPDATE TO ANOUNCEMENT STATE", announcementState);
	}, [announcementState]);

	useEffect(() => {
		if(apiResult.isError) {
			notify(t("notifications_wrong_body", "Please try again"), t("notifications_wrong_title", "Something went wrong"), INotificationSeverity.error, 5000);
		}
	}, [apiResult])

	useEffect(() => {
		if(announcements) {
			announcementDispatch({type: "set", payload: announcements})
		}
	}, [announcements])

	return (
		<AnnouncementContext.Provider value={{announcementState, announcementDispatch}}>
			{children}
		</AnnouncementContext.Provider>
	);
};

export {
	AnnouncementContext,
	AnnouncementProvider
};