import {FC, useContext, useEffect} from "react";
import {Box, BoxProps, ThemeProvider} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {useApi} from "@plumeuk/shapeshift-identity";
import {IAdminUsersLessonsResponseEntry, ILocalizationEntity, ILibraryCourse, ILessonEntity} from "@plumeuk/shapeshift-types";
import {AdminTable, AdminTableProps} from "@plumeuk/shapeshift-common/admin";
import {useNavigate} from "react-router-dom";
import {convertSecondsToHHMMSS} from "../../../utils";
import {AdminHeader} from "../../ManagerView/AdminHeader";
import {DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF, GridColumns} from "@mui/x-data-grid-pro";
import {palette} from "../../../constants"
import {adminTheme} from "../../../templates/adminTheme";
import {ICourse, ICourseModule, IModuleBase} from "@plumeuk/shapeshift-types/ICourse";
import {locale} from "dayjs";
import {LocaleContext} from "../../../contexts/localeContext";

const useStyles = makeStyles()((theme) => ({
	contentBox: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(2.5),
		backgroundColor: palette.greyDark,
		borderWidth: 0,
		borderRadius: theme.borderRadius.small,
		"& .MuiButton-text": {
			color: theme.palette.primary.main,
			border: "0px solid",
			borderRadius: "0",
			fontWeight: "600",
			fontSize: "0.8125rem",
			textTransform: "uppercase",
			padding: "12px 5px 12px",
			letterSpacing: "0.025rem",
			"&:hover": {
				background: "transparent",
				opacity: "0.8"
			},
			"& span": {
				marginRight: 0,
				background: "transparent"
			},
			"& svg": {
				marginRight: "8px",
				"& path": {
					fill: `${theme.palette.primary.main} !important`
				}
			},
			"&:disabled": {
				color: theme.palette.primary.main,
				opacity: 0.5,
				"& svg": {
					"& path": {
						fill: theme.palette.primary.main
					}
				}
			}
		},
		"& [class*='customToolbar']": {
			display: "none !important"
		}
	},
	lessonsAdminTable: {
		padding: 0
	}
}));

interface ICustomProps {
	course?: ILibraryCourse;
}

export type IProps = Omit<BoxProps, "children"> & ICustomProps;

const headCells: GridColumns<ICourseModule> = ([
	{
		field: "title",
		type: "string",
		flex: 1,
		headerName: "Title",
		minWidth: 240
	},
	{
		field: "subtitle",
		type: "string",
		flex: 1,
		headerName: "Subtitle"
	},
	{
		field: "videoLength",
		type: "string",
		flex: 1,
		headerName: "Duration",
		valueGetter: (e) => e.value ? convertSecondsToHHMMSS(e.value) : ""
	}
]);


export const LessonsTab:FC<IProps> = ({course, ...baseProps}) => {
	const {classes} = useStyles();
	const [rows, getRows] = useApi<ICourseModule[]>()
	const [{locale}] = useContext(LocaleContext)

	useEffect(() => {
		if(course)
			getRows({url: `/api/strapi-plugin-shapeshift-lms/library/courses/${course?.slug}/modules`, data: {locale: locale}})
	}, [course])


	return <Box {...baseProps}>
		<Box className={classes.contentBox}>
			<AdminHeader title={`${course?.title} Lessons`} />
			<ThemeProvider theme={adminTheme}>
				<DataGridPro<ICourseModule>
					className={classes.lessonsAdminTable}
					initialState={{
						sorting: {
							sortModel: [{field: "assignedOn", sort: "desc"}]
						}
					}}
					aria-label="admin table"
					loading={rows.isLoading}
					rowCount={rows?.data?.length ?? 0}
					rows={rows?.data ?? []}
					columns={headCells}
					checkboxSelection={false}
					autoHeight
				/>
			</ThemeProvider>
		</Box>
	</Box>
}