import {FC, useContext, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Breadcrumbs, Button, Chip, LinearProgress, Stack, Typography} from "@mui/material";
import {Link, Navigate, Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import {Tabs, Tab} from "@mui/material";
import {UserTrainingFeedTab} from "./UserPageTabs/UserTrainingFeedTab";
import {UserWatchHistoryTab} from "./UserPageTabs/UserWatchHistoryTab";
import {UserActiveCoursesTab} from "./UserPageTabs/UserActiveCoursesTab";
import {UserFinishedCoursesTab} from "./UserPageTabs/UserFinishedCoursesTab";
import {IUserEntity} from "@plumeuk/shapeshift-types";
import {useApi} from "@plumeuk/shapeshift-identity";
import {palette} from "../../../../constants";
import {UserTrainingPoolTab} from "./UserPageTabs/UserTrainingPoolTab";
import {PageBaseContext} from "../../../../contexts/pageBaseContext";

interface IProps {}

const useStyles = makeStyles()((theme) => ({
	adminLessonPage: {
		"a": {
			textDecoration: "none",
			color: theme.palette.grey[700]
		}
	},
	tabs:{
		marginTop:  theme.spacing(3),
		marginBottom:  theme.spacing(3),
		minHeight: "auto"
	},
	tabHeader:{
		minHeight: "45px",
		paddingTop: 0,
		paddingBottom: 0,
		fontSize: "14px",
		textTransform: "uppercase",
		letterSpacing: "0.4px",
		"&[class*='Mui-selected']": {
			color: palette.orange
		}
	},
	userName:{
		alignItems: "flex-end"
	}
}));


export const AdminUserPage: FC<IProps> = () => {
	const {id: _userId} = useParams();
	const {classes} = useStyles();
	const [user, getUser] = useApi<IUserEntity>()
	const [streak, getStreak] = useApi<number>()
	const location = useLocation();
	const navigate = useNavigate();
	const userId = _userId ? parseInt(_userId) : undefined;
	const timezone = encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)
	const [{bwAdmin}] = useContext(PageBaseContext);

	const tabRoutes = [
		`/admin/users/${userId}/training-feed`,
		...(bwAdmin ? [`/admin/users/${userId}/training-pool`] : []),
		`/admin/users/${userId}/watch-history`,
		`/admin/users/${userId}/active-courses`,
		`/admin/users/${userId}/finished-courses`,
		`/admin/users/${userId}/enrollment`
	];

	const currentTab = tabRoutes.findIndex((route) =>
		location.pathname === (route)
	);

	const handleTabChange = (_: React.SyntheticEvent, newTab: number): void => {
		navigate(tabRoutes[newTab]);
	};

	if(!userId)
		navigate("../")

	useEffect(() => {
		getUser(`/api/strapi-plugin-shapeshift-lms/cohort/users/${userId}`)
		getStreak({url: `/api/user/admin/${userId}/streak`, data: {timezone}})
	}, [userId])

	if(!userId)
		return <LinearProgress />

	return (
		<Box className={classes.adminLessonPage}>
			<Breadcrumbs separator="/" aria-label="breadcrumb">
				<Link to={"/admin/users"}><span>Employees</span></Link>
				<Link to={"/admin/users/" + userId}><span>{user.data?.firstname} {user.data?.lastname}</span></Link>
			</Breadcrumbs>
			<Box sx={{display: "flex", justifyContent: "space-between"}}>
				<Stack direction="row" className={classes.userName}>
					<Typography variant="h2">{user.data?.firstname} {user.data?.lastname}</Typography>
					{(streak?.data !== undefined) && <Chip color="default" label={streak.data + ((streak.data === 1 || streak.data === 0) ? " day" : " days") + " streak"} />}
				</Stack>
				{/* <Link to={process.env.REACT_APP_PROPELAUTH_ACCOUNT_SETTINGS_URI + "/employees/profile/"+user.data?.employeeId}>
					<Button variant="outlined">VIEW USER PROFILE</Button>
				</Link> */}
				{/* <Link to={process.env.REACT_APP_PROPELAUTH_ACCOUNT_SETTINGS_URI + "/employees/profile/"+user.data?.employeeId}>
					<Button variant="outlined">VIEW USER PROFILE</Button>
				</Link> */}
			</Box>
			{(currentTab !== -1) && <Tabs className={classes.tabs} value={currentTab} onChange={handleTabChange} aria-label="report tabs">
				<Tab className={classes.tabHeader} label="My Feed"/>
				{bwAdmin && <Tab className={classes.tabHeader} label="Training Pool"/>}
				<Tab className={classes.tabHeader} label="Watch History"/>
				<Tab className={classes.tabHeader} label="Active Courses"/>
				<Tab className={classes.tabHeader} label="Finished Courses"/>
				{/* <Tab className={classes.tabHeader} label="Enrollment"/> */}
			</Tabs>}

			<Routes>
				<Route path="/" element={<Navigate to="training-feed" replace />} />
				<Route path="training-feed" element={<UserTrainingFeedTab userId={userId}/>} />
				{bwAdmin && <Route path="training-pool" element={<UserTrainingPoolTab userId={userId}/>} />}
				<Route path="watch-history" element={<UserWatchHistoryTab userId={userId}/>} />
				<Route path="active-courses" element={<UserActiveCoursesTab userId={userId}/>} />
				<Route path="finished-courses" element={<UserFinishedCoursesTab userId={userId} />} />
				{/* <Route path="enrollment" element={<UserEnrollmentTab userId={userId} />} /> */}
			</Routes>
		</Box>
	);
}

