import {Box, BoxProps, CircularProgress, LinearProgress, Typography} from "@mui/material";
import {FC, useCallback, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {ICourse} from "@plumeuk/shapeshift-types/ICourse";
import {IAPIDrivenChildrenFunction} from "@plumeuk/shapeshift-common/types/ICommon";
import {LibrarySearch} from "@plumeuk/shapeshift-common/library";
import {LibraryCarousels, LibraryCarousel, LibraryItem} from "@plumeuk/shapeshift-common/v2";
import {ILibraryItem, ILibrarySearch, isILibraryCourse, isILibraryGroup} from "@plumeuk/shapeshift-types";
import {useNavigate} from "react-router-dom";
import {LibraryCarouselItem} from "./libraryCarouselItem";
import {LibraryCarouselActionBar} from "./libraryCarouselActionBar";
import {IconCourseLibrary} from "../../icons/IconCourseLibrary";
import {palette} from "../../constants";
import {useApi} from "@plumeuk/shapeshift-identity";

interface IPropsCustom {
	data?: ICourse[],
	children?: IAPIDrivenChildrenFunction<ICourse[] | undefined>,
}

export type IProps = Omit<BoxProps, "children"> & IPropsCustom;

const useStyles = makeStyles()((theme) => ({
	library:{
		overflow: "visible"
	},
	title: {
		margin: "10px",
		fontSize: "34px",
		lineHeight: "35px",
		[theme.breakpoints.down("sm")]: {
			fontSize: "35px"
		}
	},
	searchWrapper: {
		marginBottom: "10px",
		display: "flex",
		boxSizing: "border-box",
		background: palette.black,
		flexDirection: "column",
		gap: "10px",
		["& [class*='searchBox-searchBox']"]: {
			display: "flex",
			justifyContent: "center",
			flexDirection: "column",
			paddingLeft: "15px"
		},
		["& [class*='librarySearch'] > *"]: {
			display: "none" //> TO DO: Temporarily hiding other filters until ready
		},
		["& [class*='librarySearch'] [class*='MuiTextField']"]: {
			background: palette.grey10,
			border: `1px solid ${palette.grey05}`,
			height: "52px",
			borderRadius: "8px",
			"input": {
				color: theme.palette.common.white
			},
			"& ::placeholder": {
				opacity: "1",
				color: theme.palette.common.white
			},
			"svg": {
				fill: theme.palette.common.white
			},
			display: "flex"
		},
		[theme.breakpoints.up("sm")]: {
			marginTop: "-80px",
			padding: "32px 60px 40px 60px",
			width: "calc(100% - 20px)",
			borderRadius: "20px",
			marginLeft: "10px",
			marginRight: "10px"
		},
		[theme.breakpoints.down("sm")]: {
			padding: "30px 20px 40px 20px",
			width: "100%",
			["& [class*='librarySearch'] [class*='MuiTextField']"]: {
				border: "none",
				height: "52px",
				display: "flex"
			}
		}
	},
	search: {
		margin: 0
	},
	carouselsContainer: {
		margin: "70px 0 70px",
		display: "flex",
		paddingLeft: "20px",
		paddingRight: "20px",
		flexDirection: "column",
		"[class*='IconButton']": {
			background: palette.grey15,
			"& svg": {
				fontSize: "20px"
			}
		},
		[theme.breakpoints.down("sm")]: {
			margin: "30px 0"
		}
	},
	libraryItem: {
		minHeight: "550px",
		width: "100%",
		height: "100%",
		paddingBottom: "100px",
		borderRadius: "10px",
		"[class*='Button']": {
			margin: 0,
			width: "100%"
		},
		"[class*='CardActionArea']": {
			border: 0,
			padding: "0px 30px"
		},
		"[class*='libraryItemProgressionActionBar']": {
			padding: 0,
			border: 0
		},
		"[class*='libraryCarouselActionBar']": {
			width: "100%"
		},
		"[class*='MuiCardActionArea']": {
			display: "flex",
			flexDirection: "column",
			width: "100%"
		},
		"&:hover": {
			"[class*='btn']": {
				background: palette.orange,
				transition: "0.3s"
			}
		},
		"& [class*='courseItemMedia']": {
			height: "180px"
		}
	},
	item: {
	},
	carousel: {
		"[class*='itemContainer']": {
			display: "flex",
			justifyContent: "stretch"
		},
		"[class*='itemsContainer']": {
			gap: "20px"
		},
		["& > div > [class*='title']"]: {
			fontSize: "42px",
			fontWeight: 600
		},
		"[class*='swiperButton']": {
			minWidth: "0",
			padding: "17px 10px",
			top: "267px"
		},
		"[class*='swiperButtonRight']": {
			right: "-20px",
			[theme.breakpoints.down("lg")]: {
				right: theme.spacing(1)
			}
		},
		"[class*='swiperButtonLeft']": {
			left: "-20px",
			[theme.breakpoints.down("lg")]: {
				left: theme.spacing(1)
			}
		},
		[" [class*='tagContainer']"]: {
			justifyContent: "right",
			flexFlow: "row",
			flexWrap: "wrap",
			"& > *": {
				backgroundColor: theme.palette.primary.main
			}
		}
		// ["& [class*='content'] [class*='title']"]: {
		// 	fontSize: "21px",
		// 	fontWeight: 600
		// }
	},
	searchLabel: {
		color: theme.palette.common.white,
		fontWeight: 600,
		fontSize: "20px",
		[theme.breakpoints.down("sm")]:{
			fontSize: "16px"
		}
	},
	libLoader: {
		maxWidth: "420px",
		margin: "90px auto"
	},
	noneFoundContainer: {
		justifyContent: "center",
		color: theme.palette.text.primary,
		display: "flex",
		gap: "15px",
		"svg": {
			width: "60px",
			height: "60px",
			"path": {
				fill: palette.greySolid
			}
		},
		"& div": {
			display: "flex",
			justifyContent: "center",
			flexDirection: "column"
		}
	},
	countText: {
		color: theme.palette.secondary.main
	}
}));

export const LibraryContent: FC<IProps> = ({className, data, children, ...props}) => {
	const navigate = useNavigate();
	const {classes, cx} = useStyles();
	const [searchValue, setSearchValue] = useState<ILibrarySearch>({searchTerm: "", includeEnrolled: true, includeGroups: false, includeCourses: true});
	// const {tags} = useLibraryTags({sortBy: "popular", limit: 5});
	const [{data: count, isLoading: countLoading}, getCount] = useApi();

	useEffect(() => {
		getCount({url: "/api/strapi-plugin-shapeshift-lms/library/carousels/count", data: {search: JSON.stringify(searchValue)}});
	}, [searchValue]);

	const handleGoToItemAction = useCallback((e: ILibraryItem) => {
		if(isILibraryCourse(e))
			navigate(e.enrolled ? `/course/${e.slug}` : `/course-library/${e.slug}`)

		else if(isILibraryGroup(e))
			navigate(`/course-library/collection/${e.slug}`)
	}, [])

	return (
		<Box className={cx(classes.library, className)} {...props}>

			<Box className={classes.searchWrapper}>
				<Typography className={classes.searchLabel}>Search courses {(count === undefined || countLoading) ? <CircularProgress size={20}/> : <span className={classes.countText}>{`(${count})`}</span>}</Typography>
				<LibrarySearch
					className={classes.search}
					value={searchValue}
					onChange={setSearchValue}
					textFieldProps={{
						inputProps: {
							placeholder: "Course Title, Lesson Title, Lesson Description"
						}
					}}
				/>
			</Box>
			<LibraryCarousels
				className={classes.carouselsContainer}
				onGoToItemAction={handleGoToItemAction}
				searchValue={searchValue}
				componentOverrides={{
					libraryCarousel: (baseProps) =>
						<LibraryCarousel
							{...baseProps}
							className={cx(baseProps.className, classes.carousel)}
							componentOverrides={{item: e => {
								//remove tags to hide from ux
								e.item.tags = undefined;
								return <LibraryItem {...e} className={classes.libraryItem} componentOverrides={{
									cardContent: <LibraryCarouselItem item={e.item} />,
									actionBar: <LibraryCarouselActionBar active={e.active} item={e.item}/>
								}}/>}
							}}/>,
					noResultsFound: <Box className={classes.noneFoundContainer}>
						<IconCourseLibrary />
						<Box><Typography variant="h5">No Courses Found</Typography></Box>
					</Box>
				}}
			/>

		</Box>
	);
}

export default LibraryContent;
