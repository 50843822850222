import {FC} from "react";
import {Box, BoxProps, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {ILibraryCourse} from "@plumeuk/shapeshift-types";
import {AdminHeader} from "../../ManagerView/AdminHeader";
import {palette} from "../../../constants";

const useStyles = makeStyles()((theme) => ({
	contentBox: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(2.5),
		backgroundColor: palette.greyDark,
		borderWidth: 0,
		borderRadius: theme.borderRadius.small
	},
	hr: {
		borderTop: 0,
		borderColor: theme.palette.grey[100]
	},
	contentContainer: {
		display: "flex",
		"& span": {
			fontWeight: 700
		},
		gap: theme.spacing(3),
		flexDirection: "column"
	}
}));

interface ICustomProps {
	course?: ILibraryCourse;
}

export type IProps = Omit<BoxProps, "children"> & ICustomProps;


export const OverviewTab:FC<IProps> = ({course, ...baseProps}) => {
	const {classes} = useStyles();

	return <Box {...baseProps}>
		<Box className={classes.contentBox}>
			<AdminHeader title="Course Overview" />
			<Box className={classes.contentContainer}>
				{course ? <>
					<Typography variant="body1">{course.description}</Typography>
					<hr className={classes.hr}/>
					<Typography variant="body1"><span>Number of Lessons:</span> {course.moduleCount}</Typography>
					{/* <Typography variant="body1"><span>Course Duration:</span> </Typography> */}
				</>: null}
			</Box>
		</Box>
	</Box>
}
