import {FC} from "react";
import {Route, Routes, useParams} from "react-router-dom";
import {PageBase} from "../../../components/pageBase/pageBase";
import {ModulePage} from "../ModulePage";
import {MobileTrainingFeedDrawer} from "../../../components/pageBase/mobileTrainingFeedDrawer";
import {makeStyles} from "tss-react/mui";
import {AnnouncementPage} from "./AnnouncementPage";
import {Box} from "@mui/material";
import {CurriculumProvider} from "../../../contexts/curriculumContext";
import {CourseSideMenuContent} from "../../CoursePage/CourseSideMenuContent";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
		height: "100%",
		"& [class*='background']":  {
			backgroundColor: "white",
			[theme.breakpoints.down("sm")]: {
				backgroundColor: "#F2F1F4"
			}
		}
	}
}));

export const AnnouncementLandingPage: FC = () => {
	const {classes} = useStyles();

	return (
		<PageBase sideBarContent={<CourseSideMenuContent />} contentWidth={"3000px"} disableFooter={true}>
			<Box className={classes.pageBase} >
				<AnnouncementPage />
			</Box>
			<MobileTrainingFeedDrawer />
		</PageBase>
	);
}