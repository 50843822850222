import {Box, BoxProps, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {Link, useLocation, useMatch, useNavigate} from "react-router-dom";
import {palette} from "../../constants";

interface ICustomProps<pages extends string | number | symbol> {
	routesMap: { [key in pages]: string },
	icons: JSX.Element[],
	slimLabels: string[],
	height: string
}

const useStyles = makeStyles<{ height: string }>()((theme, {height}) => ({
	mobileNavBar: {
		background: palette.black,
		borderRadius: "20px 20px 0 0",
		height,
		position: "fixed",
		bottom: 0,
		width: "100%",
		display: "flex",
		padding: "10px",
		boxSizing: "border-box",
		justifyContent: "space-around",
		zIndex: 99999,
		[theme.breakpoints.up("lg")]: {
			display: "none"
		}
	},
	tab: {
		width: "45px",
		textAlign: "center",
		"& svg": {
			"& path": {
				fill: palette.midGrey
			},
			width: "24px",
			marginTop: "5px",
			height: "24px"
		},
		"&.active": {
			"& svg path": {
				fill: palette.orange
			},
			"& p": {
				color: theme.palette.common.white,
				fontWeight: 600
			}
		}
	},
	label: {
		fontSize: "12px",
		color: palette.midGrey

	},
	navIcon: {
		marginTop: "3px",
		maxWidth: "20px",
		display: "inline-block"
	}
}));

type IProps<T extends string | number | symbol> = ICustomProps<T> & Omit<BoxProps, "children">;

export const MobileNavBar = <pages extends string | number | symbol>({routesMap, slimLabels, height, icons, className, ...props}: IProps<pages>): JSX.Element => {
	const location = useLocation();
	const {classes, cx} = useStyles({height});

	let pageSelected: pages | undefined = undefined;
	Object.keys(routesMap).forEach(e => {
		const page = e as pages;
		if (location.pathname.startsWith(routesMap[page]))
			pageSelected = page;
	})

	return (
		<Box className={cx(classes.mobileNavBar, className)} {...props}>
			{(Object.keys(routesMap) as pages[]).filter(e => e !== "Content Manager").map((tab, i) => {
				return (
					<Box className={cx(classes.tab, (pageSelected === tab) ? "active" : "")} key={`mobile_nav_bar_icon${i}`}>
						<Link to={routesMap[tab] ?? ""}>
							{icons[i]}
						</Link>
						<Typography className={classes.label}>{slimLabels[i]}</Typography>
					</Box>
				)
			})}
		</Box>
	);
};
