import {BreakpointsOptions, createTheme, Shadows, Theme} from "@mui/material";
import {CustomMuiTheme} from "@plumeuk/shapeshift-common/theming";
import {palette, toolbarHeight} from "../constants";
import {TypographyStyleOptions} from "@mui/material/styles/createTypography";
import {defaultThemeOptions} from "@plumeuk/shapeshift-common/theming"

const defaultOptions = defaultThemeOptions();

declare module "@mui/material/styles" {
	interface Theme extends CustomMuiTheme {}
	// allow configuration using `createTheme`
	interface ThemeOptions extends CustomMuiTheme{}
}

const breakpoints: BreakpointsOptions = {
	//Mobile  |		 x < sm
	//Tablet  |	sm < x < md
	//Desktop |		 x > md
	values: {
		// extra-small
		xs: 500,
		// small
		sm: 700,
		// medium
		md: 1000,
		// large
		lg: 1200,
		// extra-large
		xl: 1536
	}
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getFontStyles = (font: TypographyStyleOptions, removeProps: string[] = []) => {
	const styles: { [key: string]: any } = {
		fontWeight: !removeProps.includes("fontWeight") ? font.fontWeight : undefined,
		fontSize: !removeProps.includes("fontSize") ? font.fontSize : undefined,
		lineHeight: !removeProps.includes("lineHeight") ? font.lineHeight : undefined,
		color: !removeProps.includes("color") ? font.color : undefined,
		fontFamily: !removeProps.includes("fontFamily") ? font.fontFamily : undefined,
		textTransform: !removeProps.includes("textTransform") ? font.textTransform : undefined
	};

	if (breakpoints?.values?.sm && font[`@media (max-width: ${breakpoints.values.sm}px)`]) {
		styles[`@media (max-width: ${breakpoints.values.sm}px)`] = font[`@media (max-width: ${breakpoints.values.sm}px)`];
	}

	return styles;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getAllFontStyles = (theme: Theme, removeProps: string[] = []) => ({
	"& > p, li": getFontStyles(theme.typography.body1, removeProps),
	"& > h1": getFontStyles(theme.typography.h1, removeProps),
	"& > h2": getFontStyles(theme.typography.h2, removeProps),
	"& > h3": getFontStyles(theme.typography.h3, removeProps), //> training feed card title
	"& > h4": getFontStyles(theme.typography.h4, removeProps),
	"& > h5": getFontStyles(theme.typography.h5, removeProps),
	"& > h6": getFontStyles(theme.typography.h6, removeProps)
})

export const defaultTheme = createTheme({
	...defaultOptions,
	toolbarHeight,
	breakpoints,
	shadows: Array(25).fill("none") as Shadows,
	palette: {
		grey: {
			50: "rgba(255, 255, 255, .05)",
			100: "rgba(255, 255, 255, .1)",
			200: palette.grey15,
			400: "rgba(255, 255, 255, .4)",
			600: "rgba(255, 255, 255, .6)",
			700: "rgba(255, 255, 255, .7)",
			800: "rgba(255, 255, 255, .8)",
			900: palette.midGrey
		},
		background: {
			default: palette.background,
			paper: palette.greySolid
		},
		primary: {
			main: palette.orange,
			light: palette.orange50
		},
		secondary: {
			main: palette.orange
		},
		text: {
			primary: palette.white,
			secondary: palette.grey15,
			disabled: palette.midGrey
		},
		success: {
			main: palette.success
		},
		error: {
			main: palette.error
		}
	},
	typography: {
		fontFamily: "Titillium Web, sans-serif",
		body1: {
			color: palette.white,
			fontSize: "16px",
			fontWeight: 400
		},
		h1: {
			color: palette.white,
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "50px",
			lineHeight: "100%"
		},
		h2: {
			color: palette.white,
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "46px",
			lineHeight: "100%"
		},
		h3: {
			color: palette.white,
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "26px",
			lineHeight: "120%"
		},
		h4: {
			color: palette.white,
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "21px",
			lineHeight: "120%"
		},
		h5: {
			color: palette.white,
			fontStyle: "normal",
			fontWeight: 400,
			fontSize: "21px",
			lineHeight: "120%"
		},
		body2: { //> training feed description
			color: palette.white,
			fontWeight: 400,
			fontSize: "15px"
		},
		h6: {
			color: palette.white,
			fontWeight: 400,
			fontSize: "20px"
		},
		caption: {
			fontSize: "17px",
			lineHeight: "1.3",
			fontWeight: "400"
		},
		button: { //> training feed watch lesson
			fontWeight: 500,
			color: palette.white,
			fontFamily: "Titillium Web, sans-serif",
			fontSize: "16px",
			textTransform: "none"
		},
		small: {
			fontSize: "12px !important",
			fontWeight: "400 !important"
		}
	},
	navBar: {
		statusprofile: {
			backgroundColor: palette.background
		}
	},
	components: {
		...defaultOptions.components,
		MuiToolbar: {
			styleOverrides: {
				root: {
					height: toolbarHeight
				}
			}
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					borderRadius: "20px",
					height: "6px",
					backgroundColor: palette.grey10
				},
				bar: {
					backgroundColor: palette.orange,
					borderRadius: "10px"
				}
			}
		},
		MuiMenu: {
			styleOverrides: {
				root: {
					marginTop: "0px !important"
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				root: {
					minHeight: toolbarHeight,
					display: "flex",
					flexDirection: "row",
					gap: "10px",
					fontWeight: "400",
					paddingLeft: "20px",
					paddingRight: "20px",
					fontSize: "16px",
					padding: "0px 16px 1px",
					margin: "0 12px",
					letterSpacing: "0.15px",
					color: palette.white,
					"& [class*='iconWrapper']": {
						marginBottom: "2px",
						opacity: "0.56"
					},
					"&.Mui-selected": {
						opacity: "1",
						color: "#ffffff",
						background: "transparent",
						"& [class*='iconWrapper']": {
							opacity: "1"
						},
						"& svg path": {
							fill: palette.orange
						}
					}
				}
			}
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					borderBottom: "1px solid " + palette.grey10,
					lineHeight: 0
				},
				indicator: {
					height: "4px",
					backgroundColor: palette.orange,
					transition: "none"
				}
			}
		},
		MuiIcon: {
			styleOverrides: {
				fontSizeSmall: "12pt",
				fontSizeLarge: "20px"
			}
		},
		MuiCardActions: {
			styleOverrides: {
				root: {
					"&[class*=cardActions]": {
						padding: 0,
						border: "0px solid"
					}
				}
			}
		},
		MuiBreadcrumbs: {
			styleOverrides: {
				root: {
					color: palette.grey70,
					"& p": {
						color: palette.grey70
					}
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					backgroundColor: "transparent",
					border: "1px solid " + palette.orange,
					color: "white",
					height: "46px",
					padding: "17px 30px",
					borderRadius: "150px",
					transition: "0.3s ease-in-out",
					"&[class*=timestampButton]": {
						alignSelf: "flex-start",
						borderColor: palette.orange,
						color: palette.black,
						backgroundColor: `${palette.orange} !important`,
						width: "auto",
						height: "auto",
						"& [class$='startIcon']": {
							display: "none"
						}
					},
					"&:hover": {
						backgroundColor: palette.orange,
						transition: "0.3s"
					},
					":disabled": {
						opacity: 0.6,
						color: "white"
					},
					"& a": {
						color: palette.white,
						textDecoration: "none"
					},
					"&[class*=actionButton]": {
						padding: "0 12px",
						lineHeight: "1",
						border: 0,
						alignSelf: "flex-start",
						color: palette.orange,
						width: "auto",
						minWidth: "auto",
						height: "auto",
						"&:not(:first-of-type)": {
							borderLeft: `1px solid ${palette.orange}`
						},
						"&:hover": {
							background: "transparent",
							color: `${palette.orange}85`,
							transition: "0.35s"
						},
						"& [class$='startIcon']": {
							display: "none"
						}
					}
				},
				contained: {
					background: palette.orange,
					"& a": {
						color: palette.white,
						textDecoration: "none"
					}
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				outlined: {
					fieldset: {
						background: "transparent"
					}
				},
				standard: {
					fieldset: {
						background: "transparent"
					}
				},
				select: {
					fieldset: {
						background: "transparent"
					}
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					height: "auto",
					minHeight: "auto",
					border: "0px solid",
					padding: "14px 20px",
					borderRadius: "8px",
					"&:hover .MuiOutlinedInput-notchedOutline": {
						borderColor: palette.grey15
					},
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						borderWidth: "1px",
						borderColor: palette.grey15
					},
					"&.Mui-focused label": {
						color: palette.white
					},
					"&.Mui-focused ::placeholder": {
						opacity: 0.8,
						color: palette.white
					},
					"& [class*=searchIcon]": {
						marginTop: "-1px",
						marginLeft: "-2px",
						marginRight: "4px"
					},
					"& svg": {
						color: palette.white
					},
					"& svg path": {
						transition: "0.35s"
					},
					"&.Mui-error svg path": {
						stroke: palette.error,
						transition: "0.35s"
					},
					"&.Mui-error .MuiOutlinedInput-notchedOutline": {
						borderColor: palette.error
					},
					"& [class*=timestampLabel]": {
						color: palette.black,
						fontSize: "11px",
						padding: "2px 0 0",
						fontWeight: "400"
					}
				},
				input: {
					padding: 0
				},
				notchedOutline: {
					borderColor: palette.grey15
				},
				sizeSmall: {
					padding: "7px 20px"
				}
			}
		},
		MuiCard: {
			styleOverrides: {
				root: {
					"&[class*=cardRoot]": {
						background: palette.grey05,
						border: "0px solid",
						borderRadius: "16px",
						padding: "18px 22px 32px"
					},
					"& [class*=wysiwygRoot]": {
						color: palette.grey80,
						marginBottom: "10px"
					},
					"& [class*=linkItem]": {
						textDecoration: "none"
					}
				}
			}
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: "12px 12px"
				}
			}
		},
		MuiDrawer: {
			defaultProps: {
			},
			styleOverrides: {
				root: {
					background: "none"
				},
				paper: {
					backgroundColor: palette.drawer
				},
				docked: {
					backgroundColor: palette.drawer
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					"& fieldset": {
						// borderColor: palette.grey15 + "!important"
					}
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					"& fieldset": {
						borderColor: palette.grey15
					}
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					borderRadius: 100,
					backgroundColor: palette.orange,
					color: "#fff"
				}
			}
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					margin: "10px 0",
					backgroundColor: palette.grey15
				}
			}
		},
		MuiDataGrid: {
			styleOverrides: {
				row: {
					cursor: "pointer"
				}
			}
		}
		// MuiInput: {
		// 	styleOverrides: {
		// 		root: {
		// 			border: "1px solid red",
		// 		}
		// 	}
		// }
	}
});
