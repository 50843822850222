import ReactDOM from "react-dom/client";
import "./fonts/Roobert/RoobertFont.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {defaultTheme} from "./templates/defaultTheme";
import {ThemeProvider} from "@mui/material";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";
import AuthProvider from "./AuthProvider";
import NotificationProvider from "./NotificationProvider";

// import i18n (needs to be bundled)
import "./i18n";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

export const muiCache = createCache({
	key: "mui",
	prepend: true
});


root.render(
	<AuthProvider>
		<NotificationProvider>
			<CacheProvider value={muiCache}>
				<ThemeProvider theme={defaultTheme}>
					<App />
				</ThemeProvider>
			</CacheProvider>
		</NotificationProvider>
	</AuthProvider>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
