import {FC, useCallback, useContext, useEffect, useState} from "react";
import {Box, BoxProps, Button} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {IMarkedQuizAnswers, IQuiz, IQuizAnswer, IQuizQuestion} from "@plumeuk/shapeshift-types";
import {useParams} from "react-router-dom";
import {INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {QuizResult} from "../QuizPage/QuizQuestions/QuizResult/QuizResult";
import {MiniQuizzerQuestions} from "./MiniQuizzerQuestions";

const useStyles = makeStyles<{disabled?: boolean}>()((theme, {disabled}) => ({
	miniQuizzer: {
		opacity: disabled ? .5 : 1,
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		}
	},
	innerContainer: {
		pointerEvents: disabled ? "none" : "auto"
	},
	quizStartSummary: {
		padding: 0,
		maxWidth: "none",
		textAlign: "left",
		"& [class*='centerTile']": {
			padding: 0
		}
	},
	quizResult: {
		"& [class*='nextBtn']": {
			display: "none"
		},
		"& [class*='buttonContainer'] .nextModuleBtn, [class*='buttonContainer'] .nextModuleBtn, [class*='buttonContainer'] .retakeBtn": {
			display: "none"
		}
	},
	retakeBtn: {
		[theme.breakpoints.down("sm")]:{
			width: "100%",
			marginBottom: "20px"
		}
	}
}));

interface IPropsCustom {
	quiz: IQuiz,
	disabled?: boolean,
	disabledMessage?: string,
	autoStart?: boolean,
	onQuizResult?: (e: IMarkedQuizAnswers) => void,
	onComplete: (result: IMarkedQuizAnswers) => void
}

type IProps = IPropsCustom & BoxProps;

export const MiniQuizzer: FC<IProps> = ({quiz, onComplete, onQuizResult, autoStart, disabled, disabledMessage, className, ...props}) => {
	const {classes, cx} = useStyles({disabled});
	const {courseSlug, moduleSlug: lessonSlug} = useParams();
	const [navState, setNavState] = useState<"summary" | "active" | "complete">(autoStart ? "active" : "summary");
	const [markQuizResult, markQuiz] = useApi<IMarkedQuizAnswers>()
	const [prevQuizResult] = useApi<IMarkedQuizAnswers>(`/api/strapi-plugin-shapeshift-lms/${courseSlug}/lessons/${lessonSlug}/quizzes/${quiz.slug}/latestResult`)
	const [quizResult, setQuizResult] = useState<IMarkedQuizAnswers>();
	const [{data: quizQuestions, statusCode}] = useApi<IQuizQuestion[]>(`/api/strapi-plugin-shapeshift-lms/${courseSlug}/lessons/${lessonSlug}/quizzes/${quiz.slug}/questions`, [])
	const {notify} = useContext(NotificationContext);

	const markAnswersActionOverride = useCallback((answers: IQuizAnswer[]) => {
		markQuiz({
			url: `/api/strapi-plugin-shapeshift-lms/${courseSlug}/lessons/${lessonSlug}/markQuiz/${quiz.slug}`,
			data: answers,
			method: "POST"
		})
		return true
	}, [])

	useEffect(() => markQuizResult.data && setQuizResult(markQuizResult.data), [markQuizResult])
	useEffect(() => prevQuizResult.data && setQuizResult(prevQuizResult.data), [prevQuizResult])

	useEffect(() => {
		if(quizResult)
			onQuizResult?.(quizResult)
	}, [quizResult])

	useEffect(() => {
		if(quizResult){
			setNavState("complete")
			if(quizResult.pass)
				onComplete(quizResult);
		}
	}, [quizResult])

	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		if(disabled){
			notify(disabledMessage ?? "quiz is disabled", "", INotificationSeverity.warning, 5000, false)
			e.preventDefault();
			e.stopPropagation()
		}
	}

	if(statusCode === 400)
		return <></>

	return (
		<Box onClickCapture={e => handleClick(e)} className={cx(classes.miniQuizzer, className)} {...props}>
			<Box className={classes.innerContainer}>
				{navState === "active" && (quizQuestions ?? [])?.length > 0 && <MiniQuizzerQuestions courseSlug={courseSlug} quizSlug={quiz.slug} markAnswersActionOverride={markAnswersActionOverride} quizQuestionDataOverride={quizQuestions}/>}
				{navState === "complete" && <QuizResult className={classes.quizResult} quiz={quiz} data={quizResult}/>}
				{navState === "complete" && quizResult && <Button className={classes.retakeBtn} onClick={() => setNavState("active")}>Retake</Button>}
			</Box>
		</Box>
	);
}