import {FC, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {APIState} from "@plumeuk/shapeshift-identity";
import {IAnnouncementEntity, IAnnouncementEntityPayload} from "../../../../types/announcement";
import {AdminAnnouncementPageContent} from "./AdminAnnouncementPageContent";

interface IProps {}

const useStyles = makeStyles()((theme) => ({
	adminAnnouncementPage: {
	}
}));

const template: IAnnouncementEntityPayload = {
	title: "", slug: "", content: ""
}

export const AdminAnnouncementPage: FC<IProps> = () => {
	const {announcementId} = useParams();
	const {classes} = useStyles();
	const navigate = useNavigate();
	const entityId = announcementId ? parseInt(announcementId) : undefined;
	const [locale, setLocale] = useState<string>();

	const handleOnSaveResponse = (e: APIState<IAnnouncementEntity>): void => {
		if(!e.data)
			return;

		navigate("../" + e.data.id.toString())
		navigate("/admin/announcements/" + e.data.id.toString() + "/edit")
	}

	const handleOnGetResponse = (entityApiResponse: APIState<IAnnouncementEntity>): void => {
		if(!entityApiResponse.data || entityApiResponse.isLoading)
			return;

		if(entityApiResponse.statusCode === 404){
			navigate("/admin/announcements")
		}

		if(!entityApiResponse.data)
			return;

		if(entityId && entityApiResponse.data.id !== entityId){
			navigate("/admin/announcements/" + entityApiResponse.data.id.toString() + "/edit?locale=" + entityApiResponse.data.locale)
		}
	}

	const handleOnDeleteResponse = (deleteApiResponse: APIState<IAnnouncementEntity>): void => {
		if(!deleteApiResponse.data || deleteApiResponse.isLoading)
			return;

		if(deleteApiResponse.statusCode === 200){
			navigate("/admin/announcements")
		}
	}

	const AnnouncementIdParsed = announcementId ? parseInt(announcementId) : undefined

	return (
		<Box className={classes.adminAnnouncementPage}>
			<AdminAnnouncementPageContent
				announcementId={AnnouncementIdParsed}
				onSaveResponse={handleOnSaveResponse}
				onDeleteResponse={handleOnDeleteResponse}
				onGetResponse={handleOnGetResponse}
				onLocaleChange={e => setLocale(e)}
				locale={locale}
			/>
		</Box>
	);
}